import { Tab } from "@components/tabs/tab";

export const GENERAL_CONFIG_TABS:Tab[] = [
  {
    id:'years',
    label:"Años"
  },
  {
    id:'forces',
    label:"Fuerzas"
  },
  {
    id:'charges',
    label:"Cargos"
  },
  {
    id:'ranges',
    label:"Rangos"
  }
]