import { CommonModule } from '@angular/common';
import { extraReferenceFromTypeQuery } from '@angular/compiler-cli/src/ngtsc/metadata/src/util';
import { Component, computed, inject, input, OnChanges, OnDestroy, OnInit, output, signal, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import * as UiSelect from '@components/ui/ui-select';
import { IForce, IRange, ITypeCondition } from '@models/militar-info';
import { IRateType } from '@models/rate-type';
import { IRates } from '@models/rates';
import { ISchool } from '@models/school';
import { IService } from '@models/service';
import { ApiService } from '@services/api-service.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

interface SharedData{
  forces:IForce[],
  ranges:IRange[],
  conditions:ITypeCondition[],
  tarifaTypes:IRateType[]
}

@Component({
  selector: 'app-tarifas',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
    UiInputComponent,
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './tarifas.component.html',
  styleUrl: './tarifas.component.css'
})
export class TarifasComponent implements OnDestroy, OnChanges {
  private _apiService = inject(ApiService);
  constructor(private _fb: FormBuilder) { }

  serviceItem = input.required<IService>();
  sharedData = input.required<SharedData>();
  refreshList = output<void>();

  public rateForm: FormGroup = this._fb.group({
    id: ['', Validators.required],
    schoolID: [''],
    forceID: [''],
    rangeID: [''],
    typeConditionID: [''],
    typeRateID: ['1'],
    rateValue: ['0'],
  });

  public showChildren = signal<boolean>(false);
  public schools = signal<ISchool[]>(this._apiService.schools());
  public rates = signal<IRates[]>([]);
  public showNewRateForm = signal<boolean>(false);
  public editIndex = signal<number | null>(null);
  private _rateSubscription:Subscription = new Subscription();
  public appName = environment.APP_NAME
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['serviceItem'])
      this.rates.set(this.serviceItem().Rates);
  }

  public saveData(){
    if(this.editIndex() !== null){
      this.updateTarifa();
      return;
    }

    this.addTarifa();
  }

  public onClickEdit(item:IRates, index:number){
    this.editIndex.set(index);
    this.showNewRateForm.set(false);
    this.rateForm.patchValue({
      ...item,
      typeConditionID: item.typeConditionID ?? 0 
    });
  }

  public onCreateClick(){
    this.rateForm.reset();
    this.rateForm.patchValue({
      typeRateID: 1,
      rateValue: '0',
    });
    this.showChildren.set(true);
    this.showNewRateForm.set(!this.showNewRateForm());
    this.editIndex.set(null);
  }

  public onCloseRateForm(){
    this.showNewRateForm() && this.showNewRateForm.set(false);
    this.rateForm.reset();
    this.editIndex.set(null);
  }

  private updateTarifa() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando tarifa, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    const {id, ...data} = this.rateForm.value;

    this._rateSubscription.unsubscribe();
    this._rateSubscription = this._apiService.patch<IRates>({
      path: `Rates/${id}`,
      data: {
        ...data,
        forceID: data.forceID == 0 ? null : data.forceID,
        rangeID: data.rangeID == 0 ? null : data.rangeID,
        typeConditionID: data.typeConditionID == 0 ? null : data.typeConditionID
      }
    }).subscribe({
      next: () => {
        this.refreshList.emit();
        Swal.fire({
          icon: "success",
          text: "Se guardo la tarifa satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed)
            this.onCloseRateForm();
        });
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar la tarifa: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  private addTarifa() {
    Swal.fire({
      title: 'Cargando...',
      text: "Creando tarifa, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    const {id, ...data} = this.rateForm.value;
    this._apiService.post<Partial<IRates>>({
      path: 'Rates',
      data: {
        ...data,
        serviceID: this.serviceItem().id ,
        forceID: data.forceID == 0 ? null : data.forceID,
        rangeID: data.rangeID == 0 ? null : data.rangeID,
        typeConditionID: data.typeConditionID == 0 ? null : data.typeConditionID
      }
    }).subscribe({
      next: () => {
        this.refreshList.emit();
        Swal.fire({
          icon: "success",
          text: "Se guardo la tarifa satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed)
            this.onCloseRateForm();
        });
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar la tarifa: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  public valueCurrency(event:Event){
    const target = event.target as HTMLInputElement;
    let inputValue = target.value;

    inputValue  = inputValue.replace(/[^0-9.]/g, '');
    inputValue = inputValue.replace(/^(\d*\.\d*)\./g, '$1');

    target.value = inputValue;
  }

  ngOnDestroy(): void {
    this._rateSubscription.unsubscribe();
  }
}
