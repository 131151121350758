<div class="services">
  <div class="students__filter">
    <ui-select label="Año" [(ngModel)]="yearFilter" 
      (onValueChange)="getServicesOnChange()">
      <ui-select-content>
        @for (item of years(); track $index) {
        <ui-select-item [value]="item.yearNumber">{{item.yearNumber}}</ui-select-item>
        }
      </ui-select-content>
    </ui-select>

    <ui-select label="Seleccionar {{appName === 'celic' ? 'Liceo' : 'Escuela'}}" [(ngModel)]="schoolFilter" 
      (onValueChange)="getServicesOnChange()"> 
      <ui-select-content>
        <ui-select-item [value]="0">{{appName === 'celic' ? 'Todos' : 'Todas'}}</ui-select-item>
        @for (item of schools(); track $index) {
          <ui-select-item [value]="item.id!">{{item.NameTSchool}}</ui-select-item>
        }
      </ui-select-content>
    </ui-select>

    <ui-select label="Seleccionar categoría" [(ngModel)]="typeFilter" 
      (onValueChange)="getServicesOnChange()">
      <ui-select-content>
        <ui-select-item [value]="0">Todas</ui-select-item>
        @for (item of serviceTypes(); track $index) {
          <ui-select-item [value]="item.id!">{{item.nameTypeRequest}}</ui-select-item>
        }
      </ui-select-content>
    </ui-select>
    <ui-input label="Nombre servicio" (keyup)="onSearchChange($event)" />
  </div>

  <div class="services__list">
    @for (item of filteredServices(); track $index) {
      <div class="services__item">
        <h2 class="services__title">Servicio: <strong>{{item.serviceName}}</strong></h2>
        <div class="service__content">
          <table class="service__table">
            @if(item.userCoursesWithPrice?.length === 0){
              <caption class="empty-list">Sin Estudiantes</caption>
            }

            <thead>
              <tr>
                <th class="service__table-th">Nombre de estudiante</th>
                <th class="service__table-th">Grupo</th>
                <th class="service__table-th">Oferta</th>
                <th class="service__table-th">Responsable de pago</th>
                <th class="service__table-th">Valor</th>
                <th class="service__table-th" [ngStyle]="{'width': '50px'}" ></th>
              </tr>
            </thead>
            <tbody>
              @for (userCourse of item.userCoursesWithPrice; track $index) {
                <tr>
                  <td class="service__table-td">{{userCourse.userapp?.Name1}} {{userCourse.userapp?.Name2}} {{userCourse.userapp?.LastName1}} {{userCourse.userapp?.LastName2}}</td>
                  <td class="service__table-td">{{userCourse.courseGroup?.name}}</td>
                  <td class="service__table-td">{{userCourse.coursesOfer?.NameCourseOfer}}</td>
                  <td class="service__table-td">
                    @if(userCourse.userapp?.parentsToStudents?.[0]){
                      {{userCourse.userapp?.parentsToStudents![0].parentUser?.Name1}}
                      {{userCourse.userapp?.parentsToStudents![0].parentUser?.Name2}}
                      {{userCourse.userapp?.parentsToStudents![0].parentUser?.LastName1}}
                      {{userCourse.userapp?.parentsToStudents![0].parentUser?.LastName2}}
                    }@else {
                      n/a
                    }
                  </td>
                  <td class="service__table-td">
                    @if(userCourse.UserPerService && userCourse.UserPerService['pricesPerServiceFilter'].length > 0){
                      <div>{{userCourse.UserPerService['pricesPerServiceFilter'][0].price | currency}}</div>
                    }@else {
                     0
                    }
                    @if (userCourse.UserPerService?.['message']) {
                      <div>{{userCourse.UserPerService!['message']}}</div>
                    }
                  </td>
                  <td class="service__table-td"><em class="icon-i-trash delete-user" (click)="removeUserPerService(item, userCourse.UserID)"></em></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    }@empty {
      <div class="empty-list">Sin Resultados</div>
    }
  </div>
</div>