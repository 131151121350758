<div class="category">
  <div class="category__actions">
    <ui-select label="{{appName === 'celic' ? 'Liceo' : 'Escuela'}}" (onValueChange)="onSelectSchool($event.value)" defaultValue="0">
      <ui-select-content>
        <ui-select-item value="0">{{appName === 'celic' ? 'Todos' : 'Todas' }}</ui-select-item>
        @for (item of schools(); track $index) {
        <ui-select-item [value]="item.id!">{{item.NameTSchool}}</ui-select-item>
        }
      </ui-select-content>
    </ui-select>
  </div>

  <div class="category__header">
    <h2 class="header__title">Categorías</h2>
    <button class="button button--solid" (click)="showDialog.set(true)">Agregar Categoría</button>
  </div>

  <div class="category__content">
    <div class="table__container">
      <table class="category__table">
        @if(filteredCategories().length === 0) {
        <caption class="empty-list">Sin resultados.</caption>
        }
        <thead>
          <tr>
            <th>{{appName === 'celic' ? 'Liceo' : 'Escuela'}}</th>
            <th>Nombre</th>
            <th>Público</th>
            <th>Habilitado</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          @for (item of filteredCategories(); track $index) {
          <tr>
            <td>{{item.School?.NameTSchool ? item.School?.NameTSchool : appName === 'celic' ? 'Todos los Liceos' : 'Todos las Escuelas'}}</td>
            <td>{{item.nameTypeRequest || '-'}}</td>
            <td>
              <div class="toggle-container" [ngClass]="{'toggle-container--disabled': savingStates()}">
                <span class="toggle-label">NO</span>
                <label class="switch">
                  <input type="checkbox" (change)="togglePublic($event, item)" [checked]="item.isPublic">
                  <span class="slider"></span>
                </label>
                <span class="toggle-label">SÍ</span>
              </div>
            </td>
            <td>
              <div class="toggle-container" [ngClass]="{'toggle-container--disabled': savingStates()}">
                <span class="toggle-label">NO</span>
                <label class="switch">
                  <input type="checkbox" (change)="toggleEnabled($event, item)" [checked]="item.isEnable">
                  <span class="slider"></span>
                </label>
                <span class="toggle-label">SÍ</span>
              </div>
            </td>
            <td>
              <button type="button" class="button button--edit-category">
                <em class="icon-i-pen" (click)="onEditCategory(item)"></em>
              </button>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>


  @if (showDialog()) {
  <div class="dialog__container">
    <form class="dialog__content" [formGroup]="categoriesForm" (ngSubmit)="onSave()">
      <div class="dialog__header">
        <h2 class="dialog__header-title">{{categoryToEdit() ? 'Editar' : "Agregar"}} Categoría</h2>
        <em class="icon-i-close dialog__header-close" (click)="resetForm()"></em>
      </div>

      <div class="dialog__body">
        <div>
          <ui-select label="{{appName === 'celic' ? 'Liceo' : 'Escuela'}}*" formControlName="schoolID"
            [invalid]="categoriesForm.get('schoolID')?.invalid && categoriesForm.get('schoolID')?.touched">
            <ui-select-content>
              <ui-select-item [value]="0">{{appName === 'celic' ? 'Todos los Liceos' : 'Todas las Escuelas'}}</ui-select-item>
              @for (item of schools(); track $index) {
                <ui-select-item [value]="item.id!">{{item.NameTSchool}}</ui-select-item>
              }
            </ui-select-content>
          </ui-select>
          @if(categoriesForm.get('schoolID')?.invalid && categoriesForm.get('schoolID')?.touched){
          <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-input variant="bordered" label="Nombre categoría*" formControlName="nameTypeRequest"
            [invalid]="categoriesForm.get('nameTypeRequest')?.invalid && categoriesForm.get('nameTypeRequest')?.touched" />
          @if (categoriesForm.get('nameTypeRequest')?.invalid && categoriesForm.get('nameTypeRequest')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <div class="toggle__input">
            Mostrar al público
            <div class="toggle-container">
              <span class="toggle-label">NO</span>
              <label class="switch">
                <input type="checkbox" formControlName="isPublic">
                <span class="slider"></span>
              </label>
              <span class="toggle-label">SÍ</span>
            </div>
          </div>
        </div>

        <div>
          <div class="toggle__input">
            Habilitado
            <div class="toggle-container">
              <span class="toggle-label">NO</span>
              <label class="switch">
                <input type="checkbox" formControlName="isEnable">
                <span class="slider"></span>
              </label>
              <span class="toggle-label">SÍ</span>
            </div>
          </div>
        </div>
      </div>

      <ui-button color="primary" [disabled]="categoriesForm.invalid">Guardar</ui-button>
    </form>
  </div>
  }
</div>