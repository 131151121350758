<div class="assign-dialog">
  <div class="assign-dialog__scroll">
    <div class="assign-dialog__container" [ngClass]="{'overflow-visible': uiSelectTriggered()}">
      <div class="assign-dialog__header">
        <h2 class="assign-dialog__title">{{relationship() === 'responsible' ? 'Responsable de' : 'Representante de'}}</h2>
        <button class="assign-dialog__close" (click)="closeDialog()"><em class="icon-i-close"></em></button>
      </div>

      <div class="assign-dialog__body">
        <div class="assign-dialog__form">
          <div [ngClass]="uiSelectTriggered() ? 'overflow-visible' : 'overflow-hidden'">
            <ui-dropdown (onValueChange)="onSelectStudent($event)">
              <ui-dropdown-trigger >
                <ui-input (input)="searchUser($event)"
                  type="text" 
                  label="Buscar Estudiante" 
                  placeholder="Buscar Nombre o Documento"
                  #searchInput />
              </ui-dropdown-trigger>
              <ui-dropdown-content #uiDropdwonContent>
                @for (user of users(); track $index) {
                  <ui-dropdown-item [value]="user.id!" [disabled]="user.id == selectedStudent()">
                    {{ user.fullName | titlecase}}
                  </ui-dropdown-item>
                }@empty{
                  <span>Sin resultados.</span>
                }
              </ui-dropdown-content>
            </ui-dropdown>
          </div>

          <div [ngClass]="uiSelectTriggered() ? 'overflow-visible' : 'overflow-hidden'">
            <ui-select label="Relación" #uiSelect [(ngModel)]="selectedRelationship">
              <ui-select-content>
                @for (typeRelationship of basicData.typeRelationship; track $index) {
                  <ui-select-item [value]="typeRelationship.id!" [disabled]="selectedRelationship() == typeRelationship.id!">
                    {{ typeRelationship.nameRelationship | titlecase }}
                  </ui-select-item>
                }
              </ui-select-content>
            </ui-select>
          </div>

          <ui-button (click)="saveRelationship()" [disabled]="selectedStudent() === null || selectedRelationship() === null">Guardar</ui-button>
        </div>
      </div>
    </div>
  </div>
</div>