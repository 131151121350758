<div class="services">
  <div class="services__actions">
    <ui-select label="Año" (onValueChange)="onSelectYear($event.value)" defaultValue="2024">
      <ui-select-content>
        @for (item of years(); track $index) {
          <ui-select-item [value]="item.yearNumber">{{item.yearNumber}}</ui-select-item>
        }
      </ui-select-content>
    </ui-select>

    <ui-select label="Seleccionar {{appName === 'celic' ? 'Liceso' : 'Escuelas'}}" 
      (onValueChange)="onSelectSchool($event.value)" defaultValue="0">
      <ui-select-content>
        <ui-select-item value="0">{{appName === 'celic' ? 'Todos los Liceos' : 'Todas las Escuelas'}}</ui-select-item>
        @for (item of schools(); track $index) {
          <ui-select-item [value]="item.id!">{{item.NameTSchool | titlecase}}</ui-select-item>
        }
      </ui-select-content>
    </ui-select>

    <ui-select label="Categorias" (onValueChange)="onSelectCategory($event.value)" defaultValue="0">
      <ui-select-content>
        <ui-select-item value="0">Todos</ui-select-item>
        @for (item of categories(); track $index) {
          <ui-select-item [value]="item.id!">{{item.nameTypeRequest| titlecase}}</ui-select-item>
        }
      </ui-select-content>
    </ui-select>
  </div>

  <div class="services__header">
    <h2 class="header__title">Servicios</h2>
    <button class="button button--solid" (click)="showDialog.set(true)">Agregar Servicio</button>
  </div>

  <div class="services__content">
    <div class="services__list">
      @for (item of filteredServices(); track $index) {
        <div class="services__item">
          <div class="item__content">
            <div class="item__data">
              <div class="item__data-label">Año</div>
              <div class="item__data-value">{{item.year}}</div>
            </div>
            <div class="item__data">
              <div class="item__data-label">Nombre del servicio</div>
              <div class="item__data-value">{{item.serviceName}}</div>
            </div>
            <div class="item__data">
              <div class="item__data-label">{{appName === 'celic' ? 'Liceso' : 'Escuelas'}}</div>
              <div class="item__data-value">{{item.School?.NameTSchool || appName === 'celic' ? 'Todos los Liceos' : 'Todas las Escuelas'}}</div>
            </div>
            <div class="item__data">
              <div class="item__data-label">Categoría</div>
              <div class="item__data-value">{{item.TypeRequest?.nameTypeRequest}}</div>
            </div>
            <div class="item__data">
              <div class="item__data-label">Precio</div>
              {{item.priceService | currency : 'COP '}}
            </div>
            <div class="item__data">
              <div class="item__data-label">Porcentaje militar</div>
              <div class="item__data-value">{{item.milirtarDiscountPercentage}} %</div>
            </div>

            <button class="button button--bordered" (click)="onEditService(item)">
              <em class="icon-i-pen"></em>
              Editar Servicio
            </button>
          </div>

          <app-tarifas [serviceItem]="item" [sharedData]="sharedData()!" (refreshList)="refreshData()"/>
        </div>
      }@empty {
        <span class="empty-list">Sin resultados.</span>
      }
    </div>
  </div>

  @if (showDialog()) {
  <div class="dialog__container">
    <form class="dialog__content" [formGroup]="serviceForm" (ngSubmit)="onSave()">
      <div class="dialog__header">
        <h2 class="dialog__header-title">{{serviceToEdit() ? 'Editar' : 'Agregar'}} Servicio</h2>
        <em class="icon-i-close dialog__header-close" (click)="showDialog.set(false)"></em>
      </div>

      <div class="dialog__body">
        <div>
          <ui-input variant="bordered" label="Nombre del servicio*" formControlName="serviceName" 
            [invalid]="serviceForm.get('serviceName')?.invalid && serviceForm.get('serviceName')?.touched"/>
          @if (serviceForm.get('serviceName')?.invalid && serviceForm.get('serviceName')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-select label="Año*" formControlName="year"
            [invalid]="serviceForm.get('year')?.invalid && serviceForm.get('year')?.touched">
            <ui-select-content>
              @for (item of years(); track $index) {
                <ui-select-item [value]="item.yearNumber">{{item.yearNumber}}</ui-select-item>
              }
            </ui-select-content>
          </ui-select>
          @if (serviceForm.get('year')?.invalid && serviceForm.get('year')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-select [label]="appName === 'celic' ? 'Liceso' : 'Escuelas'" formControlName="schoolID"
            [invalid]="serviceForm.get('schoolID')?.invalid && serviceForm.get('schoolID')?.touched">
            <ui-select-content>
              <ui-select-item value="0">{{appName === 'celic' ? 'Todos los Liceos' : 'Todas las Escuelas'}}</ui-select-item>
              @for (item of schools(); track $index) {
                <ui-select-item [value]="item.id!">{{item.NameTSchool}}</ui-select-item>
              }
            </ui-select-content>
          </ui-select>
          @if (serviceForm.get('schoolID')?.invalid && serviceForm.get('schoolID')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-select label="Categoría*" formControlName="typeRequestID"
            [invalid]="serviceForm.get('typeRequestID')?.invalid && serviceForm.get('typeRequestID')?.touched">
            <ui-select-content>
              @for (item of categories(); track $index) {
                <ui-select-item [value]="item.id!">{{item.nameTypeRequest}}</ui-select-item>
              }
            </ui-select-content>
          </ui-select>
          @if (serviceForm.get('typeRequestID')?.invalid && serviceForm.get('typeRequestID')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-input variant="bordered" label="Precio*" formControlName="priceService"
            [invalid]="serviceForm.get('priceService')?.invalid && serviceForm.get('priceService')?.touched"
            (keyup)="valueCurrency($event)" />
          @if (serviceForm.get('priceService')?.invalid && serviceForm.get('priceService')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-input variant="bordered" label="Porcentaje*" formControlName="milirtarDiscountPercentage" 
            [invalid]="serviceForm.get('milirtarDiscountPercentage')?.invalid && serviceForm.get('milirtarDiscountPercentage')?.touched" />
          @if (serviceForm.get('milirtarDiscountPercentage')?.invalid && serviceForm.get('milirtarDiscountPercentage')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>
      </div>

      <ui-button color="primary" [disabled]="serviceForm.invalid">Guardar</ui-button>
    </form>
  </div>
  }
</div>