<div class="parameters">
  <div class="parameters__tabs">
    <app-tabs [tabs]="GENERAL_CONFIG_TABS" 
    (onValueChange)="selectedTab.set($event)" 
    defaultTab="years" />
  </div>

  <div class="parameters__content">
    @switch (selectedTab()) {
      @case ("forces") {
        <app-forces />
      }
      @case ("charges") {
        <app-charges />
      }
      @case ("ranges") {
        <app-ranges />
      }
      @case ("years") {
        <app-years />
      }
      @default {}
    }
  </div>
</div>