import {
  Component,
  computed,
  inject,
  input,
  OnInit,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import * as UiSelect from '@components/ui/ui-select';
import * as UiDropdown from '@components/ui/ui-dropdown';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { FormsModule } from '@angular/forms';
import { IParentToStudents } from '@models/parent-to-students';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { ApiService } from '@services/api-service.service';
import { IUser } from '@models/users';
import { CommonModule, DOCUMENT } from '@angular/common';
import { IDropdownItem } from '@components/ui/models/dropdown-item';
import Swal from 'sweetalert2';
import { ParentsService } from '../../parents.service';

@Component({
  selector: 'app-assign-relationship',
  standalone: true,
  imports: [
    UiButtonComponent,
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
    UiDropdown.Root,
    UiDropdown.Trigger,
    UiDropdown.Content,
    UiDropdown.Item,
    UiInputComponent,
    FormsModule,
    CommonModule,
  ],
  templateUrl: './assign-relationship.component.html',
  styleUrl: './assign-relationship.component.css',
})
export class AssignRelationshipComponent implements OnInit {
  private _htmlInput = viewChild<UiInputComponent>("searchInput");
  private  _parentsService = inject(ParentsService);
  private  _apiService = inject(ApiService);
  private  _document = inject(DOCUMENT);

  private _uiSelect = viewChild<UiSelect.Root>('uiSelect');
  private _uiDropdwonContent =
    viewChild<UiDropdown.Content>('uiDropdwonContent');

  public selectedStudent = signal<number | null>(null);
  public selectedRelationship = signal<number | null>(null);

  relationship = input.required<'responsible' | 'guardian'>();
  onCloseDialog = output<void>();

  public uiSelectTriggered = computed(
    () => this._uiSelect()?.isTriggered() || this._uiDropdwonContent()?.open()
  );

  public users = signal<IUser[]>([]);
  private _searchUser$ = new Subject<string>();

  get basicData(){
    return this._parentsService.BASIC_DATA!;
  }

  ngOnInit(): void {
    this._document.body.style.overflow = 'hidden';
    this._searchUser$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((value: string) => value.length > 3),
        switchMap((value) => {
          const words = value.trim().split(/\s+/);
          const regex = words.join('.*');

          return this._apiService.get<IUser>({
            path: `ViewUserInfos`,
            filter: {
              limit: 5,
              where: {
                or: [
                  { fullName: { regexp: `/${regex}/i` } },
                  { Document: { regexp: `/${value}/i` } },
                  { CedocEmail: { regexp: `/${value}/i` } },
                ],
              },
            },
          });
        })
      )
      .subscribe({
        next: (users) => {
          this.users.set(users);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  public searchUser(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this._searchUser$.next(value);
  }

  public closeDialog() {
    this._document.body.style.overflow = 'auto';
    this.onCloseDialog.emit();
  }

  public onSelectStudent(event: IDropdownItem) {
    this.selectedStudent.set(Number(event.value));
    const input = this._htmlInput()?.ref.nativeElement.querySelector("input");
    input.value = event.label;
  }

  public saveRelationship() {
    if (this.selectedStudent() === null || this.selectedRelationship() === null)
      return;

    Swal.fire({
      title: 'Asignando...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    const relationship = this.relationship();
    this._apiService
      .get<IParentToStudents>({
        path: `ParentsToStudents`,
        filter: {
          limit: 5,
          where: { StudentUserId: this.selectedStudent() },
        },
      })
      .pipe(
        switchMap((parentsToStudents) => {
          if (parentsToStudents.length === 0) {
            const relationshipData =
              relationship === 'responsible'
                ? {
                    ParentUserId: this._parentsService.currentUserId$.value,
                    typeRelationshipIDParent: this.selectedRelationship()!,
                  }
                : {
                    attendedBy: this._parentsService.currentUserId$.value,
                    typeRelationshipIDAttended: this.selectedRelationship()!,
                  };

            return this._apiService.post<Partial<IParentToStudents>>({
              path: `ParentsToStudents`,
              data: {
                StudentUserId: this.selectedStudent()!,
                ...relationshipData,
              },
            });
          }

          if (relationship === 'responsible') {
            if (parentsToStudents[0].ParentUserId === null) {
              return this._apiService.patch<Partial<IParentToStudents>>({
                path: `ParentsToStudents/${parentsToStudents[0].id}`,
                data: { ParentUserId: this._parentsService.currentUserId$.value, typeRelationshipIDParent: this.selectedRelationship()! },
              });
            }
            return of({parentExists : true});
          }

          if (relationship === 'guardian') {
            if (parentsToStudents[0].attendedBy === null) {
              return this._apiService.patch<Partial<IParentToStudents>>({
                path: `ParentsToStudents/${parentsToStudents[0].id}`,
                data: { attendedBy: this._parentsService.currentUserId$.value, typeRelationshipIDAttended: this.selectedRelationship()! },
              });
            }
            return of({guardianExists : true});
          }

          return of(null);
        })
      ).subscribe({
        next: _ => {
          Swal.fire({
            title: 'Asignado',
            text: 'Estudiante asignado correctamente',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then(() => {
            this.closeDialog();
            this._parentsService.refreshParentToStudent$.next();
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
  }
}