<div class="salary">
  <div class="salary__actions">
    <ui-select label="Año" defaultValue="2024" (onValueChange)="onSelectYear($event.value)">
      <ui-select-content>
        @for (item of years(); track $index) {
          <ui-select-item [value]="item">{{item}}</ui-select-item>
        }
      </ui-select-content>
    </ui-select>

    <ui-dropdown (onValueChange)="onSelectCharge($event.value)" [closeOnSelect]="false">
      <ui-dropdown-trigger>
        <ui-input (keyup)="onSearchChange($event)" label="Seleccionar Cargo"></ui-input>
      </ui-dropdown-trigger>
      <ui-dropdown-content>
        <ui-dropdown-item [value]="0" [disabled]="chargeFilter() === 0 || !chargeFilter()">Todos</ui-dropdown-item>
        @for (item of chargesTofilter(); track $index) {
          <ui-dropdown-item [value]="item.id!" [disabled]="!!item.selected">
            {{item.namePosition}}
          </ui-dropdown-item>
        }
      </ui-dropdown-content>
    </ui-dropdown>
  </div>

  <div class="salary__header">
    <h2 class="header__title">Salarios</h2>
    <div class="header__actions">
      <button class="button button--solid" (click)="showDialog.set(true)">
        <em class="icon-i-plus-circle"></em>Agregar Salarios
      </button>
      @if (isEditing()) {
        <button class="button button--solid" (click)="saveAllSalaries()">Guardar Salarios</button>
      }
      <button class="button button--bordered" (click)="onEditChange()">
        @if (isEditing()) {
          <em class="icon-i-close cancel-icon" [ngStyle]="{'font-size': '11px'}"></em>Cancelar
        }@else {
          <em class="icon-i-pen"></em>Editar Salarios
        }
      </button>
    </div>
  </div>

  <div class="salary__content">
    <div class="table__container">
      <table class="salary__table">
        @if(filteredSalary().length === 0) {
          <caption class="empty-list">Sin resultados.</caption>
        }
        <thead>
          <tr>
            <th [ngStyle]="{'width': '80px'}">Año</th>
            <th>Cargo</th>
            <th [ngStyle]="{'text-align': 'center'}">Salario</th>
            <th [ngStyle]="{'width': '260px'}"></th>
          </tr>
        </thead>

        <tbody>
          @for (item of filteredSalary(); track $index) {
            <tr>
              <td>{{item.year}}</td>
              <td>{{item.Position?.namePosition || '-'}}</td>
              <td [ngStyle]="{'text-align': 'center'}">
                <input type="text" class="currency-input"
                  [hidden]="!isEditing() && !item.isEditing" 
                  [value]="item.salaryValue" 
                  (input)="valueCurrency($event, item)"  
                  #inputValue>
                @if (!isEditing() && !item.isEditing) {
                  {{item.salaryValue | currency : 'COP '}}
                }
              </td>
              <td>
                <div class="table__actions">
                  @if(item.isEditing){
                    <button class="button button--solid" 
                      (click)="saveSalary(inputValue.value, item)">
                      Guardar Salario
                    </button>
                  }
                  <button class="button button--bordered" (click)="item.isEditing = !item.isEditing">
                    @if (item.isEditing) {
                      <em class="icon-i-close cancel-icon" [ngStyle]="{'font-size': '11px'}"></em>Cancelar
                    }@else {
                      <em class="icon-i-pen"></em>Editar Salarios
                    }
                  </button>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>

  @if (showDialog()) {
  <div class="dialog__container">
    <form class="dialog__content" [formGroup]="salaryForm" (ngSubmit)="onCreateSalary()">
      <div class="dialog__header">
        <h2 class="dialog__header-title">Agregar Salario</h2>
        <em class="icon-i-close dialog__header-close" (click)="resetForm()"></em>
      </div>

      <div class="dialog__body">
        <div>
          <ui-select label="Año*" formControlName="year"
            [invalid]="salaryForm.get('year')?.invalid && salaryForm.get('year')?.touched">
            <ui-select-content>
              @for (item of years(); track $index) {
                <ui-select-item [value]="item">{{item}}</ui-select-item>
              }
            </ui-select-content>
          </ui-select>
          @if (salaryForm.get('year')?.invalid && salaryForm.get('year')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-dropdown (onValueChange)="onSelectNewCharge($event.value)" [closeOnSelect]="false">
            <ui-dropdown-trigger>
              <ui-input formControlName="tempPositionID" (keyup)="onNewSearchChange($event)" 
              [invalid]="salaryForm.get('positionID')?.invalid && salaryForm.get('tempPositionID')?.touched" 
              label="Seleccionar Cargo*"></ui-input>
            </ui-dropdown-trigger>
            <ui-dropdown-content>
              @for (item of newChargesToFilter(); track $index) {
                <ui-dropdown-item [value]="item.id!" [disabled]="!!item.selected">
                  {{item.namePosition}}
                </ui-dropdown-item>
              }@empty {
                <div>Sin resultados.</div>
              }
            </ui-dropdown-content>
          </ui-dropdown>
          @if (salaryForm.get('positionID')?.invalid && salaryForm.get('tempPositionID')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-input variant="bordered" label="Salario*" formControlName="salaryValue"
            (keyup)="valueCurrency($event)"
            [invalid]="salaryForm.get('salaryValue')?.invalid && salaryForm.get('salaryValue')?.touched" />
          @if (salaryForm.get('salaryValue')?.invalid && salaryForm.get('salaryValue')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>
      </div>

      <ui-button color="primary" [disabled]="salaryForm.invalid">Guardar</ui-button>
    </form>
  </div>
  }
</div>