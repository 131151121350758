import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { UiDateInputComponent } from '@components/ui/ui-date-input/ui-date-input.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import * as UiSelect from '@components/ui/ui-select';
import { IPositions } from '@models/positions';
import { ITypeUser } from '@models/type-user';
import { ApiService } from '@services/api-service.service';
import { debounceTime, distinctUntilChanged, forkJoin, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-charges',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,

    UiInputComponent,
    UiButtonComponent,
    UiDateInputComponent,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './charges.component.html',
  styleUrl: './charges.component.css'
})
export class ChargesComponent implements OnInit, OnDestroy {
  private _apiService = inject(ApiService);
  constructor( private _fb: FormBuilder){}
 
  public showDialog = signal<boolean>(false);

  public chargeNameFilter = signal<string>('');
  public userTypeFilter = signal<number>(0);

  public chargesForm:FormGroup = this._fb.group({
    namePosition: ['', Validators.required],
    typeUserID: ['', Validators.required],
  });

  public filteredCharges= signal<IPositions[]>([]);
  public userTypes = signal<ITypeUser[]>([]);
  private _fetchedAllData = signal<boolean>(true);
  public chargeToEdit = signal<IPositions | null>(null);
  private _chagesNameFilterSubject = new Subject<string>();
  private _rangesSubscription:Subscription = new Subscription();


  ngOnInit(): void {
    this.initialData();
    this._chagesNameFilterSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        this.chargeNameFilter.set(value);
        if (value.length <= 3) {
          if (!this._fetchedAllData()) {
            this._fetchedAllData.set(true);
            return this.applyFilters()
          }
          return of(null);
        }

        this._fetchedAllData.set(false);
        return this.applyFilters();
      })
    ).subscribe({
      next: (result) => {
        if (result)
          this.filteredCharges.set(result);
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  private initialData(){
    Swal.fire({
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    forkJoin({
      userType: this._apiService.get<ITypeUser>({ path: 'TypeUsers' }),
      positions: this.applyFilters()
    }).subscribe({
      next: ({ userType, positions }) => {
        this.userTypes.set(userType);

        this.filteredCharges.set(positions);
        Swal.close();
      }, error: (errd) => {
        console.log(errd);
      }
    })
  }

  public onSave() {
    if(this.chargesForm.invalid){
      this.chargesForm.markAllAsTouched();
      return;
    }

    if (this.chargeToEdit()) this.editCharge()
    else this.createCharge()
  }

  private createCharge() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando cargo, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.post<IPositions>({
      path: "Positions",
      data: {
        ...this.chargesForm.value
      }
    }).pipe(
      switchMap( _ => this.applyFilters())
    ).subscribe({
      next: (resp) => {
        Swal.fire({
          icon: "success",
          text: "Se guardó el cargo satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.filteredCharges.set(resp);
        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar el cargo: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  private editCharge() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando cargo, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.patch<IPositions>({
      path: `Positions/${this.chargeToEdit()!.id}`,
      data: {
        id: this.chargeToEdit()!.id,
        ...this.chargesForm.value
      }
    }).pipe(
      switchMap( _ => this.applyFilters())
    ).subscribe({
      next: (resp) => {
        Swal.fire({
          icon: "success",
          text: "Se guardó el cargo satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.filteredCharges.set(resp);

        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar el cargo: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  public onNameChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this._chagesNameFilterSubject.next(value);
  }

  public onUserTypeChange(value: string) {
    this.userTypeFilter.set(Number(value));

    this._rangesSubscription.unsubscribe();
    this._rangesSubscription = this.applyFilters().subscribe({
      next: (resp) => {
        this.filteredCharges.set(resp);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  private applyFilters(): Observable<IPositions[]> {
    const chargeName = this.chargeNameFilter();
    const userTypeId = Number(this.userTypeFilter());

    const chargesFilter: Record<string, any> = { and:[] };

    if (chargeName.length > 3)
      chargesFilter['and'] = [...chargesFilter['and'], { namePosition: { regexp: `/${chargeName}/i` } }];

    if (userTypeId !== 0)
      chargesFilter['and'] = [...chargesFilter['and'], { typeUserID: userTypeId }];

    return this.chargesQuery(chargesFilter)
  }

  private chargesQuery(filters?: Record<string, any>): Observable<IPositions[]> {
    const newFilters: any = { include: ['TypeUser'] }

    if (filters) newFilters.where = { ...filters }

    return this._apiService.get<IPositions>({
      path: 'Positions',
      filter: { ...newFilters }
    })
  }

  public onEditCharge(chargeItem: IPositions) {
    this.chargeToEdit.set(chargeItem);

    this.chargesForm.patchValue({
      ...chargeItem,
    });

    this.showDialog.set(true);
  }

  public resetForm() {
    this.chargesForm.reset();
    this.chargeToEdit.set(null);
    this.showDialog.set(false);
  }

  ngOnDestroy(): void {
    this._rangesSubscription.unsubscribe();
  }
}