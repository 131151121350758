import { Component, signal } from '@angular/core';
import { ForcesComponent } from './components/forces/forces.component';
import { ChargesComponent } from './components/charges/charges.component';
import { RangesComponent } from './components/ranges/ranges.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { Tab } from '@components/tabs/tab';
import { YearsComponent } from './components/years/years.component';
import { GENERAL_CONFIG_TABS } from '@utils/general-config';


@Component({
  selector: 'app-general-config',
  standalone: true,
  imports: [
    YearsComponent,
    ForcesComponent,
    ChargesComponent,
    RangesComponent,
    TabsComponent,
],
  templateUrl: './general-config.component.html',
  styleUrl: './general-config.component.css'
})
export class GeneralConfigComponent {
  public GENERAL_CONFIG_TABS:Tab[] = GENERAL_CONFIG_TABS;
  public selectedTab = signal<string>('years');
}