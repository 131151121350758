import { Component, computed, inject, input, OnChanges, OnDestroy, signal, SimpleChanges } from '@angular/core';
import { ISchool } from '@models/school';
import { ApiService } from '@services/api-service.service';
import { forkJoin, Subject, switchMap, takeUntil } from 'rxjs';
import * as UiSelect from '@components/ui/ui-select';
import { IEndpoint } from '@models/endpoint';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { IUserCourse } from '@models/user-course';
import { CommonModule } from '@angular/common';
import { IRealStatusCourse } from '@models/real-status-course';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

interface Years {id:number, yearNumber:number}
interface SelectItem {label:string, value:number | string}
@Component({
  selector: 'app-enrollment',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,

    UiInputComponent,
    UiButtonComponent,

    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './enrollment.component.html',
  styleUrl: './enrollment.component.css'
})
export class EnrollmentComponent implements OnChanges, OnDestroy{
  constructor(private _fb:FormBuilder){}

  private _apiService = inject(ApiService);

  userId = input.required<number>();

  public loadingView = signal<boolean>(true);
  public schools:ISchool[] = this._apiService.schools();
  public selectedScool = signal<number | string>(0);
  public selectedYear = signal<number | string>(0);
  public selectedState = signal<number | string | null>(null);
  public realStatusCourse = signal<IRealStatusCourse[]>([]);
  public years = signal<Years[]>([]);
  public showEditRowModal = signal<boolean>(false);
  public rowToEdit = signal<IUserCourse | null>(null);
  public userCourses = signal<IUserCourse[]>([]);

  public rowDataForm:FormGroup = this._fb.group({
    comment:[''],
    realStatusCourseID:['']
  })

  private _userCourses = signal<IUserCourse[]>([]);
  private _destroy$ = new Subject<void>();

  public appName = computed<string>(() => {
    const shcool = environment.APP_NAME;
    return shcool === 'celic' ? 'Liceo' : 'Escuela'
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId'])
      this.getInitialData();
  }

  private getInitialData(){
    Swal.fire({
      text: "Cargando vista...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    this.selectedScool.set(0);
    this.selectedYear.set(0);
    this.selectedState.set(0)

    forkJoin({
      years: this._apiService.get<Years>({path:'Years'}),
      realStatusCourse: this._apiService.get<IRealStatusCourse>({path:"RealStatusCourses"}),
      userCourses: this.getCourseOffers()
    }).pipe(
      takeUntil(this._destroy$)
    ).subscribe({
      next:({years, realStatusCourse, userCourses})=>{
        this.userCourses.set(userCourses);
        this._userCourses.set(userCourses);

        this.years.set(years);
        this.realStatusCourse.set(realStatusCourse);
      },error:(err) => {
        console.log(err);
      },complete: () => {
        this.loadingView.set(false);
        Swal.close();
      }
    })
  }

  /**
   * Fetches the course offers associated with the current user.
   */
  private getCourseOffers() {
    const params: IEndpoint = {
      path: "UserCourses",
      filter: {
        include: [{ coursesOfer: 'school' }, 'courseGroup', 'RealStatusCourseID'],
        where: { UserID: this.userId() }
      }
    }
    return this._apiService.get<IUserCourse>(params)
  }

  public onSelectSchool(item: SelectItem): void {
    this.selectedScool.set(item.value)
    this.filterUserCourses();
  }

  public onCloseForm(){
    this.showEditRowModal.set(false);
    this.rowDataForm.reset();
  }

  onSelectYear(item:SelectItem){
    this.selectedYear.set(item.value);
    this.filterUserCourses();
  }

  onSelectState(item:SelectItem){
    this.selectedState.set(item.value);
    this.filterUserCourses();
  }

  private filterUserCourses(){
    const allUserCourses = this._userCourses();
    const schoolId = Number(this.selectedScool());
    const yearValue = Number(this.selectedYear());
    const stateValue = Number(this.selectedState());
  
    const filteredBySchool = allUserCourses.filter(userCourse => {
      const matchesSchool = schoolId === 0 || userCourse.coursesOfer?.school.id === schoolId;
      const matchesYear = yearValue === 0 || userCourse.coursesOfer?.year === yearValue;
      const matchesState = stateValue === 0 || userCourse.RealStatusCourseID?.id === stateValue;
  
      return matchesSchool && matchesYear && matchesState;
    });
  
    this.userCourses.set(filteredBySchool);
  }

  public editRow(userCourse:IUserCourse){
    this.rowToEdit.set(userCourse);

    this.rowDataForm.patchValue({
      comment: userCourse.comment,
      realStatusCourseID: userCourse.realStatusCourseID
    });

    this.showEditRowModal.set(true);
  }

  public saveRowData() {
    if (!this.rowToEdit())
      return

    Swal.fire({
      title: "Guardando...",
      text: 'Estamos guardando su comentario, por favor, espere un momento.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading()
    });

    this._apiService.patch({
      path: `UserCourses/${this.rowToEdit()!.id}`,
      data:{
        ...this.rowDataForm.value,
        realStatusCourseID: this.rowDataForm.value.realStatusCourseID == 0
          ? null
          : this.rowDataForm.value.realStatusCourseID
      }
    }).pipe(
      switchMap(() => this.getCourseOffers())
    ).subscribe({
      next: (resp) => {
        this.userCourses.set(resp);
        Swal.fire({
          icon: 'success',
          title: "Comentario guardado",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          if (result.isConfirmed)
            this.onCloseForm();
        });
      }, error: (err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Error",
          text: `Error al guardar el comentario: ${err.message || 'Internal server error'}`
        })
      }
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}