<div class="roles">
  <div class="roles__section">
    @if (hasMultipleSchools()) {
      <div class="roles__alert">
        Este usuario tiene roles en diferentes {{appName()}}s. <br> <br> 1. Seleccionar la institución correspondiente. <br> 2. Hacer clic en <strong>"Guardar {{appName()}}"</strong>.
      </div>
    }
  </div>

  <div class="roles__section">
    <div class="roles__header">
      <h2 class="roles__title">{{appName()}} al que pertenece</h2> 
    </div>

    <div class="school__select">
      <div class="select__input">
        <ui-select label="Transferir de {{appName()}}" variant="bordered" [(ngModel)]="selectedSchoolId">
          <ui-select-content>
            @for (item of schools; track $index) {
              <ui-select-item [value]="item.id!">
                {{item.NameTSchool}}
              </ui-select-item>
            }
          </ui-select-content>
        </ui-select>
      </div>
      <button class="button button--solid" (click)="setNewSchool()">Guardar {{appName()}}</button>
    </div>
  </div>

  <div class="roles__section">
    <div class="roles__header">
      <h2 class="roles__title">Roles Asignados</h2> 
      <button class="button button--solid" (click)="saveRoles()">Guardar roles</button>
    </div>

    <div class="roles__container">
      @for (role of allRoles(); track $index) {
        <label class="column__item" [ngClass]="{'column__item--checked': role.checked}">
          <input type="checkbox" [checked]="role.checked" (change)="onRoleChange($event, role)">
          <span class="column__label">{{role.name}}</span>
        </label>
      }
    </div>
  </div>
</div>