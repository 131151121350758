import { Tab } from "@components/tabs/tab";

export const PARAMETER_TABS:Tab[] = [
  {
    id:'forces',
    label:"Fuerzas"
  },
  {
    id:'charges',
    label:"Cargos"
  },
  {
    id:'ranges',
    label:"Rangos"
  },
  {
    id:'salary',
    label:"Salario"
  },
  {
    id:'relationship',
    label:"Parentescos"
  },
  {
    id:'categories',
    label:"Categorias"
  },
  {
    id:'services',
    label:"Servicios"
  },
]