import { Component, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { ITypeRelationship } from '@models/parent-to-students';
import { ApiService } from '@services/api-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-parents',
  standalone: true,
  imports: [UiInputComponent, ReactiveFormsModule, UiButtonComponent],
  templateUrl: './parents.component.html',
  styleUrl: './parents.component.css'
})
export class ParentsComponent implements OnInit {
  constructor(private _fb:FormBuilder){}
  private _apiService = inject(ApiService);

  public relationshipForm:FormGroup = this._fb.group({
    nameRelationship: ['', Validators.required],
  });

  public relationships = signal<ITypeRelationship[]>([]);
  public showDialog = signal<boolean>(false);
  public relationshipName = signal<string>('');
  public relationshipToEdit = signal<ITypeRelationship | null>(null);

  ngOnInit(): void {
    this.initialData();
  }

  private initialData(){
    Swal.fire({
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    this._apiService.get<ITypeRelationship>({
      path: 'TypeRelationships'
    }).subscribe({
      next: (resp) => {
        this.relationships.set(resp);
        Swal.close();
      }, error: (err) => {
        console.log(err);
      }
    })
  }

  public onSave(){
    if(this.relationshipForm.invalid){
      this.relationshipForm.markAllAsTouched();
      return;
    }

    if (this.relationshipToEdit()) this.editRelationship()
    else this.createRelationship()
  }

  private createRelationship() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando el parentesco, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.post<ITypeRelationship>({
      path: "TypeRelationships",
      data: {
        ...this.relationshipForm.value
      }
    }).subscribe({
      next: (resp) => {
        Swal.fire({
          icon: "success",
          text: "Se guardó el parentesco satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.relationships.update(prev => [resp, ...prev]);
        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar el parentesco: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  private editRelationship(){
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando parentesco, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.patch<ITypeRelationship>({
      path: `TypeRelationships/${this.relationshipToEdit()!.id}`,
      data: {
        id: this.relationshipToEdit()!.id,
        ...this.relationshipForm.value
      }
    }).subscribe({
      next: (resp) => {
        Swal.fire({
          icon: "success",
          text: "Se guardó la fuerza satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.relationships.update(prev => {
          return prev.map(relation => relation.id === resp.id ? { ...relation, ...resp } : relation);
        });

        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar el parentesco: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  public resetForm() {
    this.relationshipForm.reset();
    this.relationshipToEdit.set(null);
    this.showDialog.set(false);
  }

  public onEditParent(item:ITypeRelationship){
    this.relationshipToEdit.set(item);
    this.relationshipForm.patchValue({
      ...item
    })
    this.showDialog.set(true);
  }
}