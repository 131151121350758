import { Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { msDataResolver } from './resolvers/ms-data-resolver.resolver';
import { CostsComponent } from './pages/private/costs/costs.component';
import { ParametersComponent } from './pages/private/parameters/parameters.component';
import { ServicesComponent } from './pages/private/services/services.component';
import { PayrollsComponent } from './pages/private/payrolls/payrolls.component';
import { GeneralConfigComponent } from './pages/private/general-config/general-config.component';
export const routes: Routes = [
  {
    path: 'manage-academic-costs',
    resolve:{
      msData: msDataResolver
    },
    children: [
      {
        path: 'home',
        component: CostsComponent,
      },
      {
        path: 'parameters',
        component: ParametersComponent,
      },
      {
        path: 'services',
        component: ServicesComponent,
      },
      {
        path: 'payrolls',
        component: PayrollsComponent,
      },
      {
        path: 'general-config',
        component: GeneralConfigComponent,
      }
    ],
  },
  { path: '**', component: EmptyRouteComponent },
];
