import { CommonModule } from '@angular/common';
import { Component, inject, input, OnChanges, OnDestroy, signal, SimpleChanges } from '@angular/core';
import * as UiSelect from '@components/ui/ui-select';
import { IServicePerUser } from '@models/service-per-user';
import { ApiService } from '@services/api-service.service';
import { concat, map, Observable, Subscription, switchMap, toArray } from 'rxjs';
import Swal from 'sweetalert2';


interface IServicePerUserWithPrice extends IServicePerUser {
  price?: Record<string, any>;
}
@Component({
  selector: 'app-services',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
    CommonModule
  ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.css'
})
export class ServicesComponent implements OnChanges, OnDestroy {
  private _apiService = inject(ApiService);

  userId = input.required<number>();
  years = input.required<{yearNumber:number}[]>();

  private _yearFilter = signal<number>(2024);
  public filteredServices = signal<IServicePerUserWithPrice[]>([]);
  private _subscription:Subscription = new Subscription();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['userId'] && changes['userId'].currentValue)
      this.getMainData();
  }

  public getServicesOnChange(value:string){
    this._yearFilter.set(Number(value));
    this._subscription.unsubscribe();
    this._subscription = this.serviceQuery().subscribe({
      next: (resp) => {                
        this.filteredServices.set(resp);
        Swal.close();
      }, error: (err) => {
        console.log(err);
      }
    });
  }

  private getMainData() {
    Swal.fire({
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this.serviceQuery().subscribe({
      next: (resp) => {
        console.log(resp);
        this.filteredServices.set(resp);
        Swal.close();
      }, error: (err) => {
        console.log(err);
      }
    });
  }

  private serviceQuery(): Observable<IServicePerUserWithPrice[]> {
    return this._apiService.get<IServicePerUser>({
      path: "ServicePerUsers",
      filter: {
        where: { and: [{ userID: this.userId() }, { year: this._yearFilter() }] },
        include: ['Service', 'CourseOffer', { User: { parentsToStudents: 'parentUser' } }]
      }
    }).pipe(
      switchMap((servies => {
        const prices = servies.map(service => {
          return this._apiService.post({
            path: "Services/getPrice",
            data: { userID: this.userId(), year: this._yearFilter(), serviceID: service.serviceID },
          }).pipe(map((resp:any) => ({...service, price: resp})))
        }
      );
      return concat(...prices).pipe(toArray())
    })))
  }

  public deleteService(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: "Eliminando...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => Swal.showLoading()
        })

        this._apiService.delete({
          path: `ServicePerUsers/${id}`
        }).subscribe({
          next: (_) => {
            Swal.fire({
              icon: 'success',
              title: 'Servicio eliminado',
            })
            this.filteredServices.update(services => services.filter(service => service.id !== id));
          }, error: (err) => {
            Swal.fire({
              icon: 'error',
              title: 'Ocurrió un error',
              text: err.error.message || 'Error interno del servidor'
            })
            console.log(err);
          },
        })
      }
    })
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}