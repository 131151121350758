<div class="charges">
  <div class="charges__actions">
    <ui-input label="Nombre del cargo" (keyup)="onNameChange($event)"/>

    <ui-select label="Tipo de usuario" (onValueChange)="onUserTypeChange($event.value)" defaultValue="0">
      <ui-select-content>
        <ui-select-item value="0">Todos</ui-select-item>
        @for (item of userTypes(); track $index) {
          <ui-select-item [value]="item.id">{{item.nameTypeUser}}</ui-select-item>
        }
      </ui-select-content>
    </ui-select>
  </div>

  <div class="charges__header">
    <h2 class="header__title">Cargos</h2>
    <button class="button button--solid" (click)="showDialog.set(true)">Agregar Cargo</button>
  </div>

  <div class="charges__content">
    <div class="charges__list">
      @for (item of filteredCharges(); track $index) {
        <div class="charges__item">
          <div class="item__content">
            <div class="charges__name">{{item.namePosition}}</div>
            <div class="user__type">Usuario: <strong>{{item.TypeUser?.nameTypeUser || '-'}}</strong></div>
          </div>
  
          <button class="button button--edit-force">
            <em class="icon-i-pen" (click)="onEditCharge(item)"></em>
          </button>
        </div>
      }@empty {
        <span class="empty-list">Sin resultados.</span>
      }
    </div>
  </div>


  @if (showDialog()) {
  <div class="dialog__container">
    <form class="dialog__content" [formGroup]="chargesForm" (ngSubmit)="onSave()">
      <div class="dialog__header">
        <h2 class="dialog__header-title">{{chargeToEdit() ? 'Editar' : 'Agregar'}} Cargo</h2>
        <em class="icon-i-close dialog__header-close" (click)="resetForm()"></em>
      </div>

      <div class="dialog__body">
        <div>
          <ui-input variant="bordered" label="Nombre del cargo*" formControlName="namePosition"
            [invalid]="chargesForm.get('namePosition')?.invalid && chargesForm.get('namePosition')?.touched" />
          @if(chargesForm.get('namePosition')?.invalid && chargesForm.get('namePosition')?.touched){
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-select label="Tipo de usuario*" formControlName="typeUserID"
            [invalid]="chargesForm.get('typeUserID')?.hasError('required') && chargesForm.get('typeUserID')?.touched">
            <ui-select-content>
              @for (item of userTypes(); track $index) {
                <ui-select-item [value]="item.id">{{item.nameTypeUser}}</ui-select-item>
              }
            </ui-select-content>
          </ui-select>
          @if(chargesForm.get('typeUserID')?.invalid && chargesForm.get('typeUserID')?.touched){
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>
      </div>

      <ui-button color="primary" [disabled]="chargesForm.invalid">Guardar</ui-button>
    </form>
  </div>
  }
</div>