import { Component, OnInit, signal } from '@angular/core';
import * as UiSelect from '@components/ui/ui-select';
import { UiDateInputComponent } from '@components/ui/ui-date-input/ui-date-input.component';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '@services/api-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-generate',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
    UiDateInputComponent,
    UiButtonComponent,
    ReactiveFormsModule
  ],
  templateUrl: './generate.component.html',
  styleUrl: './generate.component.css'
})
export class GenerateComponent implements OnInit{
  constructor(private _fb: FormBuilder, private _apiService:ApiService) {}

  public payrollForm = this._fb.group({
    discountCode: [null],
    isApproved: [null],
    billingCycle: [null],
    startYear: [null],
    startMonth: [null],
    endYear: [null],
    endMonth: [null],
    value: [null],
  });

  private USER_ID: string = '';
  public checkErrors = signal<boolean>(false);
  public submitting = signal<boolean>(false);

  ngOnInit(): void {
    this.USER_ID = localStorage.getItem('currentUser') || '';
  }

  public onSubmit(): void {
    if(this.submitting())
      return;
    
    if(!this.payrollForm.valid){
      this.checkErrors.set(true);
      return;
    }

    this.submitting.set(true);
    Swal.fire({
      title: 'Generando Nómina',
      text: 'Por favor espere...',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    this._apiService.post({
      path: 'PayrollsMinistry',
      data: {
        ...this.payrollForm.value,
        userID: Number(this.USER_ID)
      }
    }).subscribe({
      next: (res:any) => {
        console.log(res);
        this.payrollForm.reset();
        Swal.fire({
          title: 'Nómina Generada',
          text: "La nómina se ha generado correctamente",
          icon: 'success',
        })
      },
      error: (err:any) => {
        console.log(err);
        Swal.fire({ 
          title: 'Error',
          text: `${err.message || "Error al generar la nómina"}`,
          icon: 'error',
          allowOutsideClick: true,
          allowEscapeKey: false,
        })
      },
      complete: () => {
        this.submitting.set(false);
        this.checkErrors.set(false);
      }
    })
  }
}