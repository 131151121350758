<div class="history">
  <div class="history__header">
    <div class="history__filters">
      <ui-select label="Autorización/Pagos" [(ngModel)]="selectedAuthorization">
        <ui-select-content>
          @for(authorization of authorizations; track $index) {
            <ui-select-item [value]="$index">{{authorization}}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
      <ui-select label="Código" [(ngModel)]="selectedCode" (onChange)="onApplyFilters()">
        <ui-select-content>
          @for(code of codes(); track $index) {
            <ui-select-item [value]="code">{{code}}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
      <ui-select label="Año" [(ngModel)]="selectedYear">
        <ui-select-content>
          @for(year of years(); track $index) {
            <ui-select-item [value]="year.yearNumber">{{year.yearNumber}}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
      <ui-select label="Mes" (onChange)="onApplyFilters()" [(ngModel)]="selectedMonth">
        <ui-select-content>
          @for(month of months; track $index) {
            <ui-select-item [value]="$index + 1">{{month}}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
    </div>

    <div class="history__actions">
      <button class="button button--solid"
      (click)="downloadTxtReport()">Descargar archivo SICOD</button>
      <button class="button button--outline" (click)="generateExcelFile()">Descargar Excel</button>
    </div>
  </div>

  <div class="history__content">
    <div class="history__content-header">
      <div class="history__content-title">
        Registro de control de Ejército
      </div>

      <button class="button button--outline"
        (click)="onEditAll()">
        <em class="icon-i-pen edit-icon"></em>
        Editar Valores
      </button>
    </div>

    <div class="history__content-table">
      <table>
        @if(loading()) {
          <caption class="table__caption"><div class="loading-spinner"></div></caption>
        }

        @if(data().length === 0 && !loading()) {
          <caption class="table__caption">Sin Resultados</caption>
        }

        <thead>
          <tr>
            <th>Indice</th> 
            <th>No.Identificación</th>
            <th>Codigo de Descuento</th>
            <th>Nombre</th>
            <th>Autorizado</th>
            <th>Fecha Inicio</th>
            <th>Fecha Término</th>
            <th>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          @for(payroll of data(); track $index) {
            <tr>
              <td>{{$index + 1}}</td>
              <td>{{payroll.Userapp.MilitarInfo?.CedulaMil || 'n/a'}}</td>
              <td>{{payroll.discountCode}}</td>
              <td>{{payroll.Userapp.Name1}} {{payroll.Userapp.Name2}} {{payroll.Userapp.LastName1}} {{payroll.Userapp.LastName2}}</td>
              <td>{{payroll.isApproved ? 'Si' : 'No'}}</td>
              <td>{{payroll.startYear}} / {{payroll.startMonth}}</td>
              <td>{{payroll.endYear}} / {{payroll.endMonth}}</td>
              <td>
                @if(editAll() || payroll.isEditing) {
                  <input type="text" class="input input--table" [value]="payroll.value" (input)="onCurrencyChange($event, payroll)">
                } @else {
                  <span>{{payroll.value | currency: 'COP '}}</span>
                }
              </td>
              <td>
                <div class="content-table__actions">
                  @if(!editAll()) {
                    <button class="button" (click)="payroll.isEditing = !payroll.isEditing">
                      <em class="icon-i-pen edit-icon"></em>  
                    </button>
                  }
                  <button class="button" (click)="saveValue(payroll)">
                    <em class="icon-i-chulo save-icon"></em>
                  </button>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>