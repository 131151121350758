import { CommonModule } from '@angular/common';
import { Component, inject, input, OnDestroy, OnInit, output, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiDateInputComponent } from '@components/ui/ui-date-input/ui-date-input.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import * as UiSelect from '@components/ui/ui-select';
import { IEndpoint } from '@models/endpoint';
import { IForce, IRange } from '@models/militar-info';
import { IParentToStudents } from '@models/parent-to-students';
import { IUserUserApp } from '@models/users';
import { ApiService } from '@services/api-service.service';
import { catchError, concat, of, Subject, switchMap, takeUntil, toArray } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ParentsService } from '../parents.service';

@Component({
  selector: 'app-user-data',
  standalone: true,
  imports: [
    CommonModule, 
    ReactiveFormsModule,
    UiInputComponent,
    UiDateInputComponent,
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
  ],
  templateUrl: './user-data.component.html',
  styleUrl: './user-data.component.css'
})
export class UserDataComponent implements OnInit, OnDestroy{
  private _fb = inject(FormBuilder);
  private _apiService = inject(ApiService);
  private _parentsService = inject(ParentsService);

  onSaveData = output<void>();
  onLoadData = output<boolean>();
  userType = input.required<"guardian" | "responsible">();
  isEditing = input<boolean>();
  userId = input.required<number>();
  parentToStudent = input.required<IParentToStudents | null>();

  public forces = signal<IForce[]>([]);
  public isMilitar = signal<boolean>(false);

  public userForm = signal<FormGroup>(this._fb.group({
    basicInfo: this._fb.group({
      names: [''],
      lastNames: [''],
      TypeDocumentID: [''],
      Document: [''],
      typeConditionID: [''],
      Email: ['', [Validators.email, Validators.required]],
      CedocEmail: ['', [Validators.email, Validators.required]],
      typeUserID: ['']
    }),
    contactInfo: this._fb.group({
      birthDate: [''],
      Address: [''],
      CellPhone: [''],
      Phone: [''],
    }),
    parentToStudents: this._fb.group({
      relationshipId: [''],
    }),
    classificationUser: this._fb.group({
      biologicalSexID: [''],
      currentOccupation: [''],
      civilStatusID: [''],
    }),
    militaryInfo: this._fb.group({
      realRangeID: [{value: '', disabled:true}],
      ForceID: [''],
      CedulaMil: [''],
      OtanID: [''],
    }),
    workInfo: this._fb.group({
      Company: [''],
      Position: [''],
      Phone: [''],
      workEmail: [''],
      Address: [''],
      Unit: [''],
    })
  }));
  
  public loadingData = signal<boolean>(true);
  public appName:string = environment.APP_NAME;
  public ranges = signal<IRange[]>([]);
  public userData = signal<IUserUserApp | null>(null);
  public relationShipName = signal<string>('-');
  private _destroy$ = new Subject<void>();

  public ngOnInit(): void {
    this._parentsService.currentUserId$
      .pipe(takeUntil(this._destroy$))
      .subscribe(_ => this.getInitialData())
  }

  get basicData(){
    return this._parentsService.BASIC_DATA!;
  }

  private getInitialData(){
    this.userForm().reset();
    this.onLoadData.emit(false);

    this.getUserData()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        error: (err) => {
          Swal.close();
          console.log(err);
        }, complete: () => {          
          this.onLoadData.emit(true);
          this.loadingData.set(false);
        }
      })
  }

  private patchForm(){
    const data = this.userData()!;

    const name1 = data.Name1;
    const name2 = data.Name2;
    const lastName1 = data.LastName1;
    const lastName2 = data.LastName2;

    this.userForm().patchValue({
      basicInfo:{
        ...data.UserDocuments[0],
        ...data,
        names: `${name1} ${name2 || ''}`.trim(),
        lastNames: `${lastName1} ${lastName2 || ''}`.trim(),
        typeConditionID: data.typeConditionID,
        Email: this.appName === 'cedoc' ?
          data.ContactInfos[0].Email :
          data.email,
      },
      contactInfo:{
        ...data.ContactInfos[0],
        ...(data.ContactInfos[0]?.birthDate
          ? { birthDate: new Date(data.ContactInfos[0].birthDate).toISOString().split('T')[0] }
          : {}),
      },
      classificationUser: {
        ...data.ClassificationUser[0]
      },
      militaryInfo:{
        ...data.MilitarInfo
      },
      workInfo:{
        ...data.WorkInfos[data.WorkInfos.length - 1]
      },
      parentToStudents: {
        relationshipId: (this.userType() === "guardian"
          ? this.parentToStudent()!.typeRelationshipIDAttended 
          : this.parentToStudent()!.typeRelationshipIDParent) || null
      }
    });

    const relationshipId = this.userForm().get('parentToStudents.relationshipId')?.value;
    if (relationshipId) {
      const relationshipName = this.basicData.typeRelationship.find(e => e.id == relationshipId);

      this.relationShipName.set(
        relationshipName ? relationshipName.nameRelationship : (this.userType() === "guardian"
          ? this.parentToStudent()!.typeRlelationshipAttended?.nameRelationship
          : this.parentToStudent()!.typeRlelationshipParent?.nameRelationship) || '-'
      )
    }

    if (this.userForm().get("militaryInfo.ForceID")?.value)
      this.userForm().get("militaryInfo.realRangeID")?.enable();

    this.loadRanges();
  }


  printFormErrors() {
    const form = this.userForm();
    const result: string[] = [];
  
    const findInvalidControlsRecursive = (formGroup: FormGroup, parentKey: string = ''): void => {
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        const controlKey = parentKey ? `${parentKey}.${key}` : key;
  
        if (control instanceof FormGroup) {
          findInvalidControlsRecursive(control, controlKey);
        } else if (control?.invalid) {
          result.push(controlKey);
        }
      });
    };
  
    findInvalidControlsRecursive(form);
  
    console.log('Invalid fields:', result);
  
    // Optionally, display these errors in the UI
    result.forEach(field => {
      console.log(`Field ${field} is invalid`);
    });
  }

  public saveData(){
    if(!this.userForm().valid)
      return;

    Swal.fire({
      text:"Guardando...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading()
    });

    concat(
      this.updateBasicData(),
      this.updateUserappEmail(),
      this.updateContactInfo(),
      this.updateRelationShip(),
      this.updateClassificationInfo(),
      this.updateMilitaryInfo(),
      this.updateWorkInfo()
    ).pipe(
      takeUntil(this._destroy$),
      toArray(),
      switchMap(_ => this.getUserData())
    )
      .subscribe({
        next: _ => {
          Swal.fire({
            icon: 'success',
            text: "Información almacenada con éxito.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true
          });

          this.onSaveData.emit();
        }, error: (err) => {
          Swal.fire({
            icon: 'error',
            title: "Error",
            text: `${err.message || 'Error interno del servidor.'}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true
          });
          console.log(err);
        }
      });
  }

  private updateBasicData(){
    const studentData = this.userData()!;

    const { TypeDocumentID, Document, ...newUserData } = this.userForm().get("basicInfo")?.value;
    const names = newUserData.names.split(' ');
    const lastNames = newUserData.lastNames.split(' ');

    const userParams:IEndpoint = {
      path: `Userapps/${studentData.id}`,
      data: {
        ...newUserData,
        Name1: names[0],
        Name2: names[1] || null,
        LastName1: lastNames[0],
        LastName2: lastNames[1] || null,
        IsMilitar: this.userForm().get("basicInfo.typeUserID")?.value == 1
      }
    }

    return this._apiService.patch(userParams).pipe(
      switchMap(_ => {
        const documentParams:IEndpoint = {
          path: `UserDocuments/${studentData.UserDocuments[0].id}`,
          data: {
            Document,
            TypeDocumentID
          }
        }
        return this._apiService.patch(documentParams);
      })
    )
  }

  private updateContactInfo(){
    const studentData = this.userData()!;

    const contactParams:IEndpoint = {
      path: `ContactInfos/${studentData.ContactInfos[0].id}`,
      data: this.userForm().get("contactInfo")?.value
    }

    return this._apiService.patch(contactParams); 
  }

  private updateRelationShip() {
    const selectedRelation = this.userForm().get("parentToStudents.relationshipId")?.value;
    const data = this.userType() === "guardian"
      ? { typeRelationshipIDAttended: selectedRelation }
      : { typeRelationshipIDParent: selectedRelation }

    const classificationParams: IEndpoint = {
      path: `ParentsToStudents/${this.parentToStudent()!.id}`,
      data
    }

    return this._apiService.patch(classificationParams);
  }

  private updateClassificationInfo(){
    const studentData = this.userData()!;

    const classificationParams:IEndpoint = {
      path: "ClassificationUsers",
      data: {
        id: studentData.ClassificationUser.length > 0 ? studentData.ClassificationUser[0].id : null,
        UserID: studentData.id!,
        ...this.userForm().get("classificationUser")?.value
      }
    }

    return this._apiService.patch(classificationParams); 
  }

  private updateMilitaryInfo(){
    const studentData = this.userData()!;

    const militaryInfoParams:IEndpoint = {
      path: "MilitarInfos",
      data: {
        id: studentData.MilitarInfo ? studentData.MilitarInfo.id : null,
        UserID: studentData.id!,
        ...this.userForm().get("militaryInfo")?.value
      }
    }

    return this._apiService.patch(militaryInfoParams); 
  }

  private updateWorkInfo(){
    const studentData = this.userData()!;
    if(studentData.WorkInfos.length === 0)
      return of(null);

    const workInfoParams:IEndpoint = {
      path: "WorkInfos",
      data: {
        id: studentData.WorkInfos.length > 0 ? studentData.WorkInfos[studentData.WorkInfos.length - 1].id : null,
        UserID: studentData.id!,
        ...this.userForm().get("workInfo")?.value
      }
    }

    return this._apiService.patch(workInfoParams); 
  }

  private updateUserappEmail() {
    const studentData = this.userData()!;

    return this._apiService.post({
      path: `/Userapps/${studentData.id}/updateUserappEmail`,
      data:{ email: this.userForm().get("basicInfo.Email")?.value }
    }).pipe(
      catchError(err => {
        console.error('Error al actualizar userapp email:', err);
        return of(null);
      })
    );
  }

  public onChangeForce(){
    this.userForm().patchValue({ militaryInfo:{ realRangeID:null } });
    this.userForm().get("militaryInfo.realRangeID")?.enable();
    this.loadRanges();
  }

  public onChangeTypeUser(){
    const typeUserID = this.userForm().get("basicInfo.typeUserID")?.value;
    const isMilitar = this.basicData.typeUser.find(e => e.id == typeUserID);
    this.isMilitar.set(isMilitar?.isMilitar ?? false);

    this.forces.set([]);
    this.ranges.set([]);

    this.getForcesQuery().subscribe({
      next: (resp) => {
        this.forces.set(resp);
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  private loadRanges(){
    this.ranges.set([]);
    const forceValue = this.userForm().get("militaryInfo.ForceID")?.value;
    const params:IEndpoint = {
      path:'Ranges',
      filter:{
        where:{ ForceID: forceValue }
      }
    }
    this._apiService.get<IRange>(params).subscribe({
      next:(resp)=>{
        this.ranges.set(resp);
      },error:(err)=>{
        console.log(err);
      }
    })
  }

  private getUserData(){
    const finalUser = {
      path: 'userapps',
      filter: {
        include: ["ContactInfos", "TypeCondition", 'TypeUser', { MilitarInfo: ['RealRange', 'Force'] }, 'WorkInfos', { ClassificationUser: ["typeBiologicalSex", "typeRH", "typeCivilStatus"] }, { UserDocuments: "TypeDocuments" }],
        where: { id: this.userId() },
      }
    }

    return this._apiService.get<IUserUserApp>(finalUser)
      .pipe( 
        takeUntil(this._destroy$),
        switchMap(userData => {
          const user = userData[0];
          this.userData.set(user);
          this.isMilitar.set(user.TypeUser?.isMilitar ?? false);
          this.patchForm();

          return this.getForcesQuery().pipe(
            switchMap(forces => {
              this.forces.set(forces);
              return of([])
            })
          )
        })
      )
  }

  private getForcesQuery(){
    return this._apiService.get<IForce>({
      path:'Forces',
      filter:{
        where:{ typeUserID: this.userForm().get("basicInfo.typeUserID")?.value }
      }
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
