@if (!loadingData()) {
  <div class="students">
    <ng-container *ngIf="isEditing(); else noEditable">
      <form class="students__form" [formGroup]="studentForm()">
        <div class="students__input" formGroupName="basicInfo">
          <ui-input variant="bordered" label="Nombres" formControlName="names"/>
        </div>

        <div class="students__input" formGroupName="basicInfo">
          <ui-input variant="bordered" label="Apellidos" formControlName="lastNames"/>
        </div>

        <div class="students__input" formGroupName="contactInfo">
          <ui-date-input variant="bordered" label="Fecha de nacimiento" formControlName="birthDate"/>
        </div>

        <div class="students__input" formGroupName="classificationUser">
          <ui-select variant="bordered" label="Sexo biológico" formControlName="biologicalSexID">
            <ui-select-content>
              <ui-select-item [value]="item.id" *ngFor="let item of biologicalSex()">
                {{item.nameBiologicalSex}}
              </ui-select-item>
            </ui-select-content>
          </ui-select>
        </div>

        <div class="students__input" formGroupName="basicInfo">
          <ui-select variant="bordered" label="Tipo de Documento" formControlName="TypeDocumentID">
            <ui-select-content>
              <ui-select-item [value]="item.id" *ngFor="let item of typeDocuments()">
                {{item.NameTypeDocument}}
              </ui-select-item>
            </ui-select-content>
          </ui-select>
        </div>

        <div class="students__input" formGroupName="basicInfo">
          <ui-input variant="bordered" label="No. Documento" formControlName="Document"/>
        </div>

        <div class="students__input" formGroupName="classificationUser">
          <ui-input variant="bordered" label="EPS" formControlName="eps"/>
        </div>

        <div class="students__input" formGroupName="classificationUser">
          <ui-select variant="bordered" label="Tipo de Sangre" formControlName="rhID">
            <ui-select-content>
              <ui-select-item [value]="item.id" *ngFor="let item of bloodType()">
                {{item.nameRH}}
              </ui-select-item>
            </ui-select-content>
          </ui-select>
        </div>

        <div class="students__input" formGroupName="contactInfo">
          <ui-select variant="bordered" label="Nacionalidad" formControlName="Nacionality">
            <ui-select-content>
              <ui-select-item [value]="item.id" *ngFor="let item of nationality()">
                {{item.country_name}}
              </ui-select-item>
            </ui-select-content>
          </ui-select>
        </div>

        <div class="students__input" formGroupName="classificationUser">
          <ui-input variant="bordered" label="Seguro" formControlName="ensuranceCompany"/>
        </div>

        <div class="students__input" formGroupName="contactInfo">
          <ui-input variant="bordered" label="Dirección de domicilio" formControlName="Address"/>
        </div>

        <div class="students__input" formGroupName="contactInfo">
          <ui-input variant="bordered" label="Teléfono Celular" formControlName="CellPhone"/>
        </div>

        <div class="students__input" formGroupName="contactInfo">
          <ui-input variant="bordered" label="Teléfono Casa" formControlName="Phone"/>
        </div>
        
        <div class="students__input" formGroupName="basicInfo">
          <ui-input variant="bordered" label="Correo institucional" formControlName="CedocEmail"/>
          <small class="validation-feedback--text" *ngIf="studentForm().get('basicInfo.CedocEmail')?.hasError('email')">
            Correo no válido
          </small>
        </div>

        <div class="students__input" formGroupName="basicInfo">
          <ui-input variant="bordered" label="Correo personal" formControlName="Email"/>
          <small class="validation-feedback--text" *ngIf="studentForm().get('basicInfo.Email')?.hasError('email')">
            Correo no válido
          </small>
        </div>
      </form>
    </ng-container>
  </div>
}

<ng-template #noEditable>
  <div class="students__field">
    <div class="field__label">Nombres</div>
    <div class="field__data">{{studentForm().get('basicInfo.names')?.value}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">Apellidos</div>
    <div class="field__data">{{studentForm().get('basicInfo.lastNames')?.value}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">Fecha de nacimiento</div>
    @if(studentData()?.ContactInfos?.[0]?.birthDate){
      <div class="field__data">{{studentData()?.ContactInfos?.[0]?.birthDate | date}}</div>
    }@else {
      <div class="field__data">-</div>
    }
  </div>

  <div class="students__field">
    <div class="field__label">Sexo biológico</div>
    <div class="field__data">{{studentData()?.ClassificationUser?.[0]?.typeBiologicalSex?.nameBiologicalSex || '-' }}</div>
  </div>

  <div class="students__field">
    <div class="field__label">Tipo de documento</div>
    <div class="field__data">{{studentData()?.UserDocuments?.[0]?.TypeDocuments?.NameTypeDocument || '-'}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">No. Documento</div>
    <div class="field__data">{{studentData()?.UserDocuments?.[0]?.Document || '-'}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">EPS</div>
    <div class="field__data">{{studentData()?.ClassificationUser?.[0]?.eps || '-'}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">Tipo de Sangre</div>
    <div class="field__data">{{studentData()?.ClassificationUser?.[0]?.typeRH?.nameRH || '-'}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">Nacionalidad</div>
    <div class="field__data">{{country()?.country_name || '-'}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">Seguro</div>
    <div class="field__data">{{studentData()?.ClassificationUser?.[0]?.ensuranceCompany || '-'}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">Dirección de domicilio</div>
    <div class="field__data">{{studentData()?.ContactInfos?.[0]?.Address || '-'}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">Teléfono Celular</div>
    <div class="field__data">{{studentData()?.ContactInfos?.[0]?.CellPhone || '-'}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">Teléfono Casa</div>
    <div class="field__data">{{studentData()?.ContactInfos?.[0]?.Phone || '-'}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">Correo institucional</div>
    <div class="field__data">{{studentData()?.CedocEmail}}</div>
  </div>

  <div class="students__field">
    <div class="field__label">Correo personal</div>
    <div class="field__data">{{studentData()?.email}}</div>
  </div>
</ng-template>