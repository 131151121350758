import { Component, computed, ElementRef, inject, OnInit, signal, viewChild } from '@angular/core';
import * as UiDropdwon from '@components/ui/ui-dropdown'
import { ApiService } from '@services/api-service.service';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { debounceTime, distinctUntilChanged, filter, Observable, Subject, switchMap } from 'rxjs';
import { IUser } from '@models/users';
import { COST_TABS } from '@utils/cost-tabs';
import { StudentsComponent } from './components/students/students.component';
import { CommonModule } from '@angular/common';
import { HasCapabilityDirective } from 'src/app/shared/directives/has-capabilities';
import { ParentsComponent } from './components/parents/parents.component';
import { EnrollmentComponent } from './components/enrollment/enrollment.component';
import { RolesComponent } from './components/roles/roles.component';
import { environment } from 'src/environments/environment';
import { ServicesComponent } from './components/services/services.component';
import { Tab } from '@components/tabs/tab';
import { TabsComponent } from '@components/tabs/tabs.component';

interface StudentItem { value: string; label: string }

@Component({
  selector: 'app-costs',
  standalone: true,
  imports: [
    UiDropdwon.Root,
    UiDropdwon.Trigger,
    UiDropdwon.Content,
    UiDropdwon.Item,
    UiInputComponent,
    StudentsComponent,
    ParentsComponent,
    EnrollmentComponent,
    RolesComponent,
    ServicesComponent,
    HasCapabilityDirective,
    TabsComponent,
    CommonModule
  ],
  templateUrl: './costs.component.html',
  styleUrl: './costs.component.css'
})
export class CostsComponent implements OnInit {
  private _htmlInput = viewChild<UiInputComponent>("searchInput");
  public studentsComponent = viewChild(StudentsComponent);
  private _apiService = inject(ApiService);
  public states:string[] = [];
  public years = signal<{yearNumber:number}[]>([]);
  public COST_TABS:Tab[] = COST_TABS;
  public selectedTab = signal<string>('students');
  public userList = signal<(IUser & {disabled?:boolean})[]>([]);
  public selectedUser = signal<IUser | null>(null);
  public isEditing = signal<boolean>(false);
  public userInitials = computed(() =>{
    if(!this.selectedUser())
      return '';
    
    const name = this.selectedUser()!.Name1;
    const lastName = this.selectedUser()!.LastName1;
    
    return `${name.charAt(0)}${lastName.charAt(0)}`
  });
  private _searchSubject = new Subject<string>();
  public APP_NAME = environment.APP_NAME;
  
  ngOnInit(): void {
    this._searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((value) => value.length > 3),
      switchMap(value => this.findUsers(value))
    ).subscribe({
      next: (resp:IUser[]) => {
        this.userList.set(resp.map(e => ({...e, disabled:this.selectedUser()?.id! === e.id})));
      }, error: (err) => {
        console.log(err);
      }
    })

    this._apiService.get<{yearNumber:number}>({ path: "Years" }).subscribe({
      next:(resp)=>{
        this.years.set(resp);
      },error:(err)=>{
        console.log(err);
      }
    })
  }

  private findUsers(value: string): Observable<IUser[]> {
    const words = value.trim().split(/\s+/);
    const regex = words.join('.*');

    const params = {
      path: 'ViewUserInfos',
      filter: {
        where: {
          or: [
            { fullName: { regexp: `/${regex}/i` } },
            { CedocEmail: { regexp: `/${value}/i` } },
            { CedulaMil: { regexp: `/${value}/i` } },
            { Document: { regexp: `/${value}/i` } },
          ],
        },
        limit: 10,
      },
    };

    return this._apiService.get<IUser>(params)
  }

  public onSearchChange(event:Event){
    const target = event.target as HTMLInputElement;
    this._searchSubject.next(target.value);
  }

  public onSelectStudent(student:StudentItem){
    const input = this._htmlInput()?.ref.nativeElement.querySelector("input");
    input.value = student.label.replace(/ +(?= )/g,'');

    const currentUser = this.userList().find(e => e.id === Number(student.value));
    this.selectedUser.set(currentUser!);
    this.userList().forEach(e => {
      e.disabled = e.id === currentUser!.id;
    });
  }
}