import { Component, signal } from '@angular/core';
import { PARAMETER_TABS } from '@utils/parameter-tabs';
import { ForcesComponent } from './components/forces/forces.component';
import { ChargesComponent } from './components/charges/charges.component';
import { RangesComponent } from './components/ranges/ranges.component';
import { SalaryComponent } from "./components/salaray/salary.component";
import { ParentsComponent } from './components/parents/parents.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ServicesComponent } from "./components/services/services.component";
import { TabsComponent } from '@components/tabs/tabs.component';
import { Tab } from '@components/tabs/tab';


@Component({
  selector: 'app-parameters',
  standalone: true,
  imports: [
    ForcesComponent,
    ChargesComponent,
    RangesComponent,
    SalaryComponent,
    ParentsComponent,
    CategoriesComponent,
    TabsComponent,
    ServicesComponent
],
  templateUrl: './parameters.component.html',
  styleUrl: './parameters.component.css'
})
export class ParametersComponent {
  public PARAMETER_TABS:Tab[] = PARAMETER_TABS;
  public selectedTab = signal<string>('forces');

}
