<div class="payrolls">
  <div class="payrolls__header">
    <h1 class="payrolls__title">Pago de nómina</h1>
    <p class="payrolls__description">
      Genera recibos de pagos de nómina y consulta los existentes
    </p>

    <div class="payrolls__actions">
      <div class="payrolls__action-item">
        <input type="radio" name="payrolls" checked id="payrolls-current" hidden
        (change)="selectedTab.set('generate')">
        <label for="payrolls-current">Generar Pago de Nómina</label>
      </div>
      <div class="payrolls__action-item">
        <input type="radio" name="payrolls" id="payrolls-history" hidden
        (change)="selectedTab.set('history')">
        <label for="payrolls-history">Consultar Pago de Nómina</label>
      </div>
    </div>
  </div>

  <div class="payrolls__content">
    @switch (selectedTab()) {
      @case ('generate') {
        <app-generate />
      }
      @case ('history') {
        <app-history />
      }
      @default {
      }
    }
  </div>
</div>