import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
  input,
} from '@angular/core';
import { ICapabilityActions } from '../../models/platform-module';
import { ApiService } from '../../services/api-service.service';

@Directive({
  selector: '[hasCapability]',
  standalone: true,
})
export class HasCapabilityDirective implements OnInit {
  private _templateRef = inject(TemplateRef);
  private _viewContainerRef = inject(ViewContainerRef);
  private _apiService = inject(ApiService);
  public hasCapability = input<ICapabilityActions>();
  public elseContent = input<TemplateRef<any> | null>(null, {
    alias: 'hasCapabilityElse',
  });

  ngOnInit(): void {
    if (!this.hasCapability()) return;
 
    if (this._apiService.hasCapability(this.hasCapability()!)) {
      this._viewContainerRef.createEmbeddedView(this._templateRef);
      return;
    }
    
    this._viewContainerRef.clear();
    if (this.elseContent())
      this._viewContainerRef.createEmbeddedView(this.elseContent()!);
  }
}