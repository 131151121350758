import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { UiDateInputComponent } from '@components/ui/ui-date-input/ui-date-input.component';
import * as UiSelect from '@components/ui/ui-select';
import { GenerateComponent } from './generate/generate.component';
import { HistoryComponent } from './search/history.component';

@Component({
  selector: 'app-payrolls',
  standalone: true,
  imports: [
    GenerateComponent,
    HistoryComponent
  ],
  templateUrl: './payrolls.component.html',
  styleUrl: './payrolls.component.css'
})
export class PayrollsComponent implements OnInit{
  constructor(private _fb: FormBuilder) {}

  public selectedTab = signal<'generate' | 'history'>('generate');

  public payrollForm = this._fb.group({

  });

  ngOnInit(): void {
    
  }
}
