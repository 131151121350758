import { Component, inject, OnInit, signal, viewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { ApiService } from '@services/api-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-years',
  standalone: true,
  imports: [
    UiInputComponent,
    UiButtonComponent,
    FormsModule,
    UiButtonComponent,
    ReactiveFormsModule
  ],
  templateUrl: './years.component.html',
  styleUrl: './years.component.css'
})
export class YearsComponent implements OnInit {
  private _htmlInput = viewChild<UiInputComponent>("searchInput");

  private _apiService = inject(ApiService);
  public years = signal<{yearNumber:number}[]>([]);
  public yearNumber = signal<number | null>( null );


  ngOnInit(): void {
    this.initialData();
  }

  private initialData() {
    Swal.fire({
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    this._apiService.get<{yearNumber:number}>({path:"years"}).subscribe({
      next: (resp) => {
        this.years.set(resp.sort((a,b) => a.yearNumber - b.yearNumber));
        Swal.close();
      }, error: (err) => {
        console.log(err);
      }
    })
  }

  public createYear() {
    if(this.yearNumber()){
      Swal.fire({
        title: 'Guardando...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading()
      })

      this._apiService.post<{yearNumber:number}>({
        path: "years",
        data: {
          yearNumber: this.yearNumber()!,
        }
      }).subscribe({
        next: (year) => {
          const years = [...this.years(), year ];
          this.years.set(years.sort((a,b) => a.yearNumber - b.yearNumber));

          this.yearNumber.set(null);
          const input = this._htmlInput()?.ref.nativeElement.querySelector('input');
          input.value = '';
          Swal.close();
        }, 
        error: (err) => {
          Swal.fire({
            icon: "error",
            text: `Error al guardar el año: ${err.message || 'Internal server error'}`,
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          console.log(err);
        }
      })
    }
  }

  public deleteYear(yearNumber:number) {
    Swal.fire({
      title: 'Eliminando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.delete<{yearNumber:number}>({
      path: `years/${yearNumber}`,
    }).subscribe({
      next: (_) => {
        this.years.set(this.years().filter(e => e.yearNumber !== yearNumber));
        Swal.close();
      }, 
      error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al eliminar el año: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }
}