import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { IService } from '@models/service';
import { ITypeRequest } from '@models/type-request';
import { ApiService } from '@services/api-service.service';
import { concat, debounceTime, distinctUntilChanged, forkJoin, map, Observable, of, Subject, Subscription, switchMap, tap, toArray } from 'rxjs';
import * as UiSelect from '@components/ui/ui-select';
import Swal from 'sweetalert2';
import { ISchool } from '@models/school';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { UiInputComponent } from "../../../../shared/components/ui/ui-input/ui-input.component";
import { IUserCourse } from '@models/user-course';
import { CommonModule } from '@angular/common';

type UserCoursesWithPrice = IUserCourse & { UserPerService?: Record<string, any> }
interface ServiceResponse extends IService {
  userCoursesWithPrice?: UserCoursesWithPrice[];
}

@Component({
  selector: 'app-services-section',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
    FormsModule,
    UiInputComponent,
    CommonModule
],
  templateUrl: './services-section.component.html',
  styleUrl: './services-section.component.css'
})
export class ServicesSectionComponent implements OnInit {
  private _apiService = inject(ApiService);

  public schoolFilter = signal<number>(0);
  public yearFilter = signal<number>(2024);
  public typeFilter = signal<number | null>(null);

  public filteredServices= signal<ServiceResponse[]>([]);
  public serviceTypes= signal<ITypeRequest[]>([]);
  public years = signal<{yearNumber:number}[]>([]);
  public schools = signal<ISchool[]>(this._apiService.schools());
  private _fetchedAllData = signal<boolean>(true);
  private _subscription:Subscription = new Subscription();

  public appName = environment.APP_NAME;

  private _searchValue = signal<string>('');
  private _searchServiceSubject:Subject<string> = new Subject();

  ngOnInit(): void {
    this._searchServiceSubject
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap( value => {
        this._searchValue.set(value);
        if (value.length <= 3) {
          if (!this._fetchedAllData()) {
            this._fetchedAllData.set(true);
            return this.applyFilters()
          }
          return of(null);
        }

        this._fetchedAllData.set(false);
        return this.applyFilters();
      })
    )
    .subscribe({
      next:(resp)=>{
        if (resp)
          this.filteredServices.set(resp);
      },error:(err)=>{
        console.log(err);
      }
    })

    this.getMainData();
  }

  public onSearchChange(event:Event){
    const value = (event.target as HTMLInputElement).value;
    this._searchServiceSubject.next(value);
  }

  /**
   * Fetch services.
   */
  private getMainData() {
    Swal.fire({
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    forkJoin({
      years: this._apiService.get<{yearNumber:number}>({ path: "Years" }),
      serviceType: this._apiService.get<ITypeRequest>({ path: "TypesRequest" }),
    }).subscribe({
      next: ({ serviceType, years }) => {
        this.serviceTypes.set(serviceType);
        this.years.set(years);
        Swal.close();
      }, error: (err) => {
        console.log(err);
      }
    })
  }

  public getServicesOnChange(){
    Swal.fire({
      title:"Cargando...",
      allowEscapeKey:false,
      allowOutsideClick:false,
      didOpen: () => Swal.showLoading()
    })

    this._subscription.unsubscribe();
    this._subscription = this.applyFilters().subscribe({
      next: (resp) => {
        this.filteredServices.set(resp);
        Swal.close();
      }, error: (err) => {
        console.log(err);
        Swal.close();
      }
    })
  }

  public removeUserPerService(service:IService, userId:number){
    const userPerServiceId = service.ServicePerUsers.find(e => e.userID === userId);
    if(!userPerServiceId)
      return
    
    Swal.fire({
      icon:"warning",
      text: "¿Estás seguro de que deseas eliminar a este usuario del servicio?",
      confirmButtonText: 'Eliminar'
    }).then(result => {
      if(result.isConfirmed){
        Swal.fire({
          title:"Eliminando...",
          allowEscapeKey:false,
          allowOutsideClick:false,
          didOpen: () => Swal.showLoading()
        })

        this._apiService.delete({path: `ServicePerUsers/${userPerServiceId.id}`})
        .pipe(
          switchMap(_ => this.applyFilters())
        ).subscribe({
          next:(resp)=>{
            Swal.fire({
              icon:"success",
              text:"Usuario eliminado con éxito.",
              allowEscapeKey:false,
              allowOutsideClick:false,
            })
            this.filteredServices.set(resp);
          },error:(err)=>{
            Swal.fire({
              icon:"error",
              text:`Error al eliminar el usuario: ${err.message || "Internal server error"}`,
              allowEscapeKey:false,
              allowOutsideClick:false,
            })
            console.log(err);
          }
        })
      }
    })
  }

  private applyFilters(): Observable<ServiceResponse[]> {
    const school = Number(this.schoolFilter());
    const type = Number(this.typeFilter());
    const year = Number(this.yearFilter());

    const filters: Record<string, any> = { and: [{ year }] };
    if(this._searchValue().length > 3)
      filters['and'] = [...filters['and'], { serviceName: { regexp: `/${this._searchValue()}/i` } }]

    if (school !== 0)
      filters['and'] = [...filters['and'], { schoolID: school }]
    else
      filters['and'] = [...filters['and'], { or: [{ inq: this.schools().map(e => e.id) }, { choolID: null }] }]

    if (type !== null && type !== 0)
      filters['and'] = [...filters['and'], { typeRequestID: type }]

    return this.serviceQuery(filters);
  }

  private serviceQuery(filters: Record<string, any>): Observable<ServiceResponse[]> {
    const newFilters: Record<string, any> = {
      include: ['School', 'TypeRequest', 'ServicePerUsers'],
      where: { ...filters }
    };

    return this._apiService.get<IService>({
      path: "Services",
      filter: { ...newFilters }
    }).pipe(
      switchMap(services => {
        const serviceRequests = services.map(service => {
          const userIds = service.ServicePerUsers.map(e => e.userID);
          const offerIds = service.ServicePerUsers.map(e => e.courseOfferID);

          return this._apiService.get<IUserCourse>({
            path: 'UserCourses',
            filter: {
              include: ['courseGroup', 'coursesOfer', { userapp: { parentsToStudents: 'parentUser' } }],
              where: { and: [{ UserID: { inq: userIds } }, { CourseOferID: { inq: offerIds } }] }
            }
          }).pipe(
            switchMap( (userCourses:UserCoursesWithPrice[]) => {
              const userIds = userCourses.map(e => e.UserID);
              
              const pricesObs = userIds.map(user => this._apiService.post({
                path: "Services/getPrice",
                data: { userID: user, year: this.yearFilter(), serviceID: service.id }
              }).pipe(
                map(price => {
                  const userCourse = userCourses.find(e => e.UserID === user);
                  if (userCourse)
                    userCourse.UserPerService = price;
                  return userCourse as UserCoursesWithPrice;
                })
              ))
              return concat(...pricesObs).pipe( 
                toArray(),
                map(userCoursesWithPrice  => ({...service, userCoursesWithPrice}))
             )
            }),
          ) 
        });
        return concat(...serviceRequests).pipe(toArray());
      }),
      tap(result => console.log(result))
    )
  }
}