<div class="parameters">
  <div class="parameters__tabs">
    <app-tabs [tabs]="PARAMETER_TABS" 
    (onValueChange)="selectedTab.set($event)" 
    defaultTab="forces" />
  </div>

  <div class="parameters__content">
    @switch (selectedTab()) {
      @case ("forces") {
        <app-forces />
      }
      @case ("charges") {
        <app-charges />
      }
      @case ("ranges") {
        <app-ranges />
      }
      @case ("salary") {
        <app-salary />
      }
      @case ("relationship") {
        <app-parents />
      }
      @case ("categories") {
        <app-categories />
      }
      @case ("services") {
        <app-services />
      }
      @default {}
    }
  </div>
</div>