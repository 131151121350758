<div class="students">
  <div class="students__filter">
    <ui-select label="Año" defaultValue="2024" (onValueChange)="onSelectYear($event.value)">
      <ui-select-content>
        @for (item of years(); track $index) {
          <ui-select-item [value]="item.yearNumber">{{item.yearNumber}}</ui-select-item>
        }@empty {
          <span>Sin Resultados</span>
        }
      </ui-select-content>
    </ui-select>

    <ui-select label="Seleccionar {{appName()}}" [(ngModel)]="schoolFilter" 
      (onValueChange)="onSelectSchool($event.value)"> 
      <ui-select-content>
        @for (item of schools(); track $index) {
          <ui-select-item [value]="item.id!">{{item.NameTSchool}}</ui-select-item>
        }@empty {
          <span>Sin Resultados</span>
        }
      </ui-select-content>
    </ui-select>

    <ui-select label="Seleccionar programa" (onValueChange)="onSelectCourse($event.value)" 
      [disabled]="schoolFilter() == 0">
      <ui-select-content>
        @for (item of courses(); track $index) {
          <ui-select-item [value]="item.id!">{{item.NameCourse}}</ui-select-item>
        }@empty {
          <span>Sin Resultados</span>
        }
      </ui-select-content>
    </ui-select>

    <ui-select label="Seleccionar oferta" [disabled]="courseFilter() == 0" 
      (onValueChange)="onSelectOffer($event.value)">
      <ui-select-content>
        @for (item of offers(); track $index) {
          <ui-select-item [value]="item.id">{{item.NameCourseOfer}}</ui-select-item>
        }@empty {
          <span>Sin Resultados</span>
        }
      </ui-select-content>
    </ui-select>

    <ui-select label="Estado" [disabled]="offerFilter() == 0" 
      (onValueChange)="onSelectRealStatus($event.value)">
      <ui-select-content>
        <ui-select-item [value]="0">Todos</ui-select-item>
        @for (item of realStatusCourse(); track $index) {
          <ui-select-item [value]="item.id">{{item.nameRealStatus}}</ui-select-item>
        }@empty {
          <span>Sin Resultados</span>
        }
      </ui-select-content>
    </ui-select>
    
    <ui-input label="Buscar estudiante" [disabled]="offerFilter() == 0" 
      [(ngModel)]="subjectHtmlInput" (keyup)="onSearchChange($event)" />
  </div>

  <div class="students__content">
    @if(filteredUsers().length === 0){
      <div class="empty-list">Sin Resultados</div>
    }@else {
      <table class="students__table">
        <thead>
          <tr>
            <th class="students__table-th" [ngStyle]="{'width': '40px'}">
              <label class="custom-checkbox">
                <input type="checkbox" hidden id="checkbox" (change)="onSelectAll($event)">
                <span class="checkmark"></span>
              </label>
            </th>
            <th class="students__table-th">NOMBRE DEL ESTUDIANTE</th>
            <th class="students__table-th">{{appName() | uppercase}}</th>
            <th class="students__table-th">PROGRAMA</th>
            <th class="students__table-th">OFERTA</th>
            <th class="students__table-th">AÑO</th>
          </tr>
        </thead>
        <tbody>
          @for (item of filteredUsers(); track $index) {
            <tr [ngClass]="{'students__table-checked': item.selected}">
              <td class="students__table-td">
                <label class="custom-checkbox">
                  <input type="checkbox" hidden id="checkbox" [checked]="item.selected" (change)="onSlectUser(item)">
                  <span class="checkmark"></span>
                </label>
              </td>
              <td class="students__table-td">{{item.userapp?.Name1}} {{item.userapp?.Name2}} {{item.userapp?.LastName1}} {{item.userapp?.LastName2}}</td>
              <td class="students__table-td">{{item.coursesOfer?.school?.NameTSchool}}</td>
              <td class="students__table-td">{{item.coursesOfer?.course?.NameCourse}}</td>
              <td class="students__table-td">{{item.coursesOfer?.NameCourseOfer}}</td>
              <td class="students__table-td">{{item.coursesOfer?.year}}</td>
            </tr>
          }
        </tbody>
      </table>
    }

    @if(usersToAdd().length > 0){
      <div class="students__assign">
        <p class="assign__counter">{{usersToAdd().length}} estudiantes seleccionados</p>
        <button class="button button--solid" (click)="showDialog.set(true)">Asignar Servicios</button>
      </div>
    }
   
    @if(showDialog()){
      <div class="dialog__container">
        <div class="dialog__content">
          <div class="dialog__header">
            <h2 class="dialog__header-title">Asignar Servicio</h2>
            <em class="icon-i-close dialog__header-close" (click)="closeModal()"></em>
          </div>
    
          <div class="dialog__body">
            <div>
              <ui-select label="Año" [(ngModel)]="selectedYear">
                <ui-select-content>
                  @for (item of years(); track $index) {
                    <ui-select-item [value]="item.yearNumber">{{item.yearNumber}}</ui-select-item>
                  }@empty {
                    <span>Sin Resultados</span>
                  }
                </ui-select-content>
              </ui-select>
            </div>
  
            <div>
              <ui-dropdown [closeOnSelect]="false" (onValueChange)="onSelecteService($event)">
                <ui-dropdown-trigger>
                  <ui-input label="Seleccionar Servicio" [(ngModel)]="serviceHtmlInput" (keyup)="onSerachService($event)" />
                </ui-dropdown-trigger>
                <ui-dropdown-content>
                  @for (item of filteredModalServices(); track $index) {
                    <ui-dropdown-item [value]="item.id!" [disabled]="item.selected">{{item.serviceName}}</ui-dropdown-item>
                  }@empty {
                    <span>Sin Resultados</span>
                  }
                </ui-dropdown-content>
              </ui-dropdown>

              <div class="students-badges">
                @for (badge of servicesToAdd(); track $index) {
                  <div class="students-badges__item">
                    <em class="icon-i-close students-badges__close" (click)="onRemoveService(badge.id!)"></em> {{badge.serviceName}}
                  </div>
                }
              </div>
            </div>
          </div>
    
          <ui-button color="primary" (onClick)="onSave()" [disabled]="servicesToAdd().length === 0">Guardar</ui-button>
        </div>
      </div>
    }
  </div>