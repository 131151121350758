import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class XlsxService {

  constructor(@Inject(DOCUMENT) private _document: Document) { }

  private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  public exportToXlsx<T>(data: T[], fileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },  // 'Datos' será el nombre de la hoja
      SheetNames: ['data']
    };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
    const url: string = window.URL.createObjectURL(data);
    const a = this._document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    this._document.body.appendChild(a);
    a.click();
    this._document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}
