import { Tab } from "@components/tabs/tab";

export const COST_TABS:Tab[] = [
  {
    id:'students',
    label:"Usuario"
  },
  {
    id:'attendant',
    label:"Acudientes/Responsables"
  },
  {
    id:'enrollment',
    label:"Matrículas"
  },
  {
    id:'role',
    label:"Roles"
  },
  {
    id:'services',
    label:"Servicios"
  },
]