<div class="forces">
  <div class="charges__actions">
    <ui-select label="Tipo de usuario" (onValueChange)="onUserTypeChange($event.value)" defaultValue="0">
      <ui-select-content>
        <ui-select-item value="0">Todos</ui-select-item>
        @for (item of typeUsers(); track $index) {
          <ui-select-item [value]="item.id">{{item.nameTypeUser}}</ui-select-item>
        }
      </ui-select-content>
    </ui-select>
  </div>

  <div class="forces__header">
    <h2 class="header__title">Fuerzas</h2>
    <button class="button button--solid"  type="button" (click)="showDialog.set(true)">Agregar Fuerza</button>
  </div>

  <div class="forces__content">
    <div class="forces__list">
      @for (item of forces(); track $index) {
        <div class="forces__item">
          <div class="item__content">
            <div class="item__info">
              <div class="forces__name">{{item.NameForce}}</div>
              <span class="forces__acronym">{{item.Sigla}}</span>
            </div>

            <span>Tipo de usuario: <strong>{{item.TypeUser?.nameTypeUser}}</strong></span>
            <span>Público: <strong>{{item.isPublic ? 'Si' : 'No'}}</strong></span>
          </div>
  
          <button type="button" class="button button--edit-force">
            <em class="icon-i-pen" (click)="onEditForce(item)"></em>
          </button>
        </div>
      }@empty {
        <span class="empty-list">Sin resultados.</span>
      }
    </div>
  </div>


  @if (showDialog()) {
    <div class="dialog__container">
      <form class="dialog__content" [formGroup]="forcesForm" (ngSubmit)="onSave()">
        <div class="dialog__header">
          <h2 class="dialog__header-title">{{foceToEdit() ? 'Editar' : 'Agregar'}} Fuerza</h2>
          <em class="icon-i-close dialog__header-close" (click)="resetForm()"></em>
        </div>
  
        <div class="dialog__body">
          <div>
            <ui-input variant="bordered" label="Nombre de la fuerza*" formControlName="NameForce"
              [invalid]="forcesForm.get('NameForce')?.invalid && forcesForm.get('NameForce')?.touched"/>
            @if(forcesForm.get('NameForce')?.invalid && forcesForm.get('NameForce')?.touched){
              <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
            }
          </div>

          <div>
            <ui-input variant="bordered" label="Sigla*" formControlName="Sigla"
              [invalid]="forcesForm.get('Sigla')?.hasError('required') && forcesForm.get('Sigla')?.touched"/>
            @if(forcesForm.get('Sigla')?.hasError('required') && forcesForm.get('Sigla')?.touched){
              <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
            }
          </div>

          <div>
            <ui-select label="Tupo de usuario*" formControlName="typeUserID"
              [invalid]="forcesForm.get('typeUserID')?.invalid && forcesForm.get('typeUserID')?.touched">
              <ui-select-content>
                @for (item of typeUsers(); track $index) {
                  <ui-select-item [value]="item.id">{{item.nameTypeUser}}</ui-select-item>
                }
              </ui-select-content>
            </ui-select>
            @if (forcesForm.get('typeUserID')?.invalid && forcesForm.get('typeUserID')?.touched) {
              <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
            }
          </div>

          <div>
            <div class="toggle__input">
              Es público
              <div class="toggle-container">
                <span class="toggle-label">NO</span>
                <label class="switch">
                  <input type="checkbox" formControlName="isPublic">
                  <span class="slider"></span>
                </label>
                <span class="toggle-label">SÍ</span>
              </div>
            </div>
          </div>
        </div>
  
        <ui-button color="primary" [disabled]="forcesForm.invalid">Guardar</ui-button>
      </form>
    </div>
  }
</div>