import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiDateInputComponent } from '@components/ui/ui-date-input/ui-date-input.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import * as UiSelect from '@components/ui/ui-select';
import { IParentToStudents } from '@models/parent-to-students';
import { ApiService } from '@services/api-service.service';
import Swal from 'sweetalert2';
import { AssignRelationshipComponent } from './assign-relationship/assign-relationship.component';
import { ParentsService } from '../parents.service';

@Component({
  selector: 'app-children-data',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiInputComponent,
    UiDateInputComponent,
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
    AssignRelationshipComponent
  ],
  templateUrl: './children-data.component.html',
  styleUrl: './children-data.component.css'
})
export class ChildrenDataComponent {
  private _apiService: ApiService = inject(ApiService);
  private _parentsService: ParentsService = inject(ParentsService);

  public showDialog = signal<boolean>(false);
  public relationship = signal<'responsible' | 'guardian'>('responsible');

  public childrenData = computed(() => this._parentsService.childrenData())
  public noResponsibles = computed(() => this.checkEmptyOrNullField(this.childrenData().responsible, 'ParentUserId'));
  public noGuardians = computed(() => this.checkEmptyOrNullField(this.childrenData().guardian, 'attendedBy'));

  public checkEmptyOrNullField = (fieldArray: any[], fieldToCheck: string) => {
    return fieldArray.every(e => e[fieldToCheck] === null) || fieldArray.length === 0;
  }

  public deleteResponsible(item: IParentToStudents) {
    Swal.fire({
      icon: 'warning',
      text: "¿Estás seguro de que deseas eliminar a este estudiante?",
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: true,
      showCancelButton: true
    }).then(response => {
      if (response.isConfirmed) {
        this.showLoading();

        this._apiService.patch<Partial<IParentToStudents>>({
          path: `ParentsToStudents/${item.id}`,
          data: { ParentUserId: null }
        }).subscribe({
          next: () => {
            const updatedResponsibles = this.childrenData().responsible.map(responsible =>
              responsible.id === item.id
                ? { ...responsible, ParentUserId: null }
                : responsible
            );

            this._parentsService.childrenData.set({
              responsible: updatedResponsibles,
              guardian: this.childrenData().guardian
            });
            this.showSuccessAlert("Estudiante eliminado correctamente.");
          }, error: (err) => {
            console.log(err);
            this.showErrorAlert(err);
          }
        })
      }
    });
  }

  public deleteGuardian(item: IParentToStudents) {
    Swal.fire({
      icon: 'warning',
      text: "¿Estás seguro de que deseas eliminar a este estudiante?",
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: true,
      showCancelButton: true
    }).then(response => {
      if (response.isConfirmed) {
        this.showLoading();
        this._apiService.patch<Partial<IParentToStudents>>({
          path: `ParentsToStudents/${item.id}`,
          data: { attendedBy: null }
        }).subscribe({
          next: () => {
            const updatedGuardians = this.childrenData().guardian.map(guardian =>
              guardian.id === item.id
                ? { ...guardian, attendedBy: null }
                : guardian
            );

            this._parentsService.childrenData.set({
              responsible: this.childrenData().responsible,
              guardian: updatedGuardians
            });
            this.showSuccessAlert("Estudiante eliminado correctamente.");
          }, error: (err) => {
            console.log(err);
            this.showErrorAlert(err);
          }
        })
      }
    });
  }

  private showLoading(): void {
    Swal.fire({
      text: "Guardando...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading()
    });
  }

  public onAddStudentClick(relationship: 'responsible' | 'guardian') {
    this.relationship.set(relationship);
    this.showDialog.set(true);
  }

  private showSuccessAlert(message: string): void {
    Swal.fire({
      icon: 'success',
      text: message,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: true
    });
  }

  private showErrorAlert(err: any): void {
    Swal.fire({
      icon: 'error',
      title: "Error",
      text: `${err.message || 'Error interno del servidor.'}`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: true
    });
  }
}
