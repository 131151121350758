import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, input, OnChanges, OnDestroy, output, signal, SimpleChanges } from '@angular/core';
import * as UiSelect from '@components/ui/ui-select';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IUserUserApp } from '@models/users';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { ApiService } from '@services/api-service.service';
import { ITypeDocument } from '@models/type-document';
import { catchError, concat, forkJoin, map, of, Subject, switchMap, takeUntil, toArray } from 'rxjs';
import { UiDateInputComponent } from '@components/ui/ui-date-input/ui-date-input.component';
import { IBiologicalSex, ITypeRH } from '@models/classifcation-user';
import { ICountry } from '@models/country';
import { IEndpoint } from '@models/endpoint';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-students',
  standalone: true,
  imports: [
    CommonModule, 
    NgTemplateOutlet, 
    ReactiveFormsModule,

    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,

    UiInputComponent,
    UiDateInputComponent
  ],
  templateUrl: './students.component.html',
  styleUrl: './students.component.css'
})
export class StudentsComponent implements OnChanges, OnDestroy {
  constructor(private _fb: FormBuilder) { }

  private _apiService:ApiService = inject(ApiService);
  
  isEditing = input.required<boolean>();
  userId = input.required<number>();
  onSaveData = output<void>();

  public studentForm = signal<FormGroup>(this._fb.group({
    basicInfo: this._fb.group({
      names: [''],
      lastNames: [''],
      TypeDocumentID: [''],
      Document: [''],
      Email: ['', [Validators.email, Validators.required]],
      CedocEmail: ['', [Validators.email, Validators.required]],
    }),
    contactInfo: this._fb.group({
      birthDate: [''],
      Nacionality: [''],
      Address: [''],
      CellPhone: [''],
      Phone: [''],
    }),
    classificationUser: this._fb.group({
      biologicalSexID: [''],
      eps: [''],
      rhID: [''],
      ensuranceCompany: [''],
    })
  }));
  public loadingData = signal<boolean>(true);
  public typeDocuments = signal<ITypeDocument[]>([]);
  public biologicalSex = signal<IBiologicalSex[]>([]);
  public bloodType = signal<ITypeRH[]>([]);
  public nationality = signal<ICountry[]>([]);
  public appName:string = environment.APP_NAME;
  public studentData = signal<IUserUserApp | null>(null);

  public country = signal<ICountry | null>(null) ;

  private _destroy$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['userId'] && changes['userId'].currentValue)
      this.getInitialData();
  }

  private getInitialData(){
    Swal.fire({
      text: "Cargando vista...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    this.studentForm().reset();
    return forkJoin({
      typeDocuments: this._apiService.get<ITypeDocument>({ path: 'TypeDocuments' }),
      biologicalSex: this._apiService.get<IBiologicalSex>({ path: 'TypeBiologicalSexes' }),
      bloodType: this._apiService.get<ITypeRH>({ path: 'TypeRHs' }),
      countries: this._apiService.get<ICountry>({ path: 'Countries' })
    }).pipe(
      takeUntil(this._destroy$),
      switchMap(({ typeDocuments, biologicalSex, bloodType, countries }) =>{
        this.typeDocuments.set(typeDocuments);
        this.biologicalSex.set(biologicalSex);
        this.bloodType.set(bloodType);
        this.nationality.set(countries);
        return this.getUserData()
      })
    ).subscribe({
        next: () => {},
        error: (err) => {
          console.log(err);
        }, complete: () => {
          this.loadingData.set(false);
        }
      })
  }
  
  private patchForm(){
    const data = this.studentData()!;

    const name1 = data.Name1;
    const name2 = data.Name2;
    const lastName1 = data.LastName1;
    const lastName2 = data.LastName2;

    this.studentForm().patchValue({
      basicInfo: {
        ...data.UserDocuments[0],
        names: `${name1} ${name2 || ''}`.trim(),
        lastNames: `${lastName1} ${lastName2 || ''}`.trim(),
        Email:
          this.appName === 'cedoc' ? data.ContactInfos[0].Email : data.email,
        CedocEmail: data.CedocEmail,
      },
      contactInfo: {
        ...data.ContactInfos[0],
        ...(data.ContactInfos[0]?.birthDate
          ? { birthDate: new Date(data.ContactInfos[0].birthDate).toISOString().split('T')[0] }
          : {}),
      },
      classificationUser: {
        ...data.ClassificationUser[0],
      },
    });

    const nacionality = this.studentForm().get('contactInfo.Nacionality')?.value;
    this.country.set(this.nationality().find(e => e.id == nacionality) || null);
  }

  public saveData(){
    if(!this.studentForm().valid)
      return;

    Swal.fire({
      text:"Guardando...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading()
    });

    concat(
      this.updateBasicData(),
      this.updateUserappEmail(),
      this.updateContactInfo(),
      this.updateClassificationInfo()
    ).pipe( 
      takeUntil(this._destroy$),
      toArray(),
      switchMap(() => this.getUserData())
    )
    .subscribe({
      next: _ =>{
        Swal.fire({
          icon: 'success',
          text: "Información almacenada con éxito.",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: true
        });
        this.onSaveData.emit();
      },error:(err)=>{
        Swal.fire({
          icon: 'error',
          title: "Error",
          text: `${err?.error?.details?.message || err.message || 'Error interno del servidor.'}`,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: true
        });
        console.log(err);
      }
    });
  }

  private updateBasicData(){
    const studentData = this.studentData()!;

    const { TypeDocumentID, Document, CedocEmail, ...newUserData } = this.studentForm().get("basicInfo")?.value;
    const names = newUserData.names.split(' ');
    const lastNames = newUserData.lastNames.split(' ');

    const userParams:IEndpoint = {
      path: `Userapps/${studentData.id}`,
      data: {
        Name1: names[0],
        Name2: names[1] || null,
        LastName1: lastNames[0],
        LastName2: lastNames[1] || null,
        CedocEmail
      }
    }

    return this._apiService.patch(userParams).pipe(
      switchMap(_ => {
        const documentParams:IEndpoint = {
          path: `UserDocuments/${studentData.UserDocuments[0].id}`,
          data: {
            Document,
            TypeDocumentID
          }
        }
        return this._apiService.patch(documentParams);
      })
    )
  }

  private updateContactInfo(){
    const studentData = this.studentData()!;

    const contactParams:IEndpoint = {
      path: `ContactInfos/${studentData.ContactInfos[0].id}`,
      data: this.studentForm().get("contactInfo")?.value
    }

    return this._apiService.patch(contactParams); 
  }

  private updateClassificationInfo(){
    const studentData = this.studentData()!;

    const classificationParams:IEndpoint = {
      path: "ClassificationUsers",
      data: {
        id: studentData.ClassificationUser.length > 0 ? studentData.ClassificationUser[0].id : null,
        UserID: studentData.id!,
        ...this.studentForm().get("classificationUser")?.value
      }
    }

    return this._apiService.patch(classificationParams); 
  }

  updateUserappEmail() {
    const studentData = this.studentData()!;

    return this._apiService.post({
      path: `/Userapps/${studentData.id}/updateUserappEmail`,
      data:{ email: this.studentForm().get("basicInfo.Email")?.value }
    }).pipe(
      catchError(err => {
        console.error('Error al actualizar userapp email:', err);
        return of(null);
      })
    );
  }

  private getUserData(){
    const finalUser = {
      path: 'userapps',
      filter: {
        include: ["ContactInfos", "TypeCondition", 'TypeUser', { MilitarInfo: ['RealRange', 'Force'] }, 'WorkInfos', { ClassificationUser: ["typeBiologicalSex", "typeRH", "typeCivilStatus"] }, { UserDocuments: "TypeDocuments" }],
        where: { id: this.userId() },
      }
    }

    return this._apiService.get<IUserUserApp>(finalUser).pipe(
      switchMap((userData) => {
        this.studentData.set(userData[0]);
        this.patchForm();
        Swal.close();
        return of(userData)
      })
    )
  }


  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}