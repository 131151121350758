@if (!loadingData()) {
  <div class="students">
    <ng-container *ngIf="isEditing(); else noEditable">
      <form class="students__form" [formGroup]="userForm()">
        <div class="students__section">
          <h2 class="section__title">Información personal</h2>

          <div class="section__content" formGroupName="basicInfo">
            <div class="students__input" >
              <ui-input variant="bordered" label="Nombres" formControlName="names"/>
            </div>
    
            <div class="students__input">
              <ui-input variant="bordered" label="Apellidos" formControlName="lastNames"/>
            </div>

            <div class="students__input">
              <ui-select variant="bordered" label="Tipo de usuario" formControlName="typeUserID" (onValueChange)="onChangeTypeUser()">
                <ui-select-content>
                  <ui-select-item [value]="item.id" *ngFor="let item of basicData.typeUser">
                    {{item.nameTypeUser}}
                  </ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>

            <div class="students__input">
              <ui-select variant="bordered" label="Tipo Documento" formControlName="TypeDocumentID">
                <ui-select-content>
                  <ui-select-item [value]="item.id" *ngFor="let item of basicData.typeDocuments">
                    {{item.NameTypeDocument}}
                  </ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>
    
            <div class="students__input">
              <ui-input variant="bordered" label="No. Documento" formControlName="Document"/>
            </div>

            <div class="students__input">
              <ui-input variant="bordered" label="Correo institucional" formControlName="CedocEmail"/>
              <small class="validation-feedback--text" *ngIf="userForm().get('basicInfo.CedocEmail')?.hasError('email')">
                Correo no válido
              </small>
            </div>
    
            <div class="students__input">
              <ui-input variant="bordered" label="Correo personal" formControlName="Email"/>
              <small class="validation-feedback--text" *ngIf="userForm().get('basicInfo.Email')?.hasError('email')">
                Correo no válido
              </small>
            </div>
          </div>
        </div>

        <div class="students__section">
          <h2 class="section__title">Información de contacto</h2>

          <div class="section__content" formGroupName="contactInfo">
            <div class="students__input">
              <ui-date-input variant="bordered" label="Fecha de nacimiento" formControlName="birthDate"/>
            </div>

            <div class="students__input">
              <ui-input variant="bordered" label="Teléfono Celular" formControlName="CellPhone"/>
            </div>
    
            <div class="students__input">
              <ui-input variant="bordered" label="Teléfono Casa" formControlName="Phone"/>
            </div>
    
            <div class="students__input">
              <ui-input variant="bordered" label="Dirección de domicilio" formControlName="Address"/>
            </div>
          </div>
        </div>

        <div class="students__section">
          <h2 class="section__title">Datos de clasificación</h2>

          <div class="section__content" formGroupName="classificationUser">
            <div class="students__input">
              <ui-select variant="bordered" label="Sexo biológico" formControlName="biologicalSexID">
                <ui-select-content>
                  <ui-select-item [value]="item.id" *ngFor="let item of basicData.biologicalSex">
                    {{item.nameBiologicalSex}}
                  </ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>

            <div class="students__input">
              <ui-select variant="bordered" label="Estado civil" formControlName="civilStatusID">
                <ui-select-content>
                  <ui-select-item [value]="item.id" *ngFor="let item of basicData.civilStatuses">
                    {{item.nameCivilStatus}}
                  </ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>
            
            <div class="students__input">
              <ui-input variant="bordered" label="Profesión" formControlName="currentOccupation"/>
            </div>
          </div>
        </div>

        <div class="students__section">
          <h2 class="section__title">Información laboral</h2>

          <div class="section__content" formGroupName="workInfo">
            <div class="students__input">
              <ui-input variant="bordered" label="Empresa donde trabaja" formControlName="Company"/>
            </div>
            
            <div class="students__input">
              <ui-input variant="bordered" label="Cargo" formControlName="Position"/>
            </div>
    
            <div class="students__input">
              <ui-input variant="bordered" label="Email trabajo" formControlName="workEmail"/>
            </div>
    
            <div class="students__input">
              <ui-input variant="bordered" label="Teléfono trabajo" formControlName="Phone"/>
            </div>
    
            <div class="students__input">
              <ui-input variant="bordered" label="Dirección trabajo" formControlName="Address"/>
            </div>

            <div class="students__input">
              <ui-input variant="bordered" label="Unidad" formControlName="Unit"/>
            </div>
          </div>
        </div>

        <div class="students__section">
          <h2 class="section__title">Condición del usuario</h2>

          <div class="section__content">
            <div class="students__input" formGroupName="basicInfo">
              <ui-select variant="bordered" label="Condición" formControlName="typeConditionID">
                <ui-select-content>
                  <ui-select-item [value]="item.id" *ngFor="let item of basicData.typeConditions">
                    {{item.nameCondition}}
                  </ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>

            <ng-container formGroupName="militaryInfo">
              <div class="students__input">
                <ui-select variant="bordered" label="Fuerza" formControlName="ForceID"
                  (onValueChange)="onChangeForce()">
                  <ui-select-content>
                    @for (item of forces(); track $index) {
                      <ui-select-item [value]="item.id!">{{item.NameForce}}</ui-select-item>
                    }@empty {
                      <span>No hay fuerzas disponibles</span>
                    }
                  </ui-select-content>
                </ui-select>
              </div>
      
              <div class="students__input">
                <ui-select variant="bordered" label="Rango" formControlName="realRangeID">
                  <ui-select-content>
                    @for (item of ranges(); track $index) {
                      <ui-select-item [value]="item.id!">
                        {{item.NameRange}}
                      </ui-select-item>
                    }@empty {
                      <span>No hay rangos disponibles</span>
                    }
                  </ui-select-content>
                </ui-select>
              </div>
      
              @if(isMilitar()){
                <div class="students__input">
                  <ui-input variant="bordered" label="Documento militar" formControlName="CedulaMil"/>
                </div>
              }
            </ng-container>
          </div>
        </div>

        <div class="students__section">
          <h2 class="section__title">Información familiar</h2>

          <div class="section__content" formGroupName="parentToStudents">
            <div class="students__input">
              <ui-select variant="bordered" label="Parentesco" formControlName="relationshipId">
                <ui-select-content>
                  <ui-select-item [value]="item.id!" *ngFor="let item of basicData.typeRelationship">
                    {{item.nameRelationship}}
                  </ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
}@else {
  <div class="loader__container">
    <div class="loader"></div>
  </div>
}

<ng-template #noEditable>

  <div class="students__section">
    <h2 class="section__title">Información personal</h2>
    <div class="section__content">
      <div class="students__field">
        <div class="field__label">Nombres</div>
        <div class="field__data">{{userForm().get('basicInfo.names')?.value}}</div>
      </div>
    
      <div class="students__field">
        <div class="field__label">Apellidos</div>
        <div class="field__data">{{userForm().get('basicInfo.lastNames')?.value}}</div>
      </div>

      <div class="students__field">
        <div class="field__label">Tipo de usuario</div>
        <div class="field__data">{{userData()?.TypeUser?.nameTypeUser || '-'}}</div>
      </div>

      <div class="students__field">
        <div class="field__label">Tipo de documento</div>
        <div class="field__data">{{userData()?.UserDocuments?.[0]?.TypeDocuments?.NameTypeDocument || '-'}}</div>
      </div>
    
      <div class="students__field">
        <div class="field__label">No. Documento</div>
        <div class="field__data">{{userData()?.UserDocuments?.[0]?.Document || '-'}}</div>
      </div>

      <div class="students__field">
        <div class="field__label">Correo institucional</div>
        <div class="field__data">{{userData()?.CedocEmail}}</div>
      </div>
    
      <div class="students__field">
        <div class="field__label">Correo personal</div>
        <div class="field__data">{{userData()?.email}}</div>
      </div>
    </div>
  </div>
  
  <div class="students__section">
    <h2 class="section__title">Información de contacto</h2>

    <div class="section__content">
      <div class="students__field">
        <div class="field__label">Fecha de nacimiento</div>
        @if(userData()?.ContactInfos?.[0]?.birthDate){
          <div class="field__data">{{userData()?.ContactInfos?.[0]?.birthDate | date}}</div>
        }@else {
          <div class="field__data">-</div>
        }
      </div>
    
      <div class="students__field">
        <div class="field__label">Teléfono Celular</div>
        <div class="field__data">{{userData()?.ContactInfos?.[0]?.CellPhone || '-'}}</div>
      </div>
    
      <div class="students__field">
        <div class="field__label">Teléfono Casa</div>
        <div class="field__data">{{userData()?.ContactInfos?.[0]?.Phone || '-'}}</div>
      </div>
    
      <div class="students__field">
        <div class="field__label">Dirección de domicilio</div>
        <div class="field__data">{{userData()?.ContactInfos?.[0]?.Address || '-'}}</div>
      </div>
    </div>
  </div>

  <div class="students__section">
    <h2 class="section__title">Datos de clasificación</h2>
    <div class="section__content">
      <div class="students__field">
        <div class="field__label">Sexo biológico</div>
        <div class="field__data">{{userData()?.ClassificationUser?.[0]?.typeBiologicalSex?.nameBiologicalSex || '-' }}</div>
      </div>

      <div class="students__field">
        <div class="field__label">Estado civil</div>
        <div class="field__data">{{userData()?.ClassificationUser?.[0]?.typeCivilStatus?.nameCivilStatus || '-' }}</div>
      </div>

      <div class="students__field">
        <div class="field__label">Profesión</div>
        <div class="field__data">{{userData()?.ClassificationUser?.[0]?.currentOccupation || '-'}}</div>
      </div>
    </div>
  </div>

  <div class="students__section">
    <h2 class="section__title">Información laboral</h2>
    <div class="section__content">
      <div class="students__field">
        <div class="field__label">Empresa donde trabaja</div>
        <div class="field__data">{{userData()?.WorkInfos?.[0]?.Company || '-' }}</div>
      </div>
    
      <div class="students__field">
        <div class="field__label">Cargo</div>
        <div class="field__data">{{userData()?.WorkInfos?.[0]?.Position || '-' }} </div>
      </div>
    
      <div class="students__field">
        <div class="field__label">Email trabajo</div>
        <div class="field__data">{{userData()?.WorkInfos?.[0]?.workEmail || '-' }}</div>
      </div>
    
      <div class="students__field">
        <div class="field__label">Teléfono trabajo</div>
        <div class="field__data">{{userData()?.WorkInfos?.[0]?.Phone || '-' }}</div>
      </div>
    
      <div class="students__field">
        <div class="field__label">Dirección trabajo</div>
        <div class="field__data">{{userData()?.WorkInfos?.[0]?.Address || '-' }}</div>
      </div>

      <div class="students__field">
        <div class="field__label">Unidad</div>
        <div class="field__data">{{userData()?.WorkInfos?.[0]?.Unit || '-' }}</div>
      </div>
    </div>
  </div>

  <div class="students__section">
    <h2 class="section__title">Condición del usuario</h2>
    <div class="section__content">
      <div class="students__field">
        <div class="field__label">Condición</div>
        <div class="field__data">{{userData()?.TypeCondition?.nameCondition || '-' }}</div>
      </div>

      <div class="students__field">
        <div class="field__label">Fuerza</div>
        <div class="field__data">{{userData()?.MilitarInfo?.Force?.NameForce || '-' }}</div>
      </div>
    
      <div class="students__field">
        <div class="field__label">Rango</div>
        <div class="field__data">{{userData()?.MilitarInfo?.RealRange?.NameRange || '-'}}</div>
      </div>
    
      @if(isMilitar()){
        <div class="students__field">
          <div class="field__label">Documento militar</div>
          <div class="field__data">{{userData()?.MilitarInfo?.CedulaMil || '-'}}</div>
        </div>
      }
    </div>
  </div>

  <div class="students__section">
    <h2 class="section__title">Información familiar</h2>
    <div class="section__content">
      <div class="students__field">
        <div class="field__label">Parentesco</div>
        <div class="field__data">{{relationShipName()}}</div>
      </div>
    </div>
  </div>
</ng-template>