import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import * as UiSelect from '@components/ui/ui-select';
import * as UiDropdwon from '@components/ui/ui-dropdown'

import { IPositions } from '@models/positions';
import { ISalary } from '@models/salary';
import { ApiService } from '@services/api-service.service';
import { concat, debounceTime, distinctUntilChanged, filter, forkJoin, map, Observable, of, Subject, Subscription, switchMap, tap, toArray } from 'rxjs';
import Swal from 'sweetalert2';


interface SalaryWithDefault extends ISalary {
  defaultSalary?:number;
  isEditing?:boolean;
}

@Component({
  selector: 'app-salary',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,

    UiDropdwon.Root,
    UiDropdwon.Trigger,
    UiDropdwon.Content,
    UiDropdwon.Item,

    UiInputComponent,
    UiButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './salary.component.html',
  styleUrl: './salary.component.css'
})
export class SalaryComponent implements OnInit, OnDestroy {
  constructor(private _fb: FormBuilder) { }
  private _apiService = inject(ApiService);

  public salaryForm:FormGroup = this._fb.group({
    year: ['', Validators.required],
    positionID: ['', Validators.required],
    tempPositionID: [''], //just for input validation
    salaryValue: ['', Validators.required],
  });

  public showDialog = signal<boolean>(false);
  public yearFilter = signal<number>(2024);
  public chargeFilter = signal<number | null>(null);
  public years = signal<number[]>([]);
  public chargesTofilter = signal<(IPositions & {selected?:boolean})[]>([]);
  public newChargesToFilter = signal<(IPositions & {selected?:boolean})[]>([]);
  public filteredSalary = signal<SalaryWithDefault[]>([]);
  public isEditing = signal<boolean>(false);
  private _chargesSubject: Subject<string> = new Subject();
  private _newChargesSubject: Subject<string> = new Subject();
  private _salarySubscription:Subscription = new Subscription();

  ngOnInit(): void {
    this.initialData();

    // Subscribe to new charges search
    this.subscribeToSearch(this._newChargesSubject, (data) => this.newChargesToFilter.set(data));

    // Subscribe to filter charge search
    this.subscribeToSearch(this._chargesSubject, (data) => this.chargesTofilter.set(data));
  }


  private subscribeToSearch(subject: Subject<string>, onSuccess: (data: IPositions[]) => void): void {
    subject.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      tap(value => {
        if(value.length <= 2)
          this.chargesTofilter.set([]);
      }),
      filter(value => value.length > 2),
      switchMap(value => {
        return this._apiService.get<IPositions>({
          path: '/Positions',
          filter: {
            limit:10,
            where: {
              namePosition: { regexp: `/${value}/i` }
            }
          }
        });
      })
    )
    .subscribe({
      next: onSuccess,
      error: (err) => console.log(err)
    });
  }

  private initialData() {
    Swal.fire({
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    forkJoin({
      years: this._apiService.get<{yearNumber:number}>({ path: 'Years' }),
      salaries: this.salaryQuery()
    }).subscribe({
      next: ({years, salaries}) => {
        this.years.set(years.map(e => e.yearNumber));
        this.filteredSalary.set(salaries);
        Swal.close();
      }, error: (errd) => {
        console.log(errd);
      }
    })
  }

  public onCreateSalary() {
    if (this.salaryForm.invalid) {
      this.salaryForm.markAllAsTouched();
      return;
    }

    this.createSalary();
  }

  public saveSalary(value:string, item:ISalary) {
    Swal.fire({
      text: "Guardando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.patch<ISalary>({
      path: 'SalaryByPositions',
      data:{
        ...item,
        salaryValue: Number(value)
      }
    }).subscribe({
      next:(resp)=>{
        item.salaryValue = parseFloat(value);

        Swal.fire({
          icon: "success",
          text: "Se actualizó el salario satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
      },
      error:(err)=>{
        console.log(err);
      }
    })
  }

  public saveAllSalaries() {
    Swal.fire({
      text: "Guardando. El proceso puede demorar un poco.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    const allSalaries = this.filteredSalary().map( item => this._apiService.patch<ISalary>({
      path: 'SalaryByPositions',
      data:{ ...item }
    }));

    concat(...allSalaries).pipe(toArray()).subscribe({
      next:(resp)=>{
        this.filteredSalary.set(resp);
        Swal.fire({
          icon: "success",
          text: "Se actualizaron los salarios satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
      },
      error:(err)=>{
        console.log(err);
      }
    })
  }

  private createSalary() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando salario, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    const year = this.salaryForm.get("year")?.value;
    const position = this.salaryForm.get("positionID")?.value;
    this._apiService.get<ISalary>({
      path: "SalaryByPositions",
      filter: { where: { and: [{ year }, { positionID: position }] } }
    }).pipe(
      switchMap(resp => {
        if (resp.length > 0) {
          Swal.fire({
            icon: "error",
            text: "Ya existe un registro con el mismo cargo y año. Por favor, ingresa un valor diferente.",
            allowEscapeKey: false,
            allowOutsideClick: false,
          });

          return [];
        }

        const { tempPositionID, ...data } = this.salaryForm.value
        return this._apiService.post<ISalary>({
          path: "SalaryByPositions",
          data
        })
      }),
      switchMap(_ => this.applyFilters())
    ).subscribe({
      next: (resp) => {
        Swal.fire({
          icon: "success",
          text: "Se guardo el salario satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.filteredSalary.set(resp);
        this.resetForm();
      },
      error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar el salario: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
    return;
  }

  private applyFilters(): Observable<SalaryWithDefault[]> {
    const year = Number(this.yearFilter());
    const salaryFilter: Record<string, any> = { and: [{ year }] };

    if(this.chargeFilter() !== 0 && this.chargeFilter() !== null)
      salaryFilter['and'] = [...salaryFilter['and'], { positionID: this.chargeFilter() }]

    return this.salaryQuery(salaryFilter)
  }

  private salaryQuery(filters?: Record<string, any>): Observable<SalaryWithDefault[]> {
    const newFilters:Record<string, any> = { include: ['Position'] }

    if (filters) newFilters['where'] = { ...filters }

    return this._apiService.get<ISalary>({
      path: 'SalaryByPositions',
      filter: { ...newFilters }
    }).pipe(
      map(resp => resp.map(e => ({ ...e, defaultSalary: e.salaryValue })))
    );
  }

  public onSelectYear(value: string) {
    this.yearFilter.set(Number(value));
    this._salarySubscription.unsubscribe();
    this._salarySubscription = this.applyFilters().subscribe({
      next: (resp) => {
        this.filteredSalary.set(resp);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  public onSearchChange(event:Event){
    const target = event.target as HTMLInputElement;
    const value = target.value;
    this._chargesSubject.next(value);
  }

  public onNewSearchChange(event:Event){
    const target = event.target as HTMLInputElement;
    const value = target.value;
    this._newChargesSubject.next(value);
  }

  public onSelectCharge(itemId:string) {
    const selectedItem = this.chargesTofilter().find( e => e.id === Number(itemId));
    if(selectedItem)
      selectedItem.selected = true;

    const prevItem = this.chargesTofilter().find( e => e.id === this.chargeFilter());
    if(prevItem)
      prevItem.selected = false;

    this.chargeFilter.set(Number(itemId));
    this._salarySubscription.unsubscribe();
    this._salarySubscription = this.applyFilters().subscribe({
      next: (resp) => {
        this.filteredSalary.set(resp);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  public onSelectNewCharge(itemId:string) {
    const selectedCharge = this.salaryForm.get("positionID")?.value;

    const selectedItem = this.newChargesToFilter().find( e => e.id === Number(itemId));
    if(selectedItem)
      selectedItem.selected = true;

    const prevItem = this.newChargesToFilter().find( e => e.id === Number(selectedCharge));
    if(prevItem)
      prevItem.selected = false;

    this.salaryForm.patchValue({positionID: Number(itemId)});
  }

  public resetForm() {
    this.salaryForm.reset();
    this.showDialog.set(false);
    this.newChargesToFilter.set([]);
  }

  public onEditChange(){
    this.isEditing.set(!this.isEditing());
    this.filteredSalary.update(prev => {
      prev.map(e => e.isEditing = this.isEditing())
      return prev;
    } )
  }

  public valueCurrency(event: Event, item?: SalaryWithDefault): void {
    const target = event.target as HTMLInputElement;
    let inputValue = target.value;
  
    inputValue = inputValue.replace(/[^0-9.]/g, '');
  
    const dotIndex = inputValue.indexOf('.');
    if (dotIndex !== -1) {
      inputValue = inputValue.slice(0, dotIndex + 1) + inputValue.slice(dotIndex + 1).replace(/\./g, '');
    }
  
    if (inputValue === '' && item) {
      item.salaryValue = item.defaultSalary ?? 0;
      inputValue = item.defaultSalary?.toString() ?? '';
    } else if (inputValue !== '' && item) {
      item.salaryValue = Number(inputValue);
    }

    target.value = inputValue;
  }

  ngOnDestroy(): void {
    this._salarySubscription.unsubscribe();    
  }
}