<div class="years">
  <div class="years__header">
    <h2 class="header__title">Años</h2>
    <div class="header__add-year">
      <ui-input type="number" variant="bordered" placeholder="Ingrese un año" [(ngModel)]="yearNumber" #searchInput/>
      <ui-button color="primary" (onClick)="createYear()">
        <em class="icon-i-add icon-save" start-decorator></em>
        Agregar
      </ui-button>
    </div>
  </div>

  <div class="years__content">
    <div class="years__list">
      @for (item of years(); track $index) {
        <div class="years__item">
          <span>{{item.yearNumber}}</span>
          <button type="button" class="button button--delete">
            <em class="icon-i-trash" (click)="deleteYear(item.yearNumber)"></em>
          </button>
        </div>
      }@empty {
        <span class="empty-list">Sin resultados.</span>
      }
    </div>
  </div>
</div>