import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import * as UiSelect from '@components/ui/ui-select';
import { IEndpoint } from '@models/endpoint';
import { IRole } from '@models/role';
import { IRoleMappings } from '@models/role-mappings';

import { ISchool } from '@models/school';
import { ApiService } from '@services/api-service.service';
import { concat, forkJoin, of, switchMap, toArray } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


type AllRoles = IRole & { checked: boolean };
@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,

    UiInputComponent,
    FormsModule,
    CommonModule
  ],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.css'
})
export class RolesComponent implements OnChanges {
  userId = input.required();

  private _apiService = inject(ApiService);
  public userRoles = signal<IRoleMappings[]>([]);
  public allRoles = signal<AllRoles[]>([]);
  private _rolesToAdd = signal<IRole[]>([]);
  private _rolesToDelete = signal<IRoleMappings[]>([]);
  public schools: ISchool[] = this._apiService.schools();
  public selectedSchoolId = signal<number>(0);
  public hasMultipleSchools = signal<boolean>(false);

  public appName = computed<string>(() => {
    const shcool = environment.APP_NAME;
    return shcool === 'celic' ? 'Liceo' : 'Escuela'
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId'] && changes['userId'].currentValue)
      this.initialData();
  }

  private initialData() {
    Swal.fire({
      text: "Cargando vista...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    this.getRoles().subscribe({
      error: (err) => console.log(err),
      complete: () => Swal.close()
    })
  }

  public onRoleChange(event: Event, roleItem: IRole) {
    //Note: If a user has the same role assigned multiple times, the role will need to be unassigned multiple times after saving.

    const target = event.target as HTMLInputElement;
    const checked = target.checked;

    //Clear if already added
    this._rolesToDelete.set(
      this._rolesToDelete().filter(e => e.roleId !== roleItem.id)
    );

    //Clear if already added
    this._rolesToAdd.set(
      this._rolesToAdd().filter(e => e.id !== roleItem.id)
    );

    if (!checked) {
      const roleMapping = this.userRoles().find( e => e.roleId === roleItem.id);
      if(roleMapping)
        this._rolesToDelete.set([...this._rolesToDelete(), roleMapping]);
    } else {
      if (!this.userRoles().some(e => e.roleId === roleItem.id))
        this._rolesToAdd.set([...this._rolesToAdd(), roleItem]);
    }

    //Refresh role state
    this.allRoles.update(prev => {
      const currentRole = prev.find(e => e.id === roleItem.id);
      if (currentRole)
        currentRole.checked = checked;

      return prev;
    });
  }

  public setNewSchool() {
    Swal.fire({
      text: "Guardando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    const rolesObs = this.userRoles().map(e => this._apiService.patch<Partial<IRoleMappings>>({
      path: `roleMappings/${e.id}`,
      data: { SchoolID: this.selectedSchoolId() }
    }))

    concat(...rolesObs).pipe(toArray()).subscribe({
      next: () => {
        this.hasMultipleSchools.set(false);
        Swal.fire({
          icon: "success",
          text: "La escuela se asignó correctamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
      },
      error: (err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Ocurrió un error al asignar la escuela: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
      }
    })
  }

  public saveRoles() {
    Swal.fire({
      title: "Guardando...",
      text: "Esto puede tardar un momento, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    const newRole = (roleToAdd: number) => ({
      principalId: this.userId(),
      SchoolID: this.selectedSchoolId(),
      roleId: roleToAdd,
    });

    //Delete all roles from db
    const rolesToDelete = this._rolesToDelete().map(e => this._apiService.delete({ path: `roleMappings/${e.id}` }));

    //Insert the new roles
    const rolesToSave = this._rolesToAdd().map(e => this._apiService.post({ path: 'roleMappings', data: newRole(e.id!) }))
    
    concat(
      ...rolesToDelete,
      ...rolesToSave,
      this.getRoles(), //Update the role list
    ).pipe(toArray()).subscribe({
      next: () => {
        this._rolesToAdd.set([]);
        this._rolesToDelete.set([]);
        Swal.fire({
          icon: "success",
          text: "Los roles han sido guardados con éxito.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
      },
      error: (err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Ocurrió un error al guardar los roles: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        console.log(err);
      }
    })
  }

  private getRoles() {
    const userRolesParams: IEndpoint = {
      path: "roleMappings",
      filter: {
        include: ['role'],
        where: { principalId: this.userId() }
      }
    }

    return forkJoin({
      allRoles: this._apiService.get<AllRoles>({ path: 'roles' }),
      userRoles: this._apiService.get<IRoleMappings>(userRolesParams),
    }).pipe(
      switchMap(({ allRoles, userRoles }) => {
        this.userRoles.set(userRoles);

        this.allRoles.set(allRoles.map(role => ({ ...role, checked: false })));
        this.selectedSchoolId.set(userRoles[0].SchoolID);
        this.hasMultipleSchools.set(userRoles.some(e => e.SchoolID !== userRoles[0].SchoolID));

        this.allRoles.update(prev => {
          const userRoleIds = new Set(userRoles.map(userRole => userRole.role?.id));

          return prev.map(role => ({
            ...role,
            checked: userRoleIds.has(role.id)
          }));
        });

        return of({ message: 'Roles loaded' })
      })
    )
  }
}