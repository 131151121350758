import { Component, computed, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import * as UiSelect from '@components/ui/ui-select';
import * as UiDropdown from '@components/ui/ui-dropdown';
import { ICourses } from '@models/course';
import { ICourseOffer } from '@models/courseoffer';
import { ISchool } from '@models/school';
import { ApiService } from '@services/api-service.service';
import { Subscription, Subject, filter, debounceTime, distinctUntilChanged, switchMap, of, Observable, concat, toArray, forkJoin } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IUser } from '@models/users';
import { IService } from '@models/service';
import { IUserCourse } from '@models/user-course';
import Swal from 'sweetalert2';
import { IServicePerUser } from '@models/service-per-user';
import { environment } from 'src/environments/environment';
import { IRealStatusCourse } from '@models/real-status-course';

interface FilteredUsers extends IUserCourse { selected?:boolean };
interface FilteredServices extends IService { selected:boolean };
@Component({
  selector: 'app-students-section',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
    UiInputComponent,
    UiButtonComponent,

    UiDropdown.Root,
    UiDropdown.Trigger,
    UiDropdown.Content,
    UiDropdown.Item,

    ReactiveFormsModule,
    FormsModule,
    CommonModule
  ],
  templateUrl: './students-section.component.html',
  styleUrl: './students-section.component.css'
})
export class StudentsSectionComponent implements OnInit, OnDestroy {
  private _apiService = inject(ApiService);
  public years = signal<{ yearNumber: number }[]>([]);
  public realStatusCourse = signal<IRealStatusCourse[]>([]);
  public schools = signal<ISchool[]>(this._apiService.schools());
  public courses = signal<ICourses[]>([]);
  public offers = signal<ICourseOffer[]>([]);

  //Filters
  public yearFilter = signal<number>(2024);
  public schoolFilter = signal<number>(0);
  public courseFilter = signal<number>(0);
  public offerFilter = signal<number>(0);
  public realStatusFilter = signal<number>(0);

  //Form year select input
  public selectedYear = signal<number>(2024);

  public filteredUsers = signal<FilteredUsers[]>([]);
  public filteredModalServices = signal<FilteredServices[]>([]);
  public servicesToAdd = signal<FilteredServices[]>([]);

  public usersToAdd = signal<number[]>([]);
  public showDialog = signal<boolean>(false);

  private _fetchedAllData = signal<boolean>(true);
  private _searchValue = signal<string>('');
  private _subscription: Subscription = new Subscription();
  private _searchSubject: Subject<string> = new Subject();
  private _searchService: Subject<string> = new Subject();
  public serviceHtmlInput = signal<string>('');
  public subjectHtmlInput = signal<string>('');

  public appName = computed<string>(() => {
    const shcool = environment.APP_NAME;
    return shcool === 'celic' ? 'Liceo' : 'Escuela'
  });

  ngOnInit(): void {
    //Search services
    this._searchService.pipe(
      filter(value => value.length > 3),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => this._apiService.get<FilteredServices>({
        path:'Services',
        filter:{
          limit: 5,
          where:{
            and:[{serviceName:{regexp: `/${value}/i`}}, {year: this.selectedYear()}]  
          }
        }
      }))
    ).subscribe({
      next:(resp)=>{
        resp.forEach( e => {
          if(this.servicesToAdd().some(toAdd => toAdd.id === e.id))
            e.selected = true;
        })
        this.filteredModalServices.set(resp);
      },error:(err)=>{
        console.log(err);
      }
    })

    //Search users
    this._searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        this._searchValue.set(value);

        if(value.length <= 3){
          if(!this._fetchedAllData()){
            this._fetchedAllData.set(true);
            return this.applyFilters()
          }

          return of(null);
        }
          
        this._fetchedAllData.set(false);
        return this.applyFilters();
      })
    ).subscribe({
      next: (resp) => {
        if(resp)
          this.filteredUsers.set(resp);
      }, error: (err) => {
        console.log(err);
      }
    })

    this.getMainData()
  }

  private getMainData() {
    Swal.fire({
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    forkJoin({
      years: this._apiService.get<{ yearNumber: number }>({ path: "Years" }),
      realStatusCourse: this._apiService.get<IRealStatusCourse>({ path: "RealStatusCourses" })
    }).subscribe({
      next: ({years, realStatusCourse}) => {
        this.years.set(years);
        this.realStatusCourse.set(realStatusCourse);
        Swal.close();
      }, error: (err) => {
        console.log(err);
      }
    })
  }

  public onSelectYear(value: string) {
    this.yearFilter.set(Number(value));

    this.courseFilter.set(0);
    this.offerFilter.set(0);
    this.schoolFilter.set(0);

    this.courses.set([]);
    this.offers.set([]);

    this.subjectHtmlInput.set('');
    this._searchSubject.next('');
  }

  public onSelectSchool(value: string) {
    this.schoolFilter.set(Number(value));

    this.courseFilter.set(0);
    this.offerFilter.set(0);

    this.courses.set([]);
    this.offers.set([]);

    this.subjectHtmlInput.set('');
    this._searchSubject.next('');

    this._subscription.unsubscribe();
    this._subscription = this._apiService.get<ICourses>({
      path: 'Courses',
      filter: {
        where: { SchoolID: Number(value) }
      }
    }).subscribe({
      next: (resp) => {
        const orderedByName = resp.sort((a, b) => a.NameCourse.localeCompare(b.NameCourse));
        this.courses.set(orderedByName);
      }, error: (err) => {
        console.log(err);
      }
    })
  }

  public onSelectCourse(value: string) {
    this.courseFilter.set(Number(value));

    this.offerFilter.set(0);
    this.offers.set([]);

    this.subjectHtmlInput.set('');
    this._searchSubject.next('');

    this._subscription.unsubscribe();
    this._subscription = this._apiService.get<ICourseOffer>({
      path: 'CoursesOfers',
      filter: {
        where: { and: [{ SchoolID: this.schoolFilter() }, { CourseID: this.courseFilter() }, { year: this.yearFilter() }] }
      }
    }).subscribe({
      next: (resp) => {
        this.offers.set(resp);
      }, error: (err) => {
        console.log(err);
      }
    })
  }

  public onSelectOffer(value: string) {
    this.offerFilter.set(Number(value));

    Swal.fire({
      title: 'Cargando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    this.subjectHtmlInput.set('');
    this._searchSubject.next('');

    this._subscription.unsubscribe();
    this._subscription = this.applyFilters().subscribe({
      next:(resp)=>{
        this.filteredUsers.set(resp);
        Swal.close();
      },error:(err)=>{
        console.log(err);
        Swal.close();
      }
    })
  }

  public onSelectRealStatus(value: string) {
    this.realStatusFilter.set(Number(value));

    Swal.fire({
      title: 'Cargando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });
  
    this._subscription.unsubscribe();
    this._subscription = this.applyFilters().subscribe({
      next:(resp)=>{
        this.filteredUsers.set(resp);
        Swal.close();
      },error:(err)=>{
        console.log(err);
        Swal.close();
      }
    })
  }

  public onSearchChange(event:Event){
    const value = (event.target as HTMLInputElement).value;
    this._searchSubject.next(value);
  }


  public onSerachService(event:Event){
    const value = (event.target as HTMLInputElement).value;
    this._searchService.next(value);
  }

  public onSlectUser(item:FilteredUsers){
    let users = this.usersToAdd();

    if (users.includes(item.UserID))
      users = users.filter(e => e !== item.UserID);
    else
      users = [...users, item.UserID];
  
    const currentUser = this.filteredUsers().find(e => e.id === item.id);
    if(currentUser)
      currentUser.selected = !currentUser.selected;

    this.usersToAdd.set(users);
  }

  public onSelectAll(event:Event){
    const value = event.target as HTMLInputElement;
    const checked = value.checked;
    this.filteredUsers().forEach(e => e.selected = checked);
    this.usersToAdd.set(this.filteredUsers().filter(e => e.selected).map(e => e.UserID!));
  }

  public onSelecteService(value: { value: string, label: string }) {
    let services = this.servicesToAdd();

    const serviceToSave = this.filteredModalServices().find(e => e.id === Number(value.value));
    if(services.some(e => e.id === Number(value.value)))
      services.filter( e => e.id !== Number(value.value))
    else if(serviceToSave)
      services.push(serviceToSave)

    if(serviceToSave)
      serviceToSave.selected = !serviceToSave.selected;

    this.servicesToAdd.set(services);

    this.serviceHtmlInput.set('');
    this._searchService.next('');
  }

  onRemoveService(value:number){
    this.filteredModalServices().forEach(service => {
      if(service.id === value)
        service.selected = false;
    })

    const removeService = this.servicesToAdd().filter(e => e.id !== value);
    this.servicesToAdd.set(removeService);
  }

  public onSave() {
    Swal.fire({
      title:"Guardando...",
      text:"Esto puede tardar un momento, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    const userPerService:Observable<IServicePerUser | null>[] = [];
    this.usersToAdd().forEach(user => {
      this.servicesToAdd().forEach(service => {
        const item$ = this._apiService.get<IServicePerUser>({
          path: 'ServicePerUsers',
          filter:{
            where: { and: [{ serviceID: service.id! }, { userID: user }] }
          }
        }).pipe(
          switchMap(resp => {
            if(resp.length > 0)
              return of(null)

            return this._apiService.post<IServicePerUser>({
              path: 'ServicePerUsers',
              data: {
                userID: user,
                year: this.selectedYear(),
                courseOfferID: this.offerFilter(),
                serviceID: service.id!
              }
            });
          })
        );
        userPerService.push(item$);
      });
    });
    
    concat(...userPerService).pipe(toArray()).subscribe({
      next:(_)=>{
        this.closeModal();
        Swal.fire({
          icon:'success',
          text:"Estudiantes asignados correctamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        })
      },error:(err)=>{
        console.log(err);
        Swal.fire({
          icon:"error",
          text: `Error al asignar los estudiantes: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        })
      }
    })
  }

  /**
   * Closes the modal and resets various states.
   */
  public closeModal(){
    this.showDialog.set(false);
    this.selectedYear.set(2024);
    this.servicesToAdd.set([]);
    this.filteredModalServices.set([]);
    this.serviceHtmlInput.set('');
    this._searchService.next('');
  }

  public applyFilters(): Observable<IUserCourse[]> {
    const searchValue = this._searchValue();

    const buildFilters = (userIds: number[] = []): Record<string, any> => {
      const filters: Record<string, any> = {
        and: [{ CourseOferID: this.offerFilter() }]
      };
  
      if (userIds.length > 0)
        filters['and'].push({ UserID: { inq: userIds } });
  
      if (this.realStatusFilter() !== 0)
        filters['and'].push({ realStatusCourseID: this.realStatusFilter() });
  
      return filters;
    };

    const getUserCourses = (userIds: number[]): Observable<IUserCourse[]> => {
      return this._apiService.get<IUserCourse>({
        path: 'UserCourses',
        filter: {
          include: ['userapp', { coursesOfer: ['course', 'school'] }],
          where: buildFilters(userIds)
        }
      });
    };

    if (searchValue.length <= 3) {
      return this._apiService.get<IUserCourse>({
        path: 'UserCourses',
        filter: {
          include: ['userapp', { coursesOfer: ['course', 'school'] }],
          where: buildFilters()
        }
      });
    }

    return this._apiService
      .get<IUser>({
        path: 'ViewUserInfos',
        filter: {
          fields: { id: true },
          where: {
            or: [
              { CedocEmail: { regexp: `/${searchValue}/i` } },
              { Document: { regexp: `/${searchValue}/i` } },
              { fullName: { regexp: `/${searchValue}/i` } },
            ],
          },
          limit: 20,
        },
      })
      .pipe(
        switchMap((users) => {
          const userIds = users.map((e) => e.id!);
          return getUserCourses(userIds);
        })
      );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}