<div class="children">
  <div class="children__section">
    <div class="children__header">
      <h2 class="children__header-title">Responsable de</h2>
      <button class="button button--solid" (click)="onAddStudentClick('responsible')">Agregar Estudiante</button>
    </div>

    <table class="children__table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Documento</th>
          <th [ngStyle]="{'width': '50px'}"></th>
        </tr>
      </thead>
      <tbody>
        @for (item of childrenData().responsible; track $index) {
          @if(item.ParentUserId){
            <tr>
              <td>
                {{ item.studentUser?.Name1 | titlecase}}
                {{ item.studentUser?.Name2 | titlecase}} {{ item.studentUser?.LastName1 | titlecase}}
                {{ item.studentUser?.LastName2 | titlecase}}
              </td>
              <td>{{ item.studentUser?.UserDocuments?.[0]?.Document }}</td>
              <td> <em class="icon-i-trash" (click)="deleteResponsible(item)"></em></td>
            </tr>
          }
        }
      </tbody>
      @if(noResponsibles()){
        <caption class="children__item">Sin resultados.</caption>
      }
    </table>
  </div>

  <div class="children__section">
    <div class="children__header">
      <h2 class="children__header-title">Acudiente de</h2>
      <button class="button button--solid" (click)="onAddStudentClick('guardian')">Agregar Estudiante</button>
    </div>

    <table class="children__table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Documento</th>
          <th [ngStyle]="{'width': '50px'}"></th>
        </tr>
      </thead>
      <tbody>
        @for (item of childrenData().guardian; track $index) {
          @if(item.attendedBy){
            <tr>
              <td>
                {{ item.studentUser?.Name1 | titlecase}}
                {{ item.studentUser?.Name2 | titlecase}} {{ item.studentUser?.LastName1 | titlecase}}
                {{ item.studentUser?.LastName2 | titlecase}}
              </td>
              <td>{{ item.studentUser?.UserDocuments?.[0]?.Document }}</td>
              <td> <em class="icon-i-trash" (click)="deleteGuardian(item)"></em></td>
            </tr>
          }
        }
      </tbody>
      @if(noGuardians()){
        <caption class="children__item">Sin resultados.</caption>
      }
    </table>
  </div>
</div>

@if(showDialog()){
  <app-assign-relationship 
    [relationship]="relationship()"
    (onCloseDialog)="showDialog.set(false)" 
  />
}