<div class="relationship">
  <div class="relationship__header">
    <h2 class="header__title">Instituciones</h2>
    <button class="button button--solid"  type="button" (click)="showDialog.set(true)">Agregar Parentesco</button>
  </div>

  <div class="relationship__content">
    <div class="relationship__list">
      @for (item of relationships(); track $index) {
        <div class="relationship__item">
          <div class="relationship__name">{{item.nameRelationship}}</div>
  
          <button type="button" class="button button--edit-relationship">
            <em class="icon-i-pen" (click)="onEditParent(item)"></em>
          </button>
        </div>
      }@empty {
        <span class="empty-list">Sin resultados.</span>
      }
    </div>
  </div>


  @if (showDialog()) {
    <div class="dialog__container">
      <form class="dialog__content" [formGroup]="relationshipForm" (ngSubmit)="onSave()">
        <div class="dialog__header">
          <h2 class="dialog__header-title">{{relationshipToEdit() ? 'Editar' : 'Agregar'}} Parentesco</h2>
          <em class="icon-i-close dialog__header-close" (click)="resetForm()"></em>
        </div>
  
        <div class="dialog__body">
          <div>
            <ui-input variant="bordered" label="Nombre del parentesco*" formControlName="nameRelationship"
              [invalid]="relationshipForm.get('nameRelationship')?.hasError('required') && relationshipForm.get('nameRelationship')?.touched"/>
            @if(relationshipForm.get('nameRelationship')?.hasError('required') && relationshipForm.get('nameRelationship')?.touched){
              <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
            }
          </div>
        </div>
  
        <ui-button color="primary" [disabled]="relationshipForm.invalid">Guardar</ui-button>
      </form>
    </div>
  }
</div>