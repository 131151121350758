<div class="generate">
  <form action="" class="generate__form">
    <div class="generate__form-group">
      <ui-select label="Autorización/Pagos" defaultValue="2024">
        <ui-select-content>
          <ui-select-item [value]="'1'"></ui-select-item>
        </ui-select-content>
      </ui-select>
      <ui-select label="Código" defaultValue="2024">
        <ui-select-content>
          <ui-select-item [value]="'1'"></ui-select-item>
        </ui-select-content>
      </ui-select>
      <ui-date-input label="Inicio" />
      <ui-date-input label="Fin" />
    </div>
    <ui-button color="primary">Genera pago de nómina</ui-button>
  </form>
</div>