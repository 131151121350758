import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TxtService {

  constructor(@Inject(DOCUMENT) private _document: Document) { }

  public exportToTxt(data:string, fileName: string): void {
    this.generateTxtFile(data, fileName);
  }

  private generateTxtFile(data: string, fileName:string): void {
    const blob = new Blob([data], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = this._document.createElement('a');
    a.href = url;
    a.download = `${fileName}.txt`;
    this._document.body.appendChild(a);
    a.click();
    this._document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}
