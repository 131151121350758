<div class="costs">
  <div class="costs__header">
    <ui-dropdown (onValueChange)="onSelectStudent($event)">
      <ui-dropdown-trigger>
        <ui-input (keyup)="onSearchChange($event)" [value]="selectedUser()?.CedocEmail" placeholder="Seleccionar usuario" #searchInput />
      </ui-dropdown-trigger>
      <ui-dropdown-content>
        @for (item of userList(); track $index) {
          <ui-dropdown-item [value]="item.id!" [disabled]="!!item.disabled">
            {{item.Name1}} {{item.Name2}} {{item.LastName1}} {{item.LastName2}} - ({{item.Document}})
          </ui-dropdown-item>
        }@empty {
          <div>Sin resultados.</div>
        }
      </ui-dropdown-content>
    </ui-dropdown>

    @if(selectedUser()){
      <div class="student__profile">
        <span class="student__label">USUARIO</span>
        <div class="student__info">
          @if(selectedUser()!.photo){
            <img class="student__picture" [src]="selectedUser()?.photo" alt="User photo">
          }@else {
            <div class="student__initials">{{userInitials()}}</div>
          }
          <span class="student__name">
            {{selectedUser()!.fullName}}
          </span>
        </div>
      </div>
    }
  </div>

  @if (selectedUser()) {
    <div class="costs__tabs">
      <app-tabs [tabs]="COST_TABS" 
        (onValueChange)="selectedTab.set($event)" defaultTab="students" />

      @switch (selectedTab()) {
        @case ("students") {
        <ng-container *hasCapability="{canEdit:true}">
          @if (!isEditing()) {
          <button class="button button--bordered" (click)="isEditing.set(true)">
            <em class="icon-i-pen"></em>
            Editar información
          </button>
          }@else {
          <div class="student__actions">
            <button (click)="isEditing.set(false)" class="button button--bordered">
              Cancelar
            </button>
        
            <button (click)="studentsComponent()?.saveData()" class="button button--solid">
              Guardar cambios
            </button>
          </div>
          }
        </ng-container>
        }
      }
    </div>

    <div class="costs__content">
      @switch (selectedTab()) {
        @case ("students") {
        <app-students (onSaveData)="isEditing.set(false)" 
          [isEditing]="isEditing()"
          [userId]="selectedUser()!.id!" />
        }
        @case ("attendant") {
          <app-parents [userId]="selectedUser()!.id!" />
        }
        @case ("enrollment") {
          <app-enrollment [userId]="selectedUser()!.id!" />
        }
        @case ("role") {
          <app-roles [userId]="selectedUser()!.id!" />
        }
        @case ("services") {
          <app-services [userId]="selectedUser()!.id!" [years]="years()" />
        }
        @default {}
      }
    </div>
  }
</div>