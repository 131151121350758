import { Injectable, signal } from '@angular/core';
import { ITypeCivilStatuses } from '@models/civil-statuses';
import { IBiologicalSex } from '@models/classifcation-user';
import { ITypeCondition } from '@models/militar-info';
import { IParentToStudents, ITypeRelationship } from '@models/parent-to-students';
import { ITypeDocument } from '@models/type-document';
import { ITypeUser } from '@models/type-user';
import { BehaviorSubject, Subject } from 'rxjs';


export interface ChildrenData { responsible:IParentToStudents[], guardian:IParentToStudents[] };

export interface BasicData{
  typeDocuments: ITypeDocument[],
  biologicalSex: IBiologicalSex[],
  civilStatuses: ITypeCivilStatuses[];
  typeRelationship: ITypeRelationship[];
  typeConditions: ITypeCondition[];
  typeUser: ITypeUser[];
}

@Injectable({
  providedIn: 'root'
})
export class ParentsService {
  public BASIC_DATA: BasicData | null = null;

  public childrenData = signal<ChildrenData>({ responsible: [], guardian: [] });
  public refreshParentToStudent$ = new Subject<void>();
  public currentUserId$ = new BehaviorSubject<number>(0);
}