import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesSectionComponent } from './services-section/services-section.component';
import { StudentsSectionComponent } from './students-section/students-section.component';



@Component({
  selector: 'app-services',
  standalone: true,
  imports: [
    ServicesSectionComponent,
    StudentsSectionComponent,
    CommonModule
  ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.css'
})
export class ServicesComponent {
  public selectedTab = signal<string>('students');
}