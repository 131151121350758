<div class="ranges">
  <div class="ranges__actions">
    <ui-input label="Nombre del cargo" (keyup)="onNameChange($event)" />

    <ui-select label="Tipo de fuerza" (onValueChange)="onForceTypeChange($event.value)" defaultValue="0">
      <ui-select-content>
        <ui-select-item value="0">Todos</ui-select-item>
        @for (item of forceTypes(); track $index) {
          <ui-select-item [value]="item.id!">{{item.NameForce | titlecase}}</ui-select-item>
        }
      </ui-select-content>
    </ui-select>
  </div>

  <div class="ranges__header">
    <h2 class="header__title">Rangos</h2>
    <button class="button button--solid" (click)="showDialog.set(true);">Agregar Rango</button>
  </div>

  <div class="ranges__content">
    <div class="ranges__list">
      @for (item of filteredRanges(); track $index) {
        <div class="ranges__item">
          <div class="item__content">
            <div class="ranges__name">{{item.NameRange}}</div>
  
            <div class="item__info">
              <div class="item__data">Cargo: <strong>{{item.Position?.namePosition || '-'}}</strong></div>
              <div class="item__data">Fuerza: <strong>{{item.Force?.NameForce || '-'}}</strong></div>
            </div>
          </div>
  
          <button class="button button--edit-force">
            <em class="icon-i-pen" (click)="onEditRange(item)"></em>
          </button>
        </div>
      }@empty {
        <span class="empty-list">Sin resultados.</span>
      }
    </div>
  </div>

  @if (showDialog()) {
  <div class="dialog__container">
    <form class="dialog__content" [formGroup]="rangesForm" (ngSubmit)="onSave()">
      <div class="dialog__header">
        <h2 class="dialog__header-title">{{rangeToEdit() ? 'Editar' : 'Agregar'}} Rango</h2>
        <em class="icon-i-close dialog__header-close" (click)="resetForm()"></em>
      </div>

      <div class="dialog__body">
        <div>
          <ui-select label="Seleccionar cargo*" formControlName="positionID"
            [invalid]="rangesForm.get('positionID')?.invalid && rangesForm.get('positionID')?.touched">
            <ui-select-content>
              @for (item of chargeTypes(); track $index) {
                <ui-select-item [value]="item.id!">{{item.namePosition}}</ui-select-item>
              }
            </ui-select-content>
          </ui-select>
          @if (rangesForm.get('positionID')?.invalid && rangesForm.get('positionID')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-select label="Tipo de fuerza*" formControlName="ForceID"
            [invalid]="rangesForm.get('ForceID')?.invalid && rangesForm.get('ForceID')?.touched">
            <ui-select-content>
              @for (item of forceTypes(); track $index) {
                <ui-select-item [value]="item.id!">{{item.NameForce}}</ui-select-item>
              }
            </ui-select-content>
          </ui-select>
          @if (rangesForm.get('ForceID')?.invalid && rangesForm.get('ForceID')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-input variant="bordered" label="Nombre del rango*" formControlName="NameRange" 
            [invalid]="rangesForm.get('NameRange')?.invalid && rangesForm.get('NameRange')?.touched"/>
          @if (rangesForm.get('NameRange')?.invalid && rangesForm.get('NameRange')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-input variant="bordered" label="Sigla*" formControlName="Sigla" 
            [invalid]="rangesForm.get('Sigla')?.invalid && rangesForm.get('Sigla')?.touched" />
          @if (rangesForm.get('Sigla')?.invalid && rangesForm.get('Sigla')?.touched) {
            <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
          }
        </div>

        <div>
          <ui-select label="Seleccionar OTAN" formControlName="OtanID">
            <ui-select-content>
              @for (item of otan(); track $index) {
                <ui-select-item [value]="item.id!">{{item.NameOtan}}</ui-select-item>
              }
            </ui-select-content>
          </ui-select>
        </div>

        <div>
          <label for="public">
            <input id="public" type="checkbox" formControlName="isPublic">
            Mostrar al público
          </label>
        </div>
      </div>

      <ui-button color="primary" [disabled]="rangesForm.invalid">Guardar</ui-button>
    </form>
  </div>
  }
</div>