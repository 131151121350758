<div class="services">
  <div class="services__filter">
    <div class="services__filter-input">
      <ui-select label="Año" defaultValue="2024" 
        (onValueChange)="getServicesOnChange($event.value)">
        <ui-select-content>
          @for (item of years(); track $index) {
          <ui-select-item [value]="item.yearNumber">{{item.yearNumber}}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
    </div>
  </div>

  <div class="services__list">
    @if (filteredServices().length > 0) {
      <div class="service__content">
        <table class="service__table">
          <thead>
            <tr>
              <th class="service__table-th">Nombre servicio</th>
              <th class="service__table-th">Año</th>
              <th class="service__table-th">Oferta</th>
              <th class="service__table-th">Responsable de pago</th>
              <th class="service__table-th">Valor</th>
              <th class="service__table-th"></th>
            </tr>
          </thead>
          <tbody>
            @for (item of filteredServices(); track $index) {
              <tr>
                <td class="service__table-td">{{item.Service?.serviceName}}</td>
                <td class="service__table-td">{{item.CourseOffer?.year}}</td>
                <td class="service__table-td">{{item.CourseOffer?.NameCourseOfer}}</td>
                <td class="service__table-td">
                  @if(item.User?.parentsToStudents?.[0]){
                    {{item.User?.parentsToStudents![0].parentUser?.Name1}}
                    {{item.User?.parentsToStudents![0].parentUser?.Name2}}
                    {{item.User?.parentsToStudents![0].parentUser?.LastName1}}
                    {{item.User?.parentsToStudents![0].parentUser?.LastName2}}
                  }@else {
                    n/a
                  }
                </td>
                <td class="service__table-td">
                  @if(item.price && item.price['pricesPerServiceFilter'].length > 0){
                    {{item.price['pricesPerServiceFilter'][0].price | currency:'COP '}}
                  }@else {
                    0
                  }
                  @if (item.price && item.price['message']) {
                    <div>{{item.price['message']}}</div>
                  }
                </td>
                <td class="service__table-td" [ngStyle]="{width: '40px'}"> 
                  <em class="icon-i-trash delete-icon" (click)="deleteService(item.id!)"></em>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }@else {
      <div class="empty-list">Este usuario aún no cuenta con servicios.</div>
    }
  </div>
</div>