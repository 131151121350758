import { Component, computed, inject, input, OnChanges, OnDestroy, OnInit, signal, SimpleChanges, viewChild } from '@angular/core';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import * as UiDropdwon from '@components/ui/ui-dropdown'
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { IUser } from '@models/users';
import { debounceTime, distinctUntilChanged, filter, forkJoin, map, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { ApiService } from '@services/api-service.service';
import { CommonModule } from '@angular/common';
import { IEndpoint } from '@models/endpoint';
import { IParentToStudents, ITypeRelationship } from '@models/parent-to-students';
import Swal from 'sweetalert2';
import { ITypeDocument } from '@models/type-document';
import { IBiologicalSex } from '@models/classifcation-user';
import { UserDataComponent } from './user-data/user-data.component';
import { ITypeCivilStatuses } from '@models/civil-statuses';
import { IForce, ITypeCondition } from '@models/militar-info';
import { ITypeUser } from '@models/type-user';
import { ChildrenDataComponent } from './children-data/children-data.component';
import { ParentsService } from './parents.service';

interface StudentItem { value: number | string; label: string };
interface Assignments {
  guardian?: number | null;
  responsible?: number | null;
}

@Component({
  selector: 'app-parents',
  standalone: true,
  imports: [
    CommonModule,
    UiDropdwon.Root,
    UiDropdwon.Trigger,
    UiDropdwon.Content,
    UiDropdwon.Item,

    UiInputComponent,
    UiButtonComponent,

    UserDataComponent,
    ChildrenDataComponent
  ],
  templateUrl: './parents.component.html',
  styleUrl: './parents.component.css',
})
export class ParentsComponent implements OnInit, OnChanges, OnDestroy {
  private _apiService = inject(ApiService);
  private _parentsService = inject(ParentsService);

  userId = input.required<number>();
  guardianComponent = viewChild<UserDataComponent>("guardianComponent");
  responsibleComponent = viewChild<UserDataComponent>("responsibleComponent");

  public userList = signal<(IUser & { disabled?: boolean })[]>([]);
  public newAssignment = signal<IUser | null>(null);
  public assignments = signal<Assignments | null>(null);
  public showDialog = signal<boolean>(false);
  public assignmentType = signal<"guardian"|"responsible" | null>( null);
  public editGuardian = signal<boolean>( false);
  public editResponsible = signal<boolean>(false);
  public parentToStudent = signal<IParentToStudents | null>(null);
  public loadingView = signal<boolean>(true);
  public loadedResposible = signal<boolean>(false);
  public loadedGuardian = signal<boolean>(false);
  public childrenData = computed(()=> this._parentsService.childrenData())
  private _searchSubject = new Subject<string>();
  private _destroy$ = new Subject<void>();

  ngOnInit(): void {
    //Updates parent to student data, when the user assigns a new student to the responsible or guardian.
    this._parentsService.refreshParentToStudent$.pipe(
      takeUntil(this._destroy$),
    ).subscribe(_ => {
      Swal.fire({
        text: "Refrescando datos...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading()
      });
      this.getParentToStudentData()
        .subscribe(() => Swal.close())
    });

    //Search for responsible or guardian in db
    this._searchSubject
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(300),
        distinctUntilChanged(),
        filter((value) => value.length > 3),
        switchMap((value) => this.findUsers(value))
      )
      .subscribe({
        next: (resp: IUser[]) => {
          this.userList.set(
            resp.map((e) => ({ ...e, disabled: this.newAssignment()?.id! === e.id }))
          );
        },
        error: (err) => console.log(err)
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId']){
      this._parentsService.currentUserId$.next(this.userId());
      this.getInitialData();
    }
  }

  public getInitialData() {
    Swal.fire({
      text: "Cargando vista...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    forkJoin({
      typeDocuments: this._apiService.get<ITypeDocument>({ path: 'TypeDocuments' }),
      biologicalSex: this._apiService.get<IBiologicalSex>({ path: 'TypeBiologicalSexes' }),
      civilStatuses: this._apiService.get<ITypeCivilStatuses>({path: 'TypeCivilStatuses'}),
      typeRelationship: this._apiService.get<ITypeRelationship>({path: 'TypeRelationships'}),
      forces: this._apiService.get<IForce>({path:"Forces"}),
      typeConditions: this._apiService.get<ITypeCondition>({path:"TypeConditions"}),
      typeUser: this._apiService.get<ITypeUser>({path:"TypeUsers"}),
    }).pipe(
      takeUntil(this._destroy$),
      switchMap((basicData) => this.getParentToStudentData().pipe(
        map(_ => basicData)
      ))
    ).subscribe({
      next: (basicData) =>  this._parentsService.BASIC_DATA = basicData,
      error: (err) => console.log(err),
      complete: () => this.dataLoaded()
    });
  }

  private getParentToStudentData(){
    //this.childrenData.set({ responsible: [], guardian: [] });
    this.assignments.set({ guardian: null, responsible: null});

    return forkJoin({
      attendants: this.getAttendants(),
      children: this.getChildren()
    }).pipe(
      takeUntil(this._destroy$),
      switchMap(({attendants, children}) => {
        //If I'm a parent
        if(children.length > 0){
          const responsibleOf: IParentToStudents[] = [];
          const guardianOf: IParentToStudents[] = [];
        
          children.forEach(parentToStudent => {
            if (parentToStudent.studentUser) {
              if (parentToStudent.ParentUserId === this.userId())
                responsibleOf.push(parentToStudent);
              if (parentToStudent.attendedBy === this.userId())
                guardianOf.push(parentToStudent);
            }
          });
        
          this._parentsService.childrenData.set({
            responsible: responsibleOf,
            guardian: guardianOf
          });

          console.log(this._parentsService.childrenData());
          

          return of({attendants, children});
        }

        //Parent assigments
        const data = attendants.find(e => e.parentUser);
        this.parentToStudent.set(data || null);        

        this.assignments.set({
          guardian: data?.attendedBy || null,
          responsible: data?.ParentUserId || null,
        });

        return of({attendants, children});;
      })
    )
  }

  private dataLoaded(){
    this.loadingView.set(false);
    Swal.close();
  }

  private getChildren(){
    const assigmentParams: IEndpoint = {
      path: 'ParentsToStudents',
      filter: {
        include: [{ parentUser: 'UserDocuments' }, { attendantUser: 'UserDocuments' }, { studentUser: 'UserDocuments' }, 'typeRlelationshipAttended', 'typeRlelationshipParent'],
        where: { or: [{ ParentUserId: this.userId() }, { attendedBy: this.userId() }] },
      },
    };

    return this._apiService.get<IParentToStudents>(assigmentParams)
  }

  private getAttendants(){
    const assigmentParams: IEndpoint = {
      path: 'ParentsToStudents',
      filter: {
        include: [{parentUser:'UserDocuments'}, {attendantUser: 'UserDocuments'}, 'typeRlelationshipAttended', 'typeRlelationshipParent'],
        where: { StudentUserId: this.userId() },
      },
    };

    return this._apiService.get<IParentToStudents>(assigmentParams)
  }

  private findUsers(value: string): Observable<IUser[]> {
    const params = {
      path: 'ViewUserInfos',
      filter: {
        where: {
          or: [
            { CedocEmail: { regexp: `/${value}/i` } },
            { Document: { regexp: `/${value}/i` } },
          ],
        },
        limit: 10,
      },
    };

    return this._apiService.get<IUser>(params);
  }

  public onAssignClick(type: "responsible" | "guardian") {
    this.assignmentType.set(type);
    this.toggleDialog();
  }

  public saveAssignment() {
    if (!this.newAssignment())
      return;

    Swal.fire({
      text: "Guardando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    const isResponsible = this.assignmentType() === 'responsible';
    const parentData: IEndpoint = {
      path: 'ParentsToStudents',
      data: {
        id: this.parentToStudent()?.id,
        ParentUserId: this.newAssignment()!.id,
        StudentUserId: this.userId()
      }
    }

    const attendantData: IEndpoint = {
      path: "ParentsToStudents",
      data: { 
        id: this.parentToStudent()?.id,
        attendedBy: this.newAssignment()!.id 
      }
    }

    const endPointParams = isResponsible ? parentData : attendantData;
    this._apiService.patch(endPointParams)
    .pipe(
      takeUntil(this._destroy$),
    ).subscribe({
      next: (newParentToStudent) => {
        this.assignments.update(prev => {
          const updatedAssignment = isResponsible
            ? { responsible: this.newAssignment()!.id }
            : { guardian: this.newAssignment()!.id };

          return { ...prev, ...updatedAssignment };
        });

        Swal.fire({
          icon: "success",
          text: "Usuario asignado correctamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.parentToStudent.set(newParentToStudent);
        this.toggleDialog();
      },
      error: (err) => {
        Swal.fire({
          title: "Error",
          text: `Ocurrio un error: ${err.message || 'Error interno del servidor.'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        })
        console.log(err);
      }
    });
  }

  public dataToSave(type:"responsible" | "guardian"){
    if(type === "responsible"){
      this.responsibleComponent()?.saveData();
      return;
    }
    this.guardianComponent()?.saveData();
  }

  public onSearchChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this._searchSubject.next(value);
  }

  public toggleDialog() {
    this.showDialog.set(!this.showDialog());
    this.userList.set([]);
    this.newAssignment.set(null);
  }

  public onSelectUser(student: StudentItem) {
    const currentUser = this.userList().find((e) => e.id == student.value);
    this.newAssignment.set(currentUser!);

    this.userList().forEach((e) => {
      e.disabled = e.id === currentUser!.id;
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}