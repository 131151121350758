import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import * as UiSelect from '@components/ui/ui-select';
import { ISchool } from '@models/school';
import { ITypeRequest } from '@models/type-request';
import { ApiService } from '@services/api-service.service';
import { Observable, Subscription, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-categories',
  standalone: true,
  imports: [
    CommonModule,

    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,

    UiInputComponent,
    UiButtonComponent,
    ReactiveFormsModule
  ],
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.css'
})
export class CategoriesComponent implements OnDestroy {
  constructor(private _fb:FormBuilder){}
  private _apiService = inject(ApiService);

  public categoriesForm: FormGroup = this._fb.group({
    schoolID: ['', Validators.required],
    nameTypeRequest: ['', Validators.required],
    isEnable: [false],
    isPublic: [false]
  });

  public showDialog = signal<boolean>(false);
  public filteredCategories = signal<ITypeRequest[]>([]);
  public schools = signal<ISchool[]>(this._apiService.schools());
  public schoolFilter = signal<number>(0);
  public categoryToEdit = signal<ITypeRequest | null>(null);
  private _categorySubscription:Subscription = new Subscription();

  public savingStates = signal<boolean>(false);
  public appName = environment.APP_NAME;

  ngOnInit(): void {
    this.initialData();
  }

  private initialData(){
    Swal.fire({
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    this.applyFilters().subscribe({
      next: (resp) => {
        this.filteredCategories.set(resp);
        Swal.close();
      }, error: (err) => {
        console.log(err);
      }
    })
  }

  public onSelectSchool(value:string){
    this.schoolFilter.set(Number(value));

    this._categorySubscription.unsubscribe();
    this._categorySubscription = this.applyFilters().subscribe({
      next:(resp)=>{
        this.filteredCategories.set(resp);
      },error:(err)=>{
        console.log(err);
      }
    })
  }

  public onSave() {
    if (this.categoriesForm.invalid) {
      this.categoriesForm.markAllAsTouched();
      return;
    }

    if (this.categoryToEdit()) this.editCategory()
    else this.createCategory()
  }

  private createCategory() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando categoría, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })
 
    this._apiService.post<ITypeRequest>({
      path: "TypesRequest",
      data: {
        ...this.categoriesForm.value,
        schoolID: this.categoriesForm.get('schoolID')?.value == '0' ? null : this.categoriesForm.get('schoolID')?.value
      }
    }).pipe(
      switchMap(_ => this.applyFilters())
    ).subscribe({
      next: (resp) => {
        Swal.fire({
          icon: "success",
          text: "Se guardó la categoría satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.filteredCategories.set(resp);
        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar la categoría: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  public toggleEnabled(event:Event, typeRequest:ITypeRequest){
    const target = event.target as HTMLInputElement;
    const checked = target.checked;

    this.savingStates.set(true);

     this._categorySubscription.unsubscribe();
     this._categorySubscription = this._apiService.patch<Partial<ITypeRequest>>({
      path: `TypesRequest/${typeRequest.id}`,
      data: {
        isEnable: checked,
      }
    }).pipe(
      switchMap(_ => this.applyFilters())
    ).subscribe({
      next:(resp)=>{
        this.filteredCategories.set(resp);
        this.savingStates.set(false);
      },error:(err)=>{
        console.log(err);
      }
    })
  }

  public togglePublic(event:Event, typeRequest:ITypeRequest){
    const target = event.target as HTMLInputElement;
    const checked = target.checked;

    this.savingStates.set(true);

    this._categorySubscription.unsubscribe();
    this._categorySubscription = this._apiService.patch<Partial<ITypeRequest>>({
      path: `TypesRequest/${typeRequest.id}`,
      data: {
        isPublic: checked,
      }
    }).pipe(
      switchMap(_ => this.applyFilters())
    ).subscribe({
      next:(resp)=>{
        this.filteredCategories.set(resp);
        this.savingStates.set(false);
      },error:(err)=>{
        console.log(err);
      }
    })
  }

  private editCategory() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando categoría, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.patch<ITypeRequest>({
      path: `TypesRequest/${this.categoryToEdit()!.id}`,
      data: {
        id: this.categoryToEdit()!.id,
        ...this.categoriesForm.value,
        schoolID: this.categoriesForm.get('schoolID')?.value == '0' ? null : this.categoriesForm.get('schoolID')?.value
      }
    }).pipe(
      switchMap(_ => this.applyFilters())
    ).subscribe({
      next: (resp) => {
        Swal.fire({
          icon: "success",
          text: "Se guardó la categoría satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.filteredCategories.set(resp);

        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar la categoría: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  private applyFilters(): Observable<ITypeRequest[]> {
    const schoolId = this.schoolFilter();
    const categoryFilter: Record<string, any> = {};
    const isCelic = this.appName === 'celic'

    if (schoolId === 0) {
      categoryFilter['or'] = [
        { schoolID: { inq: this.schools().map(e => e.id) } },
      ];

      if (isCelic)
        categoryFilter['or'].push({ schoolID: null })
    } else {
      categoryFilter['schoolID'] = schoolId;
    }

    return this.chargesQuery(categoryFilter)
  }

  private chargesQuery(filters?: Record<string, any>): Observable<ITypeRequest[]> {
    const newFilters: any = { include: ['School'] }

    if (filters)
      newFilters.where = { ...filters }

    return this._apiService.get<ITypeRequest>({
      path: 'TypesRequest',
      filter: { ...newFilters }
    })
  }

  public onEditCategory(item: ITypeRequest) {
    this.categoryToEdit.set(item);
    this.categoriesForm.patchValue({
      ...item,
      schoolID: item.schoolID ?? '0'
    });
    this.showDialog.set(true);
  }
  
  public resetForm() {
    this.categoriesForm.reset();
    this.categoryToEdit.set(null);
    this.showDialog.set(false);
  }

  ngOnDestroy(): void {
    this._categorySubscription.unsubscribe();
  }
}
