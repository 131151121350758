import { CommonModule } from '@angular/common';
import { Component, computed, forwardRef, input, OnInit, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-date-input',
  templateUrl: './ui-date-input.component.html',
  styleUrl: './ui-date-input.component.css',
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiDateInputComponent),
      multi: true,
    },
  ],
})
export class UiDateInputComponent implements ControlValueAccessor, OnInit {
  // Inputs
  label = input<string>();
  invalid = input<boolean>();
  disabled = input<boolean>();
  type = input<'date' | 'datetime-local'>('date');
  value = input<string | number>();
  variant = input<'flat' | 'bordered' | 'underline'>('flat');
  public finalValue = signal<any>(undefined);

  public combinedClasses = computed(() => {
    const classes = {
      'no-label': !this.label(),
      [this.variant().toString()]:true,
      [`invalid-field invalid-field--${this.variant()}`]: this.invalid(),
      [`disabled disabled--${this.variant()}`]: this.disabled() || this.isDisabled()
    };
    return classes;
  });


  //#region ACCESSOR
  private _onChange: (value: any) => void = () => {};
  public onTouch = () => {};
  public isDisabled = signal<boolean>(false);
  //#endregion

  ngOnInit(): void {
    if(this.value())
      this.finalValue.set(this.value());
  }

  public onValueChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this._onChange(value);
  }

  //#region ACCESSORS
  writeValue(value: string): void {
    this.finalValue.set(value);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }
  //#endregion
}
