import { CommonModule } from '@angular/common';
import { Component, computed, OnDestroy, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import * as UiSelect from '@components/ui/ui-select';
import { IPayrolls } from '@models/payrolls';
import { ApiService } from '@services/api-service.service';
import { TxtService } from '@services/txt.service';
import { XlsxService } from '@services/xlsx.service';
import { forkJoin, Subscription, tap } from 'rxjs';
import Swal from 'sweetalert2';

interface ITableData extends IPayrolls {
  isEditing?: boolean;
}

const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

@Component({
  selector: 'app-history',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
    CommonModule,
    FormsModule,
  ],
  templateUrl: './history.component.html',
  styleUrl: './history.component.css',
})
export class HistoryComponent implements OnInit, OnDestroy {
  constructor(
    private _ApiService: ApiService,
    private _xlsxService: XlsxService,
    private _txtService: TxtService
  ) {}
  public data = signal<ITableData[]>([]);
  public years = signal<{ yearNumber: number }[]>([]);
  public selectedYear = signal<number>(new Date().getFullYear());
  public loading = signal<boolean>(false);
  public editAll = signal<boolean>(false);
  public months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  public selectedMonth = signal<number>(new Date().getMonth() + 1);
  public authorizations = ['Registro de control de Ejército', 'Pago de nómina'];
  public codes = signal<number[]>([9972]);
  public selectedCode = signal<number>(9972);
  public selectedAuthorization = signal<number>(1);
  private _subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.loading.set(true);
    forkJoin({
      years: this._ApiService.get<{ yearNumber: number }>({ path: 'Years' }),
      payrolls: this.queryPayrolls(),
    }).subscribe({
      next: ({ years }) => {
        this.years.set(years);
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => this.loading.set(false),
    });
  }

  public onEditAll(): void {
    this.editAll.set(!this.editAll());
  }

  public downloadTxtReport(): void {
    //TODO: Get the total data count, according to the selected month: example 2500
    //TODO: dataCount puede ser mayor a 999999?
    const currentDate = new Date();

    const dataCount = (2500).toString(); //nÚMERO DE REGISTROS A REPORTAR
    const selectedYear = currentDate.getFullYear();
    const selectedMonth = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');

    const discountReport =
      '0'.repeat(15) +
      '0'.repeat(6 - dataCount.length) +
      dataCount +
      'REGISTRO DE CONTROL' +
      ' '.repeat(6) +
      selectedYear +
      selectedMonth +
      ' '.repeat(5) +
      1; // 1 default

    const columnData = (
      userDocument: string,
      code: string,
      discountValue: number,
      startYear: number,
      startMonth: number,
      endYear: number,
      endMonth: number
    ) => {
      //TODO: Fecha en la que se genera el archivo?
      const disposalDate = new Date();

      const integerValue = Math.floor(discountValue);
      const cents = (discountValue % 1).toFixed(2).slice(2);

      return (
        '0'.repeat(11 - userDocument.length) +
        userDocument.toUpperCase() +
        code +
        '0'.repeat(6 - dataCount.length) +
        dataCount +
        disposalDate.getFullYear().toString() +
        (disposalDate.getMonth() + 1).toString().padStart(2, '0') +
        disposalDate.getDate().toString().padStart(2, '0') +
        integerValue.toString().padStart(10, '0') +
        cents +
        '00000' + // Default
        startYear.toString() +
        startMonth.toString().padStart(2, '0') +
        endYear.toString() +
        endMonth.toString().padStart(2, '0')
      );
    };

    const payrollData = this.data().map((payroll) => {
      const militarInfo =
        payroll.Userapp.MilitarInfo?.CedulaMil || '0'.repeat(11);
      return columnData(
        militarInfo,
        payroll.discountCode.toString(),
        payroll.value,
        payroll.startYear,
        payroll.startMonth,
        payroll.endYear,
        payroll.endMonth
      );
    });

    const data = [discountReport, ...payrollData].join('\n');
    this._txtService.exportToTxt(
      data,
      `payrolls_${currentDate.toLocaleDateString()}`
    );
  }

  public onApplyFilters(): void {
    this._subscription.unsubscribe();
    this._subscription.add(this.queryPayrolls().subscribe());
  }

  public generateExcelFile(): void {
    const data = this.data().map((payroll, index) => {
      const useName = `${payroll.Userapp.Name1} ${
        payroll.Userapp.Name2 || ''
      } ${payroll.Userapp.LastName1} ${payroll.Userapp.LastName2 || ''}`
        .replace(/ +(?= )/g, '')
        .toUpperCase();

      const startDate = `${payroll.startYear}-${payroll.startMonth}`;
      const endDate = `${payroll.endYear}-${payroll.endMonth}`;

      return {
        'Numb.': index + 1,
        Código: payroll.discountCode.toString(),
        Nombre: useName,
        'F. Inicio': startDate,
        'F. Termino': endDate,
        'Valor Descuento': payroll.value.toString(),
      };
    });

    const currentDate = new Date();
    this._xlsxService.exportToXlsx(
      data,
      `payrolls_${currentDate.toLocaleDateString()}`
    );
  }

  private queryPayrolls() {
    const filter: Record<string, any> = {
      and: [
        {
          discountCode: this.selectedCode(),
          billingCycle: this.selectedMonth(),
        },
      ],
    };

    return this._ApiService
      .get<ITableData>({
        path: 'PayrollsMinistry',
        filter: {
          where: { ...filter },
          include: [{ Userapp: 'MilitarInfo' }],
        },
      })
      .pipe(tap((res) => this.data.set(res)));
  }

  public saveValue(item: ITableData): void {
    Swal.fire({
      title: 'Guardando valor...',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    this._ApiService
      .put<ITableData>({
        path: 'PayrollsMinistry',
        data: item,
      })
      .subscribe({
        next: (_) => {
          Swal.fire({
            title: 'Valor guardado',
            icon: 'success',
            allowEnterKey: false,
          });
          item.isEditing = false;
        },
        error: (error) => {
          console.log(error);
          Swal.fire({
            title: 'Error al guardar el valor',
            icon: 'error',
            allowEnterKey: false,
          });
        },
      });
  }

  public onCurrencyChange(event: Event, item: ITableData): void {
    const target = event.target as HTMLInputElement;
    let inputValue = target.value;

    inputValue = inputValue.replace(/[^0-9.]/g, '');
    inputValue = inputValue.replace(/^(\d*\.\d*)\./g, '$1');

    target.value = inputValue;

    item.value = parseFloat(inputValue);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
