<div class="input-container">
  <div class="input-field" [ngClass]="combinedClasses()">
    <label class="field-label">{{ label() }}</label>
    <div class="input-field__content">
      <input
        class="ui-input"
        (blur)="onTouch()"
        [value]="finalValue()"
        (input)="onValueChange($event)"
        [type]="type()"
      />
    </div>
  </div>
</div>