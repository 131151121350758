import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import * as UiSelect from '@components/ui/ui-select';
import { ISchool } from '@models/school';
import { IService } from '@models/service';
import { ApiService } from '@services/api-service.service';
import { forkJoin, Observable, Subscription, switchMap } from 'rxjs';
import Swal from 'sweetalert2';
import { TarifasComponent } from './tarifas/tarifas.component';
import { ITypeRequest } from '@models/type-request';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { IForce, IRange, ITypeCondition } from '@models/militar-info';
import { IRateType } from '@models/rate-type';
import { environment } from 'src/environments/environment';

interface Years { yearNumber: number };
interface SharedData{
  forces:IForce[],
  ranges:IRange[],
  conditions:ITypeCondition[],
  tarifaTypes:IRateType[]
}

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
    UiButtonComponent,
    UiInputComponent,
    TarifasComponent,
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.css'
})
export class ServicesComponent implements OnInit {

  private _apiService = inject(ApiService);
  constructor(private _fb: FormBuilder) { }

  public showDialog = signal<boolean>(false);
  public serviceForm: FormGroup = this._fb.group({
    year: ['', Validators.required],
    schoolID: ['', Validators.required],
    typeRequestID: ['', Validators.required],
    serviceName: ['', Validators.required],
    priceService: ['', Validators.required],
    milirtarDiscountPercentage: ['', Validators.required],
  });

  public yearFilter = signal<number>(2024);
  public categoryFilter = signal<number>(0);
  public schoolFilter = signal<number>(0);
  public years = signal<Years[]>([]);
  public categories = signal<ITypeRequest[]>([]);
  public schools = signal<ISchool[]>(this._apiService.schools());
  public filteredServices = signal<IService[]>([]);
  public sharedData = signal<SharedData | null>(null);
  public serviceToEdit = signal<IService | null>(null);
  private _servicesSubscription: Subscription = new Subscription();
  public appName = environment.APP_NAME;;

  ngOnInit(): void {
    this.initialData();
  }

  private initialData() {
    Swal.fire({
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    forkJoin({
      years: this._apiService.get<Years>({ path: 'Years' }),
      categories: this._apiService.get<ITypeRequest>({ path: 'TypesRequest' }),
      services: this.applyFilters(),
      forces: this._apiService.get<IForce>({ path: 'Forces' }),
      ranges: this._apiService.get<IRange>({ path: 'Ranges' }),
      conditions: this._apiService.get<ITypeCondition>({ path: 'TypeConditions' }),
      tarifaTypes: this._apiService.get<IRateType>({ path: 'TypeRates' }),
    }).subscribe({
      next: ({ years, categories, services, forces, ranges, conditions, tarifaTypes }) => {
        this.years.set(years);
        this.categories.set(categories);
        this.filteredServices.set(services);

        this.sharedData.set({
          forces,
          ranges,
          conditions, 
          tarifaTypes
        });

      }, error: (err) => {
        console.log(err);
      }, complete: () => {
        Swal.close();
      }
    })
  }

  public onSave() {
    if (this.serviceForm.invalid) {
      this.serviceForm.markAllAsTouched();
      return;
    }

    if (this.serviceToEdit()) {
      this.editService()
      return;
    }

    this.createService()
  }

  private editService() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando el servicio, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.patch<IService>({
      path: `Services/${this.serviceToEdit()!.id}`,
      data: {
        id: this.serviceToEdit()!.id,
        ...this.serviceForm.value,
        schoolID: this.serviceForm.value.schoolID == 0 ? null : this.serviceForm.value.schoolID
      }
    }).pipe(
      switchMap(_ => this.applyFilters())
    ).subscribe({
      next: (resp) => {
        Swal.fire({
          icon: "success",
          text: "Se guardó el servicio satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.filteredServices.set(resp);

        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar el servicio: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  private createService() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando servicio, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.post<IService>({
      path: "Services",
      data: {
        ...this.serviceForm.value,
        schoolID: this.serviceForm.value.schoolID == 0 ? null : this.serviceForm.value.schoolID
      }
    }).pipe(
      switchMap(_ => this.applyFilters())
    ).subscribe({
      next: (resp) => {
        Swal.fire({
          icon: "success",
          text: "Se guardo el servicio satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.filteredServices.set(resp);
        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar el servicio: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  public refreshData() {
    this._servicesSubscription.unsubscribe();
    this._servicesSubscription = this.applyFilters().subscribe({
      next: (resp) => {
        this.filteredServices.set(resp);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  public onSelectYear(value: string) {
    this.yearFilter.set(Number(value));
    this.refreshData();
  }

  public onSelectSchool(value: string) {
    this.schoolFilter.set(Number(value));
    this.refreshData();
  }

  public onSelectCategory(value: string) {
    this.categoryFilter.set(Number(value));
    this.refreshData();
  }

  private applyFilters(): Observable<IService[]> {
    const year = Number(this.yearFilter());
    const schoolId = Number(this.schoolFilter());
    const typeRequest = Number(this.categoryFilter());

    const serviceFilter: Record<string, any> = { and: [{ year }] };

    if(schoolId !== 0)
      serviceFilter['and'] = [...serviceFilter['and'], {schoolID:schoolId }]
    else 
      serviceFilter['and'] = [...serviceFilter['and'], { or: [{ inq: this.schools().map(e => e.id) }, { choolID: null }] }]

    if (typeRequest !== 0)
      serviceFilter['and'] = [...serviceFilter['and'], { typeRequestID: typeRequest }]

    return this.rangesQuery(serviceFilter)
  }

  private rangesQuery(filters?: Record<string, any>): Observable<IService[]> {
    const newFilters: any = { include: ['School', 'TypeRequest', { Rates: ['School', 'Force', 'Range', 'TypeCondition', 'TypeRate'] }] }

    if (filters) newFilters.where = { ...filters };

    return this._apiService.get<IService>({
      path: 'Services',
      filter: { ...newFilters }
    })
  }

  public onEditService(service: IService) {
    this.serviceToEdit.set(service);
    this.serviceForm.patchValue({ 
      ...service,
      schoolID: service.schoolID ?? 0
     });
    this.showDialog.set(true);
  }

  public resetForm() {
    this.serviceForm.reset();
    this.serviceToEdit.set(null);
    this.showDialog.set(false);
  }

  public valueCurrency(event:Event){
    const target = event.target as HTMLInputElement;
    let inputValue = target.value;

    inputValue  = inputValue.replace(/[^0-9.]/g, '');
    inputValue = inputValue.replace(/^(\d*\.\d*)\./g, '$1');

    target.value = inputValue;
  }
}