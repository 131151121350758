<div class="services">
  <div class="services__header">
    <h2 class="header__title">Asociar servicios a estudiantes</h2>
    <p class="header__text">Selecciona todos los estudiantes a los que quieras asignarle un servicio</p>

    <div class="header__actions">
      <input type="radio" name="view" checked hidden id="students" (change)="selectedTab.set('students')">
      <label for="students" class="header__actions-button">
        ASOCIAR ESTUDIANTES
      </label>

      <input type="radio" name="view" hidden id="services" (change)="selectedTab.set('services')">
      <label for="services" class="header__actions-button">
        SERVICIOS
      </label>
    </div>
  </div>

  <div class="services__content">
    @switch (selectedTab()) {
    @case ('students') {
      <app-students-section />
    }@case ('services') {
      <app-services-section />
    }@default {}
    }
  </div>
</div>