<div class="tarifa">
  <div class="tarifa__actions">
    <button class="button button--tarifas" (click)="onCreateClick()">Agregar Tarifas</button>
    @if(serviceItem().Rates.length > 0){
      <button class="button button--tarifas" (click)="showChildren.set(!showChildren())">Ver Tarifas</button>
    }
  </div>

  @if(showChildren()){
  <form class="tarifa__content" [formGroup]="rateForm" (submit)="saveData()">
    @if (showNewRateForm()) {
      <div class="tarifa__item">
        <ng-container *ngTemplateOutlet="formTemplate; context: {formGroup:rateForm}" />
      </div>
    }

    @for (item of rates(); track $index) {
      <div class="tarifa__item">
        @if(editIndex() !== $index){
          <div class="item__data">
            <div class="item__label">{{appName === 'celic' ? 'Liceo' : 'Escuela'}}</div>
            <div class="item__content">{{item.School?.NameTSchool ? item.School?.NameTSchool : appName === 'celic' ? 'Todos los Liceos' : 'Todos las Escuelas'}}</div>
          </div>

          <div class="item__data">
            <div class="item__label">Institución</div>
            <div class="item__content">{{item.Force?.NameForce || 'Todas las fuerzas'}}</div>
          </div>

          <div class="item__data">
            <div class="item__label">Rango</div>
            <div class="item__content">{{item.Range?.NameRange || 'Todos'}}</div>
          </div>

          <div class="item__data">
            <div class="item__label">Condición</div>
            <div class="item__content">{{item.TypeCondition?.nameCondition || 'Todos'}}</div>
          </div>

          <div class="item__data">
            <div class="item__label">Tipo de tarifa</div>
            <div class="item__content">{{item.TypeRate?.nameRate}}</div>
          </div>

          <div class="item__data">
            <div class="item__label">Valor</div>
            <div class="item__content">
              @if (item.typeRateID === 1) {
                {{item.rateValue | currency : 'COP '}}
              }@else {
                {{item.rateValue}} %
              }
            </div>
          </div>

          <div class="item__actions">
            <button type="button" class="button button--edit" (click)="onClickEdit(item, $index)">
              <em class="icon-i-pen"></em>
            </button>
          </div>
        }@else {
          <ng-container *ngTemplateOutlet="formTemplate; context: {formGroup:rateForm, data: item}" />
        }
      </div>
    }
  </form>
  }

  <ng-template let-formGroup="formGroup" let-data="data" #formTemplate>
    <ng-container [formGroup]="formGroup">
      <div class="input__container">
        <ui-select variant="bordered" [label]="appName === 'celic' ? 'Liceo' : 'Escuela'" formControlName="schoolID">
          <ui-select-content>
            @for (item of schools(); track $index) {
            <ui-select-item [value]="item.id!">{{item.NameTSchool}}</ui-select-item>
            }
          </ui-select-content>
        </ui-select>
      </div>

      <div class="input__container">
        <ui-select variant="bordered" label="Institución" formControlName="forceID">
          <ui-select-content>
            <ui-select-item [value]="0">Todas las fuerzas</ui-select-item>
            @for (item of sharedData().forces; track $index) {
            <ui-select-item [value]="item.id!">{{item.NameForce}}</ui-select-item>
            }
          </ui-select-content>
        </ui-select>
      </div>

      <div class="input__container">
        <ui-select variant="bordered" label="Rango" formControlName="rangeID">
          <ui-select-content>
            <ui-select-item [value]="0">Todos</ui-select-item>
            @for (item of sharedData().ranges; track $index) {
            <ui-select-item [value]="item.id!">{{item.NameRange}}</ui-select-item>
            }
          </ui-select-content>
        </ui-select>
      </div>

      <div class="input__container" [ngStyle]="{'max-width': '140px'}">
        <ui-select variant="bordered" label="Condición" formControlName="typeConditionID">
          <ui-select-content>
            <ui-select-item [value]="0">Todos</ui-select-item>
            @for (item of sharedData().conditions; track $index) {
            <ui-select-item [value]="item.id!">{{item.nameCondition}}</ui-select-item>
            }
          </ui-select-content>
        </ui-select>
      </div>

      <div class="input__container" [ngStyle]="{'max-width': '140px'}">
        <ui-select variant="bordered" label="Tipo tarifa" formControlName="typeRateID">
          <ui-select-content>
            @for (item of sharedData().tarifaTypes; track $index) {
            <ui-select-item [value]="item.id!">{{item.nameRate}}</ui-select-item>
            }
          </ui-select-content>
        </ui-select>
      </div>

      <div class="input__container" [ngStyle]="{'max-width': '150px'}">
        <ui-input variant="bordered" label="Valor" formControlName="rateValue" (keyup)="valueCurrency($event)" />
      </div>

      <div class="item__actions">
        <button type="button" class="button button--edit" (click)="onCloseRateForm()">
          <em class="icon-i-close"></em>
        </button>

        <button class="button button--edit" >
          <em class="icon-i-chulo"></em>
        </button>
      </div>
    </ng-container>
  </ng-template>
</div>