import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import * as UiSelect from '@components/ui/ui-select';
import { IEndpoint } from '@models/endpoint';
import { IForce } from '@models/militar-info';
import { ITypeUser } from '@models/type-user';
import { ApiService } from '@services/api-service.service';
import { forkJoin, map, Observable, Subscription, switchMap } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forces',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,

    UiInputComponent,
    UiButtonComponent,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './forces.component.html',
  styleUrl: './forces.component.css'
})
export class ForcesComponent implements OnInit, OnDestroy {
  constructor(private _fb: FormBuilder) { }
  private _apiService = inject(ApiService);
  public forces = signal<IForce[]>([]);
  public userTypeFilter = signal<number>(0);
  public typeUsers = signal<ITypeUser[]>([]);
  public forcesForm: FormGroup = this._fb.group({
    NameForce: ['', Validators.required],
    Sigla: ['', Validators.required],
    typeUserID: ['', Validators.required],
    isPublic: [false]
  });

  public showDialog = signal<boolean>(false);
  public foceToEdit = signal<IForce | null>(null);
  private _userTypeSubscription:Subscription = new Subscription();


  ngOnInit(): void {
    this.initialData();
  }

  private initialData() {
    Swal.fire({
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    forkJoin({
      forces: this.getFoces(),
      typeUsers: this._apiService.get<ITypeUser>({ path: 'TypeUsers' })
    }).subscribe({
      next: ({ typeUsers }) => {
        this.typeUsers.set(typeUsers)
        Swal.close();
      }, error: (err) => {
        console.log(err);
      }
    })
  }

  public onEditForce(forceItem: IForce) {
    this.foceToEdit.set(forceItem);
    this.forcesForm.patchValue({
      ...forceItem,
    })
    this.showDialog.set(true);
  }

  public onSave() {
    if (this.forcesForm.invalid) {
      this.forcesForm.markAllAsTouched();
      return;
    }

    if (this.foceToEdit()) this.editForce()
    else this.createForce()
  }

  private createForce() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando fuerza, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.post<IForce>({
      path: "Forces",
      data: {
        ...this.forcesForm.value,
      }
    }).pipe(
      switchMap(_ => this.getFoces())
    ).subscribe({
      next: (_) => {
        Swal.fire({
          icon: "success",
          text: "Se guardo la fuerza satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar la fuerza: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  private editForce() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando fuerza, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.patch<IForce>({
      path: `Forces/${this.foceToEdit()!.id}`,
      data: {
        id: this.foceToEdit()!.id,
        ...this.forcesForm.value,
      }
    }).pipe(
      switchMap(_ => this.getFoces())
    ).subscribe({
      next: (_) => {
        Swal.fire({
          icon: "success",
          text: "Se guardó la fuerza satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar la fuerza: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  private getFoces(): Observable<IForce[]> {
    const forcesFilter: Record<string, any> = {};

    if (this.userTypeFilter() !== 0)
      forcesFilter['typeUserID'] = this.userTypeFilter();

    const filters: IEndpoint = {
      path: "Forces",
      filter:  { 
        include: ["TypeUser"],
        where: { ...forcesFilter }
      }
    }

    return this._apiService.get<IForce>(filters).pipe(
      map(forces => {
        this.forces.set(forces);
        return forces
      })
    )
  }

  public onUserTypeChange(value: string) {
    this.userTypeFilter.set(Number(value));

    this._userTypeSubscription.unsubscribe();
    this._userTypeSubscription = this.getFoces().subscribe({
      next: (resp) => {
        this.forces.set(resp);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  public resetForm() {
    this.forcesForm.reset();
    this.foceToEdit.set(null);
    this.showDialog.set(false);
  }

  public ngOnDestroy(): void {
    this._userTypeSubscription.unsubscribe();
  }

}