@if (!loadingView()) {
  @if ((childrenData().responsible.length > 0 || childrenData().guardian.length > 0)) {
    <app-children-data />
  }@else {
    <div class="parents">
      <div class="parents__section">
        <div class="parents__header">
          <h2 class="parents__header-title">Responsable</h2>
          <div class="header__actions">
            @if (loadedResposible()) {
              @if (editResponsible()) {
                <button class="button button--bordered" (click)="editResponsible.set(false)">
                  <em class="icon-i-pen"></em>
                  Cancelar
                </button>
                <button class="button button--solid" (click)="dataToSave('responsible')">
                  Guardar cambios
                </button>
              } 
              @else{
                @if (assignments()?.responsible) {
                  <button class="button button--bordered" (click)="editResponsible.set(true)">
                    <em class="icon-i-pen"></em>
                    Editar información
                  </button>
                }
              }
            }
            <button class="button button--solid" [ngClass]="{disabled: assignments()!.responsible && !loadedResposible()}" 
              (click)="onAssignClick('responsible')">
              {{assignments()?.responsible ? 'Cambiar responsable' : 'Asignar responsable'}}
            </button>
          </div>
        </div>
  
        <div class="parents__content">
          @if(assignments()?.responsible){
            <app-user-data #responsibleComponent userType="responsible" (onSaveData)="editResponsible.set(false)"
              (onLoadData)="loadedResposible.set($event)"
              [isEditing]="editResponsible()" 
              [parentToStudent]="parentToStudent()" [userId]="assignments()!.responsible!" />
          }@else {
            <span class="parents__text">El estudiante no tiene ningún responsable asignado</span>
          }
        </div>
      </div>
  
      <!-- If the user has no parents, we can't add a guardian -->
      @if (assignments()?.responsible) {
        <div class="parents__section">
          <div class="parents__header">
            <h2 class="parents__header-title">Acudiente</h2>
            <div class="header__actions">
              @if (loadedGuardian()) {
                @if (editGuardian()) {
                  <button class="button button--bordered" (click)="editGuardian.set(false)">
                    <em class="icon-i-pen"></em>
                    Cancelar
                  </button>
                  <button class="button button--solid" (click)="dataToSave('guardian')">
                    Guardar cambios
                  </button>
                }
                @else{
                  @if(assignments()?.guardian){
                    <button class="button button--bordered" (click)="editGuardian.set(true)">
                      <em class="icon-i-pen"></em>
                      Editar información
                    </button>
                  }
                }
              }
              <button class="button button--solid" [ngClass]="{disabled: (assignments()!.guardian && !loadedGuardian()) || !loadedResposible()}" 
                (click)="onAssignClick('guardian')">
                {{assignments()?.guardian ? 'Cambiar acudiente' : 'Asignar acudiente'}}
              </button>
            </div>
          </div>
  
          <div class="parents__content">
            @if(assignments()?.guardian){
              <app-user-data #guardianComponent userType="guardian" (onSaveData)="editGuardian.set(false)"
                (onLoadData)="loadedGuardian.set($event)"
                [isEditing]="editGuardian()" 
                [parentToStudent]="parentToStudent()" [userId]="assignments()!.guardian!" />
            }@else {
              <span class="parents__text">El estudiante no tiene ningún acudiente asignado</span>
            }
          </div>
        </div>
      }
    </div>
  
    @if (showDialog()) {
      <div class="dialog__container">
        <div class="dialog__content">
          <div class="dialog__header">
            <h2 class="dialog__header-title">{{assignmentType() === "responsible" ? 'Responsable de pago' : 'Asignar acudiente'}}</h2>
            <em class="icon-i-close dialog__header-close" (click)="toggleDialog()"></em>
          </div>
  
          <div class="dialog__body">
            <div class="dialog__input">
              <span class="dialog__input-username">{{(newAssignment()?.fullName || '') | titlecase}}</span>
              <ui-dropdown (onValueChange)="onSelectUser($event)">
                <ui-dropdown-trigger>
                  <ui-input
                    [placeholder]="assignmentType() === 'responsible' ? 'Seleccionar responsable' : 'Seleccionar acudiente'"
                    (keyup)="onSearchChange($event)">
                    <em class="icon-i-search search-icon" start-decorator></em>
                  </ui-input>
                </ui-dropdown-trigger>
                <ui-dropdown-content>
                  @for (item of userList(); track $index) {
                  <ui-dropdown-item [value]="item.id!" [disabled]="!!item.disabled">{{item.Name1}}
                    {{item.LastName1}}</ui-dropdown-item>
                  }@empty {
                  <div>Sin resultados.</div>
                  }
                </ui-dropdown-content>
              </ui-dropdown>
            </div>
          </div>

          <ui-button color="primary" (onClick)="saveAssignment()" [disabled]="!newAssignment()">
            Guardar
          </ui-button>
        </div>
      </div>
    }
  }
}