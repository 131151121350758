import { Component, computed, input, output, signal } from '@angular/core';
import { Tab } from './tab';

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.css'
})
export class TabsComponent {

  tabs = input.required<Tab[]>();
  onValueChange = output<string>();
  selectedTab = signal<string>('');
  defaultTab = input<string>('')

  onTabChange(tab:Tab){
    this.selectedTab.set(tab.id);
    this.onValueChange.emit(tab.id.toString());
  }
}
