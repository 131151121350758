@if(!loadingView()){
  <div class="enrollment">
    <div class="enrollment__header">
      <ui-select label="Seleccionar {{appName()}}" (onValueChange)="onSelectSchool($event)" [defaultValue]="0">
        <ui-select-content>
          <ui-select-item [value]="0">Todas</ui-select-item>
          @for (school of schools; track $index) {
            <ui-select-item [value]="school.id!">{{school.NameTSchool}} - {{school.SchoolAcronim}}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
  
      <ui-select label="Año" (onValueChange)="onSelectYear($event)" [defaultValue]="0">
        <ui-select-content>
          <ui-select-item [value]="0">Todos</ui-select-item>
          @for (year of years(); track $index) {
            <ui-select-item [value]="year.yearNumber">{{year.yearNumber}}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
  
      <ui-select label="Estado" (onValueChange)="onSelectState($event)" [defaultValue]="0">
        <ui-select-content>
          <ui-select-item [value]="0">Todos</ui-select-item>
          @for (item of realStatusCourse(); track $index) {
            <ui-select-item [value]="item.id">{{item.nameRealStatus}}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
    </div>
  
    <div class="enrollment__content">
      @if(userCourses().length > 0){
        <table class="enrollment__table">
          <thead>
            <tr>
              <th class="enrollment__table-th" [ngStyle]="{'width': '350px'}">{{appName()}}</th>
              <th class="enrollment__table-th" [ngStyle]="{'width': '100px'}">Periodo</th>
              <th class="enrollment__table-th" [ngStyle]="{'width': '150px'}">Estado</th>
              <th class="enrollment__table-th" [ngStyle]="{'width': '250px'}">Grupo</th>
              <th class="enrollment__table-th" [ngStyle]="{'width': '150px'}">Grado</th>
              <th class="enrollment__table-th" [ngStyle]="{'width': '250px'}">Comentario</th>
              <th class="enrollment__table-th" [ngStyle]="{'width': '100px'}">Creación</th>
              <th class="enrollment__table-th" [ngStyle]="{'width': '100px'}"></th>
            </tr>
          </thead>
          <tbody>
            @for (userCourse of userCourses(); track $index) {
              <tr>
                <td class="enrollment__table-td">{{userCourse.coursesOfer?.school?.NameTSchool| titlecase }}</td>
                <td class="enrollment__table-td">{{userCourse.coursesOfer?.year || '-' }}</td>
                <td class="enrollment__table-td">
                  {{userCourse.RealStatusCourseID?.nameRealStatus || '-' }}
                </td>
                <td class="enrollment__table-td">{{(userCourse.courseGroup?.name | titlecase) || '-' }}</td>
                <td class="enrollment__table-td">{{(userCourse.coursesOfer?.NameCourseOfer | titlecase) || '-'}}</td>
                <td class="enrollment__table-td">
                  {{userCourse.comment || '-'}}
                </td>
                <td class="enrollment__table-td">{{(userCourse.createdAt | date) || '-'}}</td>
                <td class="enrollment__table-td">
                  <button class="column__action" [ngClass]="{'column__action--center': !userCourse.comment}" 
                    (click)="editRow(userCourse)">
                    <em class="icon-i-pen"></em>
                  </button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      }@else{
        Sin matrículas
      }
    </div>
  
    @if(showEditRowModal()){
      <div class="comment__dialog">
        <form class="dialog__content" [formGroup]="rowDataForm" (submit)="saveRowData()">
          <div class="dialog__header">
            <h2 class="dialog__header-title">Comentario</h2>
            <em class="icon-i-close dialog__header-close" (click)="onCloseForm()"></em>
          </div>
          <p class="dialog__text">Está a punto de dejar un comentario en el grupo {{rowToEdit()?.courseGroup?.name}}</p>
          <div class="dialog__body">
            <ui-select label="Estado" formControlName="realStatusCourseID">
              <ui-select-content>
                @for (item of realStatusCourse(); track $index) {
                  <ui-select-item [value]="item.id">{{item.nameRealStatus}}</ui-select-item>
                }
              </ui-select-content>
            </ui-select>
 
            <textarea formControlName="comment" class="dialog__textarea" placeholder="Escribir comentario"></textarea>
          </div>

          <ui-button color="primary"> 
            Guardar
          </ui-button>
        </form>
      </div>
    }
  </div>
}