import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import * as UiSelect from '@components/ui/ui-select';
import { IForce, IRange } from '@models/militar-info';
import { IOtan } from '@models/otan';
import { IPositions } from '@models/positions';
import { ApiService } from '@services/api-service.service';
import { debounceTime, distinctUntilChanged, forkJoin, map, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ranges',
  standalone: true,
  imports: [
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,

    UiInputComponent,
    UiButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './ranges.component.html',
  styleUrl: './ranges.component.css'
})
export class RangesComponent implements OnInit, OnDestroy {
  constructor(private _fb: FormBuilder) { }
  private _apiService = inject(ApiService);
  public showDialog = signal<boolean>(false);
  public chargeNameFilter = signal<string>('');
  public forceTypeFilter = signal<number>(0);
  public forceTypes = signal<IForce[]>([]);
  public chargeTypes = signal<IPositions[]>([]);
  public otan = signal<IOtan[]>([]);

  public rangesForm: FormGroup = this._fb.group({
    positionID: ['', Validators.required],
    ForceID: ['', Validators.required],
    OtanID: [''],
    NameRange: ['', Validators.required],
    Sigla: ['', Validators.required],
    isPublic: [false]
  });

  public filteredRanges = signal<IRange[]>([]);
  public rangeToEdit = signal<IRange | null>(null);
  private _fetchedAllData = signal<boolean>(true);
  private _chargeNameFilterSubject = new Subject<string>();
  private _rangesSubscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.initialData();

    /**
     * Find ranges by charge name
     */
    this._chargeNameFilterSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        this.chargeNameFilter.set(value);

        if (value.length <= 3) {
          if (!this._fetchedAllData()) {
            this._fetchedAllData.set(true);
            return this.applyFilters();
          }
          return of(null);
        }
    
        this._fetchedAllData.set(false);
        return this.applyFilters();
      })
    ).subscribe({
      next: (result) => result && this.filteredRanges.set(result),
      error: (err) => console.log(err)
    });
  }

  private initialData() {
    Swal.fire({
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    forkJoin({
      forceTypes: this._apiService.get<IForce>({ path: 'Forces' }),
      chargeTypes: this._apiService.get<IPositions>({ path: 'Positions' }),
      ranges: this.rangesQuery(), //Get all ranges
      otan: this._apiService.get<IOtan>({ path: 'Otans' })
    }).subscribe({
      next: ({ forceTypes, ranges, chargeTypes, otan }) => {

        const forceTypesOrdered = forceTypes.sort((a, b) => a.NameForce!.localeCompare(b.NameForce!));
        this.forceTypes.set(forceTypesOrdered);
        this.chargeTypes.set(chargeTypes);
        this.otan.set(otan);

        this.filteredRanges.set(ranges);
      }, error: (errd) => {
        console.log(errd);
      }, complete: () => {
        Swal.close();
      }
    })
  }

  public onSave() {
    if (this.rangesForm.invalid) {
      this.rangesForm.markAllAsTouched();
      return;
    }

    if (this.rangeToEdit()) this.editRange()
    else this.createRange()
  }

  private createRange() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando rango, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.post<IRange>({
      path: "Ranges",
      data: {
        ...this.rangesForm.value
      }
    }).pipe(
      switchMap(_ => this.applyFilters())
    ).subscribe({
      next: (resp) => {
        Swal.fire({
          icon: "success",
          text: "Se guardo el rango satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.filteredRanges.set(resp);
        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar el rango: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  private editRange() {
    Swal.fire({
      title: 'Guardando...',
      text: "Guardando rango, por favor, espere.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.patch<IRange>({
      path: `Ranges/${this.rangeToEdit()!.id}`,
      data: {
        id: this.rangeToEdit()!.id,
        ...this.rangesForm.value
      }
    }).pipe(
      switchMap(_ => this.applyFilters())
    ).subscribe({
      next: (resp) => {
        Swal.fire({
          icon: "success",
          text: "Se guardó el cargo satisfactoriamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.filteredRanges.set(resp);
        this.resetForm();
      }, error: (err) => {
        Swal.fire({
          icon: "error",
          text: `Error al guardar el cargo: ${err.message || 'Internal server error'}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        console.log(err);
      }
    })
  }

  public onNameChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this._chargeNameFilterSubject.next(value);
  }

  public onForceTypeChange(value: string) {
    this.forceTypeFilter.set(Number(value));

    this._rangesSubscription.unsubscribe();
    this._rangesSubscription = this.applyFilters().subscribe({
      next: (resp) => {
        this.filteredRanges.set(resp);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  private applyFilters(): Observable<IRange[]> {
    const chargeName = this.chargeNameFilter();
    const forceId = Number(this.forceTypeFilter());

    const rangeFilter: Record<string, any> = { and: [] };
    if (forceId !== 0)
      rangeFilter['and'] = [...rangeFilter['and'], { ForceID: forceId }];

    const positionsObs = this._apiService.get<IPositions>({
      path: 'Positions',
      filter: {
        fields: { id: true },
        where: { namePosition: { regexp: `/${chargeName}/i` }/* , limit: 20 */ }
      }
    })

    if(chargeName.length > 3){
      return positionsObs.pipe(
        switchMap(positions =>{
          const positionIds = positions.map(position => position.id);
        
          if (positionIds.length > 0)
            rangeFilter['and'] = [...rangeFilter['and'], { positionID: { inq: positionIds } }];

          return this.rangesQuery(rangeFilter)
        })
      )
    }

    return this.rangesQuery(rangeFilter);
  }

  private rangesQuery(filters?: Record<string, any>): Observable<IRange[]> {
    const newFilters:Record<string, any> = { include: ['Position', 'Force'] }

    if (filters) newFilters['where'] = { ...filters }

    return this._apiService.get<IRange>({
      path: 'Ranges',
      filter: { ...newFilters }
    })
  }

  public onEditRange(chargeItem: IRange) {
    this.rangeToEdit.set(chargeItem);

    this.rangesForm.patchValue({
      ...chargeItem,
    });

    this.showDialog.set(true);
  }

  public resetForm() {
    this.showDialog.set(false);
    this.rangesForm.reset();
    this.rangeToEdit.set(null);
  }

  ngOnDestroy(): void {
    this._rangesSubscription.unsubscribe();
  }
}